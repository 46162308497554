import { GET_ROW_NAME, GET_RELATION_INSTANCE } from "../action";

const initstate = {
	rowName: "",
	rowInstance: {
		rowOrigin: "",
		rowTarget: "",
	},
};

export const dataReducer = (state = initstate, action) => {
	switch (action.type) {
		case GET_ROW_NAME:
			return { ...state, rowName: action.payload };

		case GET_RELATION_INSTANCE:
			let rowInstance = {
				rowOrigin: action.rowOrigin,
				rowTarget: action.payload,
			};
			return {
				...state,
				rowInstance: rowInstance,
			};

		default:
			return state;
	}
};
