import React from "react";
import { connect } from "react-redux";

import { FormComponent, FormRelation } from "..";

import {
	addComponent,
	getComponentOriginNames,
	getComponentTargetNames,
} from "../../action/componentAction";
import { addRelation } from "../../action/relationAction";

import { sortedFunction } from "../../utils/Function";

import "./Popup.css";

function PopupCreateComponent(props) {
	return (
		<div className='container--popup'>
			<div className='background--popup'></div>
			{props.componentPage ? (
				<FormComponent
					type={props.type}
					components={props.components}
					properties={sortedFunction(props.info)}
					setPopupCreate={props.setPopupCreate}
					addComponent={props.addComponent}
				/>
			) : (
				<FormRelation
					type={props.type}
					components={props.components.sort((a, b) => a.name[0].localeCompare(b.name[0]))}
					relations={props.relations.sort((a, b) => a.name.localeCompare(b.name))}
					setPopupCreate={props.setPopupCreate}
					componentOriginName={props.componentOriginName}
					componentTargetName={props.componentTargetName}
					getComponentOriginNames={props.getComponentOriginNames}
					getComponentTargetNames={props.getComponentTargetNames}
					propertiesListColumnRelation={props.info ? sortedFunction(props.info.map(info => info.data)) : []}
					addRelation={props.addRelation}
					typeFromGraph={props.selectedType}
					nameFromGraph={props.selectedName}
					selected={props.selected}
				/>
			)}
		</div>
	);
}

const mapStateToProps = (state) => ({
	componentOriginName: state.componentReducer.componentOriginName,
	componentTargetName: state.componentReducer.componentTargetName,
});

const mapDispatchToProps = (dispatch) => ({
	addComponent: (newComponent, inputFields, update) =>
		dispatch(addComponent(newComponent, inputFields, update)),
	addRelation: (relationType, newRelation) =>
		dispatch(addRelation(relationType, newRelation)),
	getComponentOriginNames: (componentType) =>
		dispatch(getComponentOriginNames(componentType)),
	getComponentTargetNames: (componentType) =>
		dispatch(getComponentTargetNames(componentType)),
});

const PopupCreate = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PopupCreateComponent);

export { PopupCreate };
