import React, { useState, Fragment } from "react";

import { AddRemoveInput } from "../..";

import { componentColor, cancelColor } from "../../../utils/color";

import ButtonStyle from "../../../styled-components/ButtonStyle";

import "../Form.css";
import { capitalizeString, capitalizeWord } from "../../../utils/Function";

function FormComponent(props) {
	const [formNumber, setFormNumber] = useState(1);
	const [customType, setCustomType] = useState('');
	const propertiesFiltered = props.properties.filter(
		(col) =>
			col !== "id" &&
			col !== "Nom" &&
			col !== "Name" &&
			col !== "Criticité" &&
			col !== "Client" &&
			col !== "MiseEnService" &&
			col !== "TypeLogiciel" &&
			col !== "TypeLicence" &&
			col !== "UpdatedBy" &&
			col !== "CreatedBy",
	);

	const [newComponent, setNewComponent] = useState({
		type: props.type,
		name: "",
	});

	const [inputFields, setInputFields] = useState([
		{ property: "", content: "" },
	]);

	const handleChange = (event) => {
		setNewComponent({
			...newComponent,
			[event.target.name]: event.target.name === 'name' ? capitalizeString(event.target.value) : event.target.value,
		});
	};

	const formatInput = (event) => {
		setNewComponent({
			...newComponent,
			[event.target.name]: event.target.value.trim(),
		});
	};

	// const handleAddFields = () => {
	// 	const values = [...inputFields];
	// 	values.push({ property: "", content: "" });
	// 	setInputFields(values);
	// 	setFormNumber((prevFormNumber) => prevFormNumber + 1);
	// };

	// const handleRemoveFields = (index) => {
	// 	const values = [...inputFields];
	// 	values.splice(index, 1);
	// 	setInputFields(values);
	// 	setFormNumber((prevFormNumber) => prevFormNumber - 1);
	// };

	const handleInputChange = (index, event) => {
		const values = [...inputFields];
		if (event.target.name === "property") {
			values[index].property = event.target.value;
		} else {
			values[index].content = event.target.value;
		}

		setInputFields(values);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let update = true;
		if (newComponent.type === '' && customType !== '') {
			newComponent.type = customType;
			if (props.type !== undefined) {
				update = false;
			}
		}
		if (props.type !== undefined && newComponent.type !== props.type) {
			update = false;
		}
		props.addComponent(newComponent, inputFields, update);
		props.setPopupCreate(false);
	};

	// ===================================================
	let inputFieldsSelected = inputFields.map((x) => x.property);
	let inputFieldsProperties = propertiesFiltered.map((field) => field);

	let inputFieldsRemaining = inputFieldsProperties.filter(
		(property) => !inputFieldsSelected.includes(property),
	);
	// ===================================================

	return (
		<form onSubmit={handleSubmit} className='container--form'>
			<h2 style={{ color: componentColor }}>Ajouter un nouveau composant</h2>
			<div className='container--tuple'>
				<div className='group--input'>
					{newComponent.type === '' ? 
					<input 
						name='type' 
						style={{ color: componentColor }}
						value={customType} 
						onChange={(e) => setCustomType(capitalizeWord(e.target.value))}/> : null }
					<select
						name='type'
						onChange={handleChange}
						value={newComponent.type}
						style={{ color: componentColor, width: newComponent.type === '' ? '15px' : 'auto' }}
						required={customType === ''}>
						<option value=''></option>
						{ props.components.map((component, i) => {
							return (
								<option key={i} value={component.name}>
									{component.name}
								</option>
							);
						})}
					</select>
					<span
						className='floating--label--select required'
						style={{ color: componentColor }}>
						Catégorie de composant
					</span>
				</div>
				<div className='group--input'>
					<input
						type='text'
						name='name'
						value={newComponent.name}
						onChange={handleChange}
						onBlur={formatInput}
						style={{ color: componentColor, borderBottomColor: componentColor }}
						required
					/>
					<span
						className='floating--label--input required'
						style={{ color: componentColor }}>
						Nom du composant
					</span>
				</div>
			</div>
			<div className='form--row'>
				{inputFields.map((inputField, index) => (
					<Fragment key={`${inputField}~${index}`}>
						<div className='fragment'>
							<div className='group--input'>
								<input
									type='text'
									name='property'
									list='property'
									value={inputField.property}
									onChange={(event) => handleInputChange(index, event)}
									style={{
										color: componentColor,
										borderBottomColor: componentColor,
									}}
									required={formNumber > 1 ? true : false}
								/>
								<datalist id='property'>
									{inputFieldsRemaining.map((property, i) => {
										return (
											<option key={i} value={property}>
												{property}
											</option>
										);
									})}
								</datalist>
								<span
									className={
										formNumber > 1
											? "floating--label--input required"
											: "floating--label--input"
									}
									style={{ color: componentColor }}>
									Propriété
								</span>
							</div>
							<div className='group--input'>
								<input
									type='text'
									name='content'
									value={inputField.content}
									onChange={(event) => handleInputChange(index, event)}
									style={{
										color: componentColor,
										borderBottomColor: componentColor,
									}}
									required={formNumber > 1 ? true : false}
								/>
								<span
									className={
										formNumber > 1
											? "floating--label--input required"
											: "floating--label--input"
									}
									style={{ color: componentColor }}>
									Contenu
								</span>
							</div>
						</div>
						<AddRemoveInput
							formNumber={formNumber}
							index={index}
							setFormNumber={setFormNumber}
							inputFields={inputFields}
							setInputFields={setInputFields}
						/>
						<div className='container--add--remove'></div>
					</Fragment>
				))}
			</div>
			<div className='container--form--button'>
				<ButtonStyle
					color={cancelColor}
					onClick={() => props.setPopupCreate(false)}>
					ANNULER
				</ButtonStyle>
				<ButtonStyle color={componentColor} type='submit'>
					CONFIRMER
				</ButtonStyle>
			</div>
		</form>
	);
}

export { FormComponent };

// <select
// 									name='property'
// 									value={inputField.property}
// 									onChange={(event) => handleInputChange(index, event)}>
// 									<option value=''></option>
// 									{inputFieldsRemaining.map((property, i) => {
// 										return (
// 											<option key={i} value={property}>
// 												{property}
// 											</option>
// 										);
// 									})}
// 								</select>

// {formNumber < propertiesFiltered.length && (
// 	<button
// 		className='btn handle--tuple'
// 		type='button'
// 		data-tip='Ajouter une propriété'
// 		onClick={() => handleAddFields()}>
// 		+
// 	</button>
// )}
