import { UPLOAD_CSV, GET_NODE_CHANGE_CSV, GET_REL_CHANGE_CSV } from "../action/uploadTypes";

export const uploadReducer = (state = {}, action) => {
	switch (action.type) {

		case UPLOAD_CSV:
			return { ...state, success: action.payload };

		case GET_NODE_CHANGE_CSV:
			return { ...state, nodeChanges: action.payload };
			
		case GET_REL_CHANGE_CSV:
			return { ...state, relChanges: action.payload };

		default:
			return state;
	}
};