import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import {
	useTable,
	useSortBy,
	useGlobalFilter,
	usePagination,
	useBlockLayout,
	useExpanded,
} from "react-table";
import { useSticky } from "react-table-sticky";

import { Loader, TableData, TablePagination } from "..";
import { StylesComponent, StylesRelation } from "./TableStyles";
import { GlobalFilter } from "./GlobalFilter";
import { DeleteButton } from "./DeleteButton";
import { UpdateButton } from "./UpdateButton";
import { CloneButton } from "./CloneButton";

import {
	getComponentRelationNumber,
	getPropertyListComponent,
} from "../../action/componentAction";
import { getRowName, getRelationInstance } from "../../action/dataAction";
import { getPropertyListRelation } from "../../action/relationAction";

import updateComponent from "../../assets/table/update_composant.png";
import updateRelation from "../../assets/table/update_relation.png";

import "./TableTest.css";

function ReactTableComponent(props) {
	const [reactTableState, setReactTableState] = useState();
	const table = useRef(null);
	let columnsFromProps;

	// Récupérer les données de chaque composant
	const propsGetRowInfo = props.getRowInfo;

	useEffect(() => {
		propsGetRowInfo(props.type);
	}, [propsGetRowInfo, props.type]);

	// ==================================================

	// Si l'utilisateur vient de la page composants
	if (props.componentPage) {

		// Filtrage des colonnes pour afficher seulement celles voulues
		const columnsFiltered = props.columns.filter(
			(col) =>
				col !== "id" &&
				col !== "Id" &&
				col !== "CreatedBy" &&
				col !== "Created" &&
				col !== "UpdatedBy" &&
				col !== "Updated" &&
				col !== "Nom" &&
				col !== 'name' &&
				col !== 'type' &&
				col !== 'Type' &&
				col !== "idOrigin" &&
				col !== "idTarget" &&
				col !== "IdOrigin" &&
				col !== "IdTarget" &&
				col !== 'update' &&
				col !== '',
		);

		// Définition de la taille des colonnes infos selon le nombre de colonnes a afficher, pour remplir l'espace du tableau.
		const columnsInfo = columnsFiltered.map((col) => {
			if (columnsFiltered.length === 3) {
				return {
					Header: col,
					accessor: col,
					width: 300,
				};
			} else if (columnsFiltered.length === 2) {
				return {
					Header: col,
					accessor: col,
					width: 400,
				};
			} else if (columnsFiltered.length === 1) {
				return {
					Header: col,
					accessor: col,
					width: 1065,
				};
			} else {
				return {
					Header: col,
					accessor: col,
					width: 350,
				};
			}
		});

		// Selon le type de composant il n'y a pas de description, donc on le retire des colonnes en mode sticky
		if (
			props.type === "Stockage" ||
			props.type === "Société" ||
			props.type === "Personne" ||
			props.type === "EquipReseau"
		) {
			columnsFromProps = [
				{
					Header: "Nom",
					accessor: "Nom",
					width: 350,
					sticky: "left",
				},
				...columnsInfo,
			];
		} else {
			columnsFromProps = [
				{
					Header: "Nom",
					accessor: "Nom",
					width: 350,
					sticky: "left",
				},
// 				{
// 					Header: "Description",
// 					accessor: "Description",
// 					width: 400,
// 				},
				...columnsInfo,
			];
		}
	}
	// Si l'utilisateur vient de la page relations
	else if (props.relationPage) {
		const columnsFiltered = props.columns.filter(
			(col) =>
				col !== "id" &&
				col !== "Id" &&
				col !== "CreatedBy" &&
				col !== "Created" &&
				col !== "UpdatedBy" &&
				col !== "Updated" &&
				col !== "Nom" &&
				col !== 'name' &&
				col !== 'type' &&
				col !== 'Type' &&
				col !== "idOrigin" &&
				col !== "idTarget" &&
				col !== "IdOrigin" &&
				col !== "IdTarget" &&
				col !== 'update' &&
				col !== '',
		);
		// S'il n'y a qu'une colonne infos on agrandi sa taille pour remplir le blanc du tableau
		const columnsInfo = columnsFiltered.map((col) => {
			if (props.columns.length === 1) {
				return {
					Header: col,
					accessor: col,
					width: 625,
				};
			} else {
				return {
					Header: col,
					accessor: col,
					width: 350,
				};
			}
		});

		// S'il n'y a aucune colonne infos on agrandi la taille des colonnes titre pour remplir le blanc du tableau
		let columnsTitle;
		if (props.columns.length === 0) {
			columnsTitle = {
				source: {
					Header: "Composant source",
					accessor: "origin",
					width: 500,
					sticky: "left",
				},
				type: {
					Header: "Relation",
					accessor: "type",
					width: 470,
					sticky: "left",
				},
				target: {
					Header: "Composant cible",
					accessor: "target",
					width: 500,
					sticky: "left",
				},
			};
		} else {
			columnsTitle = {
				source: {
					Header: "Composant source",
					accessor: "origin",
					width: 300,
					sticky: "left",
				},
				type: {
					Header: "Relation",
					accessor: "type",
					width: 300,
					sticky: "left",
				},
				target: {
					Header: "Composant cible",
					accessor: "target",
					width: 300,
					sticky: "left",
				},
			};
		}

		// concaténation des colonnes
		columnsFromProps = [
			columnsTitle.source,
			columnsTitle.type,
			columnsTitle.target,
			...columnsInfo,
		];
	}

	// ===================================================

	// Ajout des boutons d'intéraction
	const columnsWithButton = [
		{
			id: "delete",
			Header: "",
			disableSortBy: true,
			accessor: "delete",
			Cell: (row) => (
				<DeleteButton
					{...row}
					type={props.type}
					setPopupDelete={props.setPopupDelete}
					setComponentId={props.setComponentId}
					setRelationId={props.setRelationId}
					getRowName={props.getRowName}
					getComponentRelationNumber={props.getComponentRelationNumber}
					getRelationInstance={props.getRelationInstance}
					componentPage={props.componentPage}
				/>
			),
			width: 30,
			sticky: "left",
		},
		{
			id: "update",
			Header: "",
			disableSortBy: true,
			accessor: "update",
			Cell: (row) => (
				<UpdateButton
					{...row}
					type={props.type}
					updateComponent={updateComponent}
					componentPage={props.componentPage}
					updateRelation={updateRelation}
					setPopupUpdate={props.setPopupUpdate}
					setInfoUpdate={props.setInfoUpdate}
					getPropertyListComponent={props.getPropertyListComponent}
					getPropertyListRelation={props.getPropertyListRelation}
				/>
			),
			width: 30,
			sticky: "left",
		},
		{
			id: "clone",
			Header: "",
			disableSortBy: true,
			accessor: "clone",
			Cell: (row) => (
				<CloneButton
					{...row}
					type={props.type}
					componentPage={props.componentPage}
					setPopupClone={props.setPopupClone}
					setInfoUpdate={props.setInfoUpdate}
					getPropertyListComponent={props.getPropertyListComponent}
					getPropertyListRelation={props.getPropertyListRelation}
				/>
			),
			width: 30,
			sticky: "left",
		},
		...columnsFromProps,
	];

	
	const calculate_width = (columns, rows, state, max_width, data) => {
		let totalWidth = 0;
		let augment = false;
		for (const column of columns) {
			let biggest_width = 0;
			const magicSpacing = 13;
			if (column.id !== 'update' && column.id !== 'delete' && column.id !== 'clone' && column.id && column.id.length !== 0) {
				for (let item = (state.pageIndex * state.pageSize); item < ((state.pageIndex * state.pageSize) + state.pageSize); item++) {
					if (rows[item] && rows[item].values[column.id] && rows[item].values[column.id].length > biggest_width) {
						biggest_width = rows[item].values[column.id].length;
					}
				}
				if (biggest_width === column.Header.length) {
					biggest_width += 1;
				}
				column.width = Math.min(max_width, Math.max(biggest_width, column.Header.length) * magicSpacing);
				if (column.width === max_width) {
					augment = true;
				}
				totalWidth += column.width;
				if (biggest_width === 0 && column.isVisible && column.id !== 'add') {
					column.toggleHidden(true);
				} else if (biggest_width !== 0 && !column.isVisible && column.id !== 'add') {
					column.toggleHidden(false);
				}
			}
		}
		if (table.current && (totalWidth + 100) <= (table.current.offsetWidth - 50) && augment) {
			calculate_width(columns, rows, state, max_width + 100, data);
		}
		if (data.length <= 10) {
			state.pageSize = data.length;
		} else if (table.current && table.current.offsetHeight - 100 > (42 * state.pageSize) + 30 && (state.pageSize + 1) <= data.length) {
			state.pageSize += 1;
			calculate_width(columns, rows, state, max_width, data);
		}
	}

	// Voir documentation ReactTable ↓↓↓↓↓=====================================
	// eslint-disable-next-line
	const columns = useMemo(() => columnsWithButton, [props.rowInfo]);
	// eslint-disable-next-line
	const data = useMemo(() => {
		return (
			props.rowInfo &&
			props.rowInfo.map((info, i) => {
				const filtered = {};
				Object.keys(info).filter(key => key !== '').forEach(key => {
					if (info[key]) {
						filtered[key] = info[key];
					}
				})
				return filtered;
			}).sort((a, b) => {
				let toCompare = 'Nom';
				if (!a[toCompare])
					toCompare = 'origin';
				if (!a[toCompare])
					return a - b;
				return a[toCompare].localeCompare(b[toCompare]);
			}));
	}, [props.rowInfo]);

	const tableInstance = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: 10 },
			autoResetGlobalFilter: false,
			autoResetPage: false
		},
		useGlobalFilter,
		useSortBy,
		useExpanded,
		usePagination,
		useBlockLayout,
		useSticky,
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		prepareRow,
		state,
		setGlobalFilter,
	} = tableInstance;

	const { globalFilter, pageIndex, pageSize } = state;


	if (tableInstance.columns && tableInstance.rows && tableInstance.columns.length > 0 && tableInstance.rows.length > 0) {
		calculate_width(tableInstance.columns, tableInstance.rows, state, 400, data);
	}

	const setDataFiltered = props.setDataFiltered;

	useMemo(() => {
		if (globalFilter)
			setDataFiltered(page.map(single => {
				return single.original;
			}));
		else
			setDataFiltered(data);
	}, [page, globalFilter, data, setDataFiltered])
	
	// Voir documentation ReactTable ↑↑↑↑↑=====================================

	return (
		<>
		<div className='container--table'>
			{data ? (
				<div className='table--page' ref={table}>
					<GlobalFilter gotoPage={gotoPage} filter={reactTableState ? reactTableState.globalFilter : globalFilter} setFilter={setGlobalFilter} />
					{props.componentPage ? (
						<StylesComponent>
							<TableData
								type={props.type}
								getTableProps={getTableProps}
								headerGroups={headerGroups}
								getTableBodyProps={getTableBodyProps}
								page={page}
								prepareRow={prepareRow}
								componentPage={props.componentPage}
								updateComponent={props.updateComponent}
							/>
						</StylesComponent>
					) : (
						<StylesRelation>
							<TableData
								getTableProps={getTableProps}
								headerGroups={headerGroups}
								getTableBodyProps={getTableBodyProps}
								page={page}
								prepareRow={prepareRow}
								relationPage={props.relationPage}
								updateRelation={props.updateRelation}
							/>
						</StylesRelation>
					)}
				</div>
			) : (
				<Loader />
			)}
		</div>
					<TablePagination
						pageIndex={pageIndex}
						pageOptions={pageOptions}
						pageSize={pageSize}
						setPageSize={setPageSize}
						gotoPage={gotoPage}
						previousPage={previousPage}
						canPreviousPage={canPreviousPage}
						nextPage={nextPage}
						canNextPage={canNextPage}
						pageCount={pageCount}
						componentPage={props.componentPage}
					/>
					</>
	);
}

const mapDispatchToProps = (dispatch) => ({
	getRowName: (rowName) => dispatch(getRowName(rowName)),
	getRelationInstance: (rowOrigin, rowTarget) =>
		dispatch(getRelationInstance(rowOrigin, rowTarget)),
	getComponentRelationNumber: (componentType, rowName) =>
		dispatch(getComponentRelationNumber(componentType, rowName)),
	getPropertyListComponent: (componentType) =>
		dispatch(getPropertyListComponent(componentType)),
	getPropertyListRelation: (componentType) =>
		dispatch(getPropertyListRelation(componentType)),
});

const ReactTable = connect(null, mapDispatchToProps)(ReactTableComponent);

export { ReactTable };
