const GET_SETTINGS="GET_SETTINGS";
const DELETE_SETTINGS="DELETE_SETTINGS";
const ADD_SETTINGS="ADD_SETTINGS";
const UPDATE_SETTINGS="UPDATE_SETTINGS";
const GET_ALL_PROPERTIES="GET_ALL_PROPERTIES";
const UPDATE_PROPERTIE="UPDATE_PROPERTIE";
const DELETE_PROPERTIES="DELETE_PROPERTIES";
const UPDATE_DATABASE="UPDATE_DATABASE";
const GET_DATABASE = "GET_DATABASE";
const GET_USER_LIST = "GET_USER_LIST";
const GET_ROLE_LIST = "GET_ROLE_LIST";
const ADD_USER = "ADD_USER";
const UPDATE_USER = "UPDATE_USER";
const ADD_ROLE = "ADD_ROLE";
const UPDATE_ROLE = "UPDATE_ROLE";
const DELETE_ROLE = "DELETE_ROLE";
const DELETE_USER = "DELETE_USER";
const GET_USER = "GET_USER";

export {
    GET_SETTINGS,
    DELETE_SETTINGS,
    ADD_SETTINGS,
    UPDATE_SETTINGS,
    GET_ALL_PROPERTIES,
    UPDATE_PROPERTIE,
    DELETE_PROPERTIES,
    UPDATE_DATABASE,
    GET_DATABASE,
    GET_USER_LIST,
    GET_ROLE_LIST,
	ADD_USER,
	UPDATE_USER,
	ADD_ROLE,
	UPDATE_ROLE,
    DELETE_USER,
    DELETE_ROLE,
    GET_USER
}