import moment from "moment";
import { isDate } from "../../GraphSettings/GraphFilter/Filter/Filter";

		export const hideFilter = (filterList, nodes, links, links2) => {
			// Filter possibility:
			// apply => boolean to apply or not the filter
			// date => date value to filter with time (not really usefull)
			// hide => boolean to hide or not all nodes type
			// link => string for link category
			// node => string for node category
			// open => boolean if the filter is open or close (display only)
			// propertie => string for node or link property that will be filtered
			// type => string for type (node or link)
			const nodeHidden = [];
			nodes.selectAll("g").style("visibility", checkNode(nodeHidden, filterList, links2));
			links.selectAll("g").style("visibility", checkLink(nodeHidden, filterList));
		}

			const checkNode = (nodeHidden, filterList, links2) => (d, i) => { // Check for every node if it should be hidden
				let hide = false;
				filterList.forEach(filter => {
					if (filter.type === "node" && d.type === filter.node) {
						if (filter.hide) { // Hide all node of same type
							hide = true;
						} else if (filter.apply) { // If filter is activated
							if (isDate(filter.propertie)) { // Custom filter for date
								if (filter.values.includes(">")) {
									if (!moment(d[filter.propertie], "DD/MM/YYYY").isSameOrAfter(moment(filter.date))) {
										console.log(`${d[filter.propertie]} n'est pas superieur a ${filter.date} donc je cache ${d.name}`);
										hide = true;
									}
								} else if (filter.values.includes("<")) {
									if (!moment(d[filter.propertie], "DD/MM/YYYY").isSameOrBefore(moment(filter.date))) {
										console.log(`${d[filter.propertie]} n'est pas inferieur a ${filter.date} donc je cache ${d.name}`);
										hide = true;
									}
								} else {
									if (filter.values.includes(d[filter.propertie])) {
										console.log(`${d[filter.propertie]} est inclue dans les dates selectionne, donc je cache ${d.name}`)
										hide = true;
									}
								}
							} else {
								if (filter.values.includes('empty')) {
									if (!d[filter.propertie] || d[filter.propertie] === '') {
										hide = true;
									}
								} if (filter.propertie !== 'undefined' && filter.values.length > 0) { // Check for simple properties
									if (filter.values.includes(d[filter.propertie])) {
										hide = true;
									}
								}
							}
						}
					}
					if (filter.type === "node" && d.type === filter.node && filter.propertie === "Orphelins" && filter.apply) {
						if (!links2.find(link => link.source.id === d.id || link.target.id === d.id)) {
							hide = true;
						}
					}
				})
				if (hide) {
					nodeHidden.push(d.id);
					return "hidden";
				}
				return "visible";
			}


			const checkLink = (nodeHidden, filterList) => (d, i) => { // Check for every link if it should be hidden
				let hide = false;
				filterList.forEach(filter => {
					if (filter.type === "link" && d.type === filter.link) {
						if (filter.hide) { // Hide all links of same type
							hide = true;
						} else if (filter.apply) { // If filter is activated
							if (isDate(filter.propertie)) { // Custom filter for date
								if (filter.values.includes(">")) {
									if (!moment(d[filter.propertie], "DD/MM/YYYY").isSameOrAfter(moment(filter.date))) {
										console.log(`${d[filter.propertie]} n'est pas superieur a ${filter.date} donc je cache ${d.name}`);
										hide = true;
									}
								} else if (filter.values.includes("<")) {
									if (!moment(d[filter.propertie], "DD/MM/YYYY").isSameOrBefore(moment(filter.date))) {
										console.log(`${d[filter.propertie]} n'est pas inferieur a ${filter.date} donc je cache ${d.name}`);
										hide = true;
									}
								} else {
									if (filter.values.includes(d[filter.propertie])) {
										console.log(`${d[filter.propertie]} est inclue dans les dates selectionne, donc je cache ${d.name}`)
										hide = true;
									}
								}
							} else {
								if (filter.values.includes('empty')) {
									if (!d[filter.propertie] || d[filter.propertie] === '') {
										hide = true;
									}
								} if (filter.propertie !== 'undefined' && filter.values.length > 0) { // Check for simple properties
									if (filter.values.includes(d[filter.propertie])) {
										hide = true;
									}
								}
							}
						}
					}
				})
				if (nodeHidden.find(id => id === d.source.id || id === d.target.id)) { // Hide all links that have a node previously hidden
					hide = true;
				}
				return hide ? "hidden" : "visible";
			}