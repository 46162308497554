import React from "react";

//import logo from "../assets/Logo.png";
import logosvg from "../assets/img/Graph-IT.svg";

function Home() {
	return (
		<div
			style={{
				height: "100vh",
				paddingTop: "50px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-around",
				alignItems: "center",
			}}>
			<img
				src={logosvg}
				alt='Loading'
				style={{
					width: "40vw",
				}}
			/>
		</div>
	);
}

export { Home };
