const GET_RELATIONS = "GET_RELATIONS";
const GET_RELATION_INFO = "GET_RELATION_INFO";
const GET_PROPERTY_LIST_RELATION = "GET_PROPERTY_LIST_RELATION";
const DELETE_RELATION = "DELETE_RELATION";
const ADD_RELATION = "ADD_RELATION";
const UPDATE_RELATION = "UPDATE_RELATION";

export {
	GET_RELATIONS,
	GET_RELATION_INFO,
	GET_PROPERTY_LIST_RELATION,
	DELETE_RELATION,
	ADD_RELATION,
	UPDATE_RELATION,
};
