import React, { useMemo, useState } from "react";
import { connect } from "react-redux";

import "../../Form/Form.css";
import './ValidateFields.css';
import ButtonStyle from "../../../styled-components/ButtonStyle";
import { uploadColor, cancelColor } from '../../../utils/color';
import { getPropertyListComponent } from "../../../action/componentAction";
import { getPropertyListRelation } from "../../../action/relationAction";
import { getNodeChangeCsv, getRelChangeCsv } from "../../../action/uploadAction";

function ValidateFieldsComponent(props) {
    const [type, setType] = useState('node');
    const [node, setNode] = useState('');
    const [relation, setRelation] = useState('');
    const [fields, setFields] = useState(props.csvFields);
    const [error, setError] = useState('');

    const generateNodePicker = () => {
        let nodes = props.components.map((element) => {
            return (
                <option value={element.name[0]}>{element.name[0]}</option>
            );
        });
        return (
            <select value={node} onChange={(e) => setNode(e.target.value)}>
                <option value=''></option>
                {nodes}
            </select>
        );
    }

    useMemo(() => {
        props.getPropertyListComponent(node);
    }, [node])
    
    useMemo(() => {
        props.getPropertyListRelation(relation);
    }, [relation])

    const generateRelationPicker = () => {
        let relations = props.relations.map((element) => {
            return (
                <option value={element.name}>{element.name}</option>
            );
        });
        return (
            <select value={relation} onChange={(e) => {
                setRelation(e.target.value);
                setNode('')}}>
            <option value=''></option>
                {relations}
            </select>
        );
    }

    const generateProperties = () => {
        let properties = node !== '' && relation === '' ? props.nodeProperties.filter((element) => 
            element !== 'Created' &&
            element !== 'CreatedBy' &&
            element !== 'UpdatedBy' &&
            element !== 'Updated',) : props.linkProperties.concat(['Source', 'Cible', 'SourceType', 'CibleType']);
        return (
            properties.map((element) => {
                return (
                    <option value={element}>{element}</option>
                );
            })
        );
    }

    const generateFields = () => {
        return (
            fields.map((element) => {
                return (
                    <tr>
                        <td>{element.Header}</td>
                        <td>
                            <select name={element.Header} value={element.field} onChange={(e) => handleFields(e)}>
                                <option value=''></option>
                                {generateProperties()}
                            </select>
                        </td>
                    </tr>
                );
            })
        );
    }

    const handleFields = (e) => {
        const field = fields;
        let index = fields.findIndex((element) => element.Header === e.target.name);
        field[index].field = e.target.value;
        let error = false;
        let named = false;
        let source = false;
        let target = false;
        let sourceType = false;
        let targetType = false;
        fields.forEach((element) => {
            let name = element.field;
            if (name === 'Nom' && relation === '') {
                named = true;
            } else if (node === '' && name === 'Source') {
                source = true;
            } else if (node === '' && name === 'Cible') {
                target = true;
            } else if (node === '' && name === 'SourceType') {
                sourceType = true;
            } else if (node === '' && name === 'CibleType') {
                targetType = true;
            }
            let doublon = 0;
            fields.forEach((element) => {
                if (name === element.field && name !== undefined && name !== '') {
                    doublon++;
                }
            })
            if (doublon > 1) {
                error = true;
            }
        })
        if (error) {
            setError('Les proprietes doivent etre unique');
        } else if (node === '' && (!source || !target)) {
            setError('Source et Cible sont obligatoires');
        } else if (node === '' && (!sourceType || !targetType)) {
            setError('SourceType et CibleType sont obligatoires');
        } else if (relation === '' && !named) {
            setError('Le composant doit au moins avoir un Nom');
        } else {
            setError('');
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (type && type === 'node') {
            const field = fields.find((element) => element.field === 'Nom').accessor;
            const searchList = [];
            props.csvData.forEach((element) => {
                if (element[field] !== undefined)
                    searchList.push(element[field]);
            })
            props.getNodeChangesCsv(searchList, node);
            props.setFields(fields);
            props.setTypes({'type': type, 'node': node, 'relation': relation});
        } else {
            const from = fields.find((element) => element.field === 'Source').accessor;
            const to = fields.find((element) => element.field === 'Cible').accessor;
            const fromType = fields.find((element) => element.field === 'SourceType').accessor;
            const toType = fields.find((element) => element.field === 'CibleType').accessor;
            const searchList = [];
            props.csvData.forEach((element) => {
                searchList.push({from: {name: element[from], type: element[fromType]}, to: {name: element[to], type: element[toType]}});
            })
            props.getRelChangesCsv(searchList, relation);
            props.setFields(fields);
            props.setTypes({'type': type, 'node': node, 'relation': relation});
        }
    }

    return (
        <div className='import__fields'>
            <form className='container--form'>
                <div className="import__fields__select">
                    <h2>Vous importez </h2> 
                    <select value={type} onChange={(e) => {
                        setType(e.target.value)
                        setNode('')
                        setRelation('')}}>
                        <option value='node'>Composants</option>
                        <option value='relation'>Relation</option>
                    </select>
                </div>
                {type !== '' ? 
                <div className="import__fields__select">
                    <h2>Selectionner votre {type === 'node' ? 'Composant' : 'Relation'}</h2>
                    {type === 'node' ? generateNodePicker() : generateRelationPicker()}
                </div> : null}
                {(relation !== '' && node === '') || (node !== '' && relation === '') ? 
                <div className="import__fields__list customScrollbar">
                    <table>
                        <thead>
                            <tr>
                                <th>Colonne CSV</th>
                                <th>Propriété</th>
                            </tr>
                        </thead>
                        <tbody>
                            {generateFields()}
                        </tbody>
                    </table>
                    {error !== '' ? <span style={{color: 'red'}}>{error}</span> : null}
                </div> : null}
                <div className='container--form--button'>
                    <ButtonStyle
                        color={cancelColor}
                        onClick={() => props.setPopupImport(false)}>
                        ANNULER
                    </ButtonStyle>
                    <ButtonStyle disabled={error !== ''} color={uploadColor} onClick={handleSubmit}>
                        CONFIRMER
                    </ButtonStyle>
                </div>
            </form>
        </div>
    );
}

const mapStateToProps = (state) => ({
	nodeProperties: state.componentReducer.propertiesListColumnComponent,
    linkProperties: state.relationReducer.propertiesListColumnRelation,
    nodeChanges: state.uploadReducer.nodeChanges,
});

const mapDispatchToProps = (dispatch) => ({
    getPropertyListComponent: (componentType) => dispatch(getPropertyListComponent(componentType)),
    getPropertyListRelation: (relationType) => dispatch(getPropertyListRelation(relationType)),
    getNodeChangesCsv: (csv, node) => dispatch(getNodeChangeCsv(csv, node)),
    getRelChangesCsv: (csv, relation) => dispatch(getRelChangeCsv(csv, relation)),
});

const ValidateFields = connect(
    mapStateToProps,
	mapDispatchToProps,
)(ValidateFieldsComponent);

export { ValidateFields };