import React from "react";
import { ReactComponent as Bin } from "../../../../assets/img/trash-alt-solid.svg"
import { ReactComponent as Pencil } from "../../../../assets/img/pencil-solid.svg"
import { useMemo } from "react";
import { useTable } from "react-table";
import { StylesComponent } from "./UserSettingsStyle";
import { useState } from "react";
import { PopupUpdateUser } from "../PopupUpdate/PopupUpdateUser";
import { componentColor } from "../../../../utils/color";

const UserSettings = (props) => {
	const [showUpdatePopup, setShowUpdatePopup] = useState(false);
	const [userData, setUserData] = useState({
        username: '',
        firstname: '',
        lastname: '',
        function: '',
        password: '',
        role: '',
    });

	const handleDelete = (row) => {
		if (row.username !== 'Graphit-Admin' && row.username !== 'Graphit-Dev') {
			props.deleteUser(row);
		}
	}

	const handleUpdate = (row) => {
		if (row.username !== 'Graphit-Admin' && row.username !== 'Graphit-Dev') {
			setUserData(row);
			setShowUpdatePopup(true);
		}
	}

	const canEdit = (row) => {
		if (row.username === 'Graphit-Admin' || row.username === 'Graphit-Dev')
			return 'not-allowed';
		return 'pointer';
	}

	// eslint-disable-next-line
	const columns = useMemo(
		() => [
			{
				id: "delete",
				Header: "",
				disableSortBy: true,
				accessor: "delete",
				Cell: (row) => (
					<Bin style={{width: '15px', cursor: canEdit(row.row.original)}} onClick={() => handleDelete(row.row.original)}></Bin>
				),
				sticky: "left",
			},
			{
				id: "update",
				Header: "",
				disableSortBy: true,
				accessor: "update",
				Cell: (row) => (
					<Pencil style={{width: '15px', cursor: canEdit(row.row.original), fill: componentColor}} onClick={() => handleUpdate(row.row.original)}></Pencil>
				),
				width: 30,
				sticky: "left",
			},
			{
			Header: 'Identifiant',
			accessor: 'username'
		  },
		  {
			Header: 'Prenom',
			accessor: 'firstname'
		  },
		  {
			Header: 'Nom',
			accessor: 'lastname'
		  },
		  {
			Header: 'Role',
			accessor: 'roles'
		  },
		],
		[]
	  )
	  
	const data = useMemo(() => {
		const value = props.userList ? props.userList : [];
		value.forEach(el => {
			if (!el.role) {
				el.role = [{name: 'default'}]
			}
			el.roles = el.role.map(role => role.name + ' ');
		})
		return value;
	}, [props.userList]);

	const tableInstance = useTable({
		columns,
		data
	})
	
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	  } = tableInstance;

	return (
		<>
		{ showUpdatePopup ? <PopupUpdateUser updateUser={props.updateUser} userList={props.userList} roleList={props.roleList} userData={userData} setShow={setShowUpdatePopup}></PopupUpdateUser> : null}
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
				<StylesComponent>
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
								))}
							</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map((row, i) => {
							prepareRow(row)
							return (
								<tr {...row.getRowProps()}>
								{row.cells.map(cell => {
									return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
								})}
								</tr>
							)
							})}
						</tbody>
					</table>
				</StylesComponent>
			</div>
		</>
	);
}

export default UserSettings;
