import React, { useMemo, useState } from "react";

import { useTable } from 'react-table';
import { StylesComponent } from "./PropertiesSettingsStyle";
import { PopupUpdateSettings } from "./PopupUpdateSettings/PopupUpdateSettings";

const PropertiesSettings = (props) => {
	const [rowInfo, setRowInfo] = useState();
	const [show, setShow] = useState(false);

	const handlePopup = (row) => {
		setShow(true);
		setRowInfo(row);
	}

	// eslint-disable-next-line
	const columns = useMemo(
		() => [
		  {
			Header: 'Propriete',
			accessor: 'propertie'
		  },
		  {
			Header: 'Type associe',
			accessor: 'type'
		  },
		  {
			Header: 'Elements associe',
			accessor: 'name',
			width: 300
		  },
		  {
			Header: 'Nombre',
			accessor: 'number'
		  }
		],
		[]
	  )
	const data = useMemo(() => {
		const value = props.properties ? props.properties : [];
		value.forEach((element) => {
			let rel = 0;
			element.name.forEach((relorcomp) => {
				if (relorcomp === relorcomp.toUpperCase()) {
					rel++;
				}
			});
			element.type = rel === 0 ? 'Composant' : (rel === element.name.length ? 'Relation' : 'Composant | Relation');
		});
		return (value.filter(element => 
				element.propertie !== 'Nom'
//				&& element.propertie !== 'Environnement'
				&& element.propertie !== 'Created'
				&& element.propertie !== 'CreatedBy'
				&& element.propertie !== 'Updated'
				&& element.propertie !== 'UpdatedBy'
			));
	}, [props.properties]);
	const tableInstance = useTable({
		columns,
		data
	})
	
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	  } = tableInstance;
	
	return (
		<> {show ? 
			<PopupUpdateSettings
				row={rowInfo}
				values={data}
				updatePropertie={props.updatePropertie}
				setShow={setShow}
				/> : null}
			<StylesComponent>
				<table {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row, i) => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps()} onClick={() => handlePopup(row.values)}>
							{row.cells.map(cell => {
								if (cell.column.id === 'name') {
									return <td {...cell.getCellProps()}>{cell.value.join(" - ")}</td>
								}
								return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
							})}
							</tr>
						)
						})}
					</tbody>
				</table>
			</StylesComponent>
		</>
	);
}

export default PropertiesSettings;
