import * as d3 from "d3";

	export const initZoom = (width, height, nodes, links, gZoom) => {
		return d3.zoom()
		.filter((event) => {
			return event.type === 'wheel' || event.type === 'mousedown'
		})
		.extent([[0, 0], [width, height]])
		.scaleExtent([0.2, 5])
		.on("zoom", zoomed(nodes, links, gZoom));
	}

	const zoomed = (nodes, links, gZoom) => (event) => {
		if(d3.zoomTransform(gZoom.node()).k < 0.3) {
			nodes.selectAll("text")
				.classed("visually-hidden", true);
			links.selectAll("text")
				.classed("visually-hidden", true);
		}
		else  {
			nodes.selectAll("text")
					.classed("visually-hidden", false);
					links.selectAll("text")
					.classed("visually-hidden", false);
		}

		gZoom.attr("transform", event.transform);
	}