    export const getInitialGraphState = () => ({
        nodes2: 				[],
        links2: 				[],
        nodePossibility:		[],
        linkPossibility:		[],

        showingModal: 			false,
        showingNodeModal: 		false,
        nodeModalCoor: 			[0, 0],
        showingNodeTypeModal: 	false,
        showingLinkTypeModal: 	false,
        showFilter:				false,
        popupLoadCartos:        false,
        popupSaveCarto:         false,

        anchorPoint:			{x: 0, y: 0},
        contextMenuShow:		false,
        contextMenuShowData:	false,
        contextMenuShowDataBtn:	false,
        contextMenuNodeData: 	{},
        contextMenuLinkData: 	{},

        nodesTypes: 			[],
        linksTypes: 			[],
        nodesExpanded:			[],
        
        selectedElements: 		new Set(),
        selectedId: 			0,
        selectedType: 			true,
        selectedName:			"",
        selectForLink:			[],

        typeToConfig: 			"",
        updatedNodeConfig:		false,
        updatedlinkConfig:		false,
        filters:				[],
        export:                 false,
        nodesDeleted:           [],
        ctrlZ:                  false,
        lastCartoLoaded:        {},
    });