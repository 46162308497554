import styled from "styled-components";

import {
	componentColor,
	componentColorHover,
	relationColor,
	relationColorHover,
} from "../../utils/color";

export const StylesComponent = styled.div`
	display: block;
	height: 100%;

	.tableWrap {
		display: block;
		border-radius: 20px;
		margin-top: 20px;
		overflow-x: scroll;
		overflow-y: scroll;
	}

	.header .th {
		:last-child {
			border-top-right-radius: 20px;
		}
		padding: 5px;
		background-color: ${componentColor};
		color: white;
		font-size: 20px;
		font-family: "Betm Rounded Regular";
	}

	.body {
		height: calc(60vh - 100px);
		width: 100%;
	}

	.body::-webkit-scrollbar {
		display: none;
	  }

	.body .tr {
		background-color: #fff;
		heigth: 30px;
	}

	.body .tr:hover {
		background-color: ${componentColorHover};
	}

	.body .td {
		color: ${componentColor};
		padding: 2px;
		font-size: 18px;
		font-family: "Betm Rounded Regular";
		border-bottom: 1px solid ${componentColor};
		border-right: 1px solid ${componentColor};
		text-overflow: ellipsis;
		display: flex;
		align-items: center;
		background-color: #fff;
		text-align: center;
	}

	/* .body .tr {
		:first-child {
			.td {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	} */

	.body .tr {
		:last-child {
			.td {
				border-bottom: 0;
			}
		}
	}

	&.sticky {
		.header {
			position: sticky;
			z-index: 1;
		}

		.header {
			top: 0;
		}

		[data-sticky-td] {
			position: sticky;
		}

		[data-sticky-last-left-td] {
			/* box-shadow: 2px 0px 3px #ccc; */
		}

		[data-sticky-first-right-td] {
			/* box-shadow: -2px 0px 3px #ccc; */
		}
	}
`;

// ==============================

export const StylesRelation = styled.div`
display: block;
height: 100%;

.tableWrap {
	display: block;
	border-radius: 20px;
	margin-top: 20px;
	overflow-x: scroll;
	overflow-y: scroll;
}

.header .th {
	:last-child {
		border-top-right-radius: 20px;
	}
	padding: 5px;
	background-color: ${relationColor};
	color: white;
	font-size: 20px;
	font-family: "Betm Rounded Regular";
}

.body {
	height: calc(60vh - 100px);
	width: 100%;
}

.body::-webkit-scrollbar {
	display: none;
  }

.body .tr {
	background-color: #fff;
	heigth: 30px;
}

.body .tr:hover {
	background-color: ${relationColorHover};
}

.body .td {
	color: ${relationColor};
	padding: 2px;
	font-size: 18px;
	font-family: "Betm Rounded Regular";
	border-bottom: 1px solid ${relationColor};
	border-right: 1px solid ${relationColor};
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
	background-color: #fff;
	text-align: center;
}

/* .body .tr {
	:first-child {
		.td {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
} */

.body .tr {
	:last-child {
		.td {
			border-bottom: 0;
		}
	}
}

&.sticky {
	.header {
		position: sticky;
		z-index: 1;
	}

	.header {
		top: 0;
	}

	[data-sticky-td] {
		position: sticky;
	}

	[data-sticky-last-left-td] {
		/* box-shadow: 2px 0px 3px #ccc; */
	}

	[data-sticky-first-right-td] {
		/* box-shadow: -2px 0px 3px #ccc; */
	}
}
`;
