import React from "react";
import { Transition } from 'react-transition-group';

import "./Popup.css";

const PopupError = (props) => {

    const defaultStyle = {
        transition: `top 300ms ease-in-out`,
        top: "-10%",
    }

    const transitionStyles = {
        entering: { top: "5%" },
        entered:  { top: "5%" },
        exiting:  { top: "-10%" },
        exited:  { top: "-10%" },
      };

	return (
        <Transition in={props.show} timeout={500}>
            {state => (
                <div style={{...defaultStyle, ...transitionStyles[state]}} className='container--popup--error'>
                    {props.error}
                </div>
            )}
        </Transition>
	);
}

export { PopupError };
