import styled from "styled-components";

import {
  relationColor
} from "../../../utils/color";

export const StylesComponent = styled.div`
display: block;
padding: 2rem;
height: 90vh;
overflow-y: scroll;
width: 90vw;

table {
  border-spacing: 0;
  border: 1px solid black;
  background-color: #fff;

  thead {
    background-color: ${relationColor};
    color: white;
    font-size: 20px;
    font-family: "Betm Rounded Regular";
    td {
      color: white;
    }
  }

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  tbody {
    td {
      color: ${relationColor};
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
		font-size: 20px;
		font-family: "Betm Rounded Regular";

    :last-child {
      border-right: 0;
    }
  }
}
`