import React, { useMemo, useState } from "react";

import { useTable } from 'react-table';
import { StylesComponent } from "./RelationshipSettingsStyle";
import { ReactComponent as Double } from '../../../assets/img/angle-double-left-solid.svg';
import { ReactComponent as Circle } from '../../../assets/img/arrow-circle-left-solid.svg';
import { ReactComponent as Arrow } from '../../../assets/img/arrow-left-solid.svg';
import { ReactComponent as Square } from '../../../assets/img/caret-square-left-solid.svg';
import { PopupUpdateSettings } from "./PopupUpdateSettings/PopupUpdateSettings";

const RelationshipSettings = (props) => {
	const [rowInfo, setRowInfo] = useState();
	const [show, setShow] = useState(false);

	const shapes = [
		{name: 'circle', svg: <Circle/>},
		{name: 'square', svg: <Square/>},
		{name: 'double', svg: <Double/>},
		{name: 'Arrow', svg: <Arrow/>}
	];

	const linesWidth = ['1px', '2px', '3px', '4px', '5px', '6px', '7px', '8px', '9px', '10px'];
	const lineType = ['dotted', 'dashed', 'solid', 'double'];

	const colors = [
		"rgb(214, 39, 40)",
		"rgb(44, 160, 44)",
		"rgb(148, 103, 189)",
		"rgb(140, 86, 75)",
		"rgb(255, 127, 14)",
		"rgb(127, 127, 127)",
		"rgb(188, 189, 34)",
		"rgb(23, 190, 207)",
		"rgb(31, 119, 180)"
	];

	const handlePopup = (row) => {
		setShow(true);
		setRowInfo(row);
	}

	// eslint-disable-next-line
	const columns = useMemo(
		() => [
		  {
			Header: 'Relation',
			accessor: 'name'
		  },
		  {
			Header: 'Nombre',
			accessor: 'number'
		  },
		  {
			Header: 'Nb proprietes',
			accessor: 'propertiesCount'
		  },
		  {
			Header: 'Couleur',
			accessor: 'color'
		  },
		  {
			Header: 'Fleche',
			accessor: 'shape'
		  },
		  {
			Header: 'Trait',
			accessor: 'line'
		  },
		  {
			Header: 'Contenu',
			accessor: 'content'
		  },
		],
		[]
	  )
	const data = useMemo(() => {
		const value = props.relations && props.settings ? props.relations : [];
			value.forEach(element => {
				element.shape = props.settings[element.name] ? props.settings[element.name].shape : 'default';
				element.color = props.settings[element.name] ? props.settings[element.name].color : 'default';
				element.content = element.content.filter(element => 
					element !== 'Created'
					&& element !== 'CreatedBy'
					&& element !== 'Updated'
					&& element !== 'UpdatedBy'
				);
				element.propertiesCount = element.content.length;
				element.line = props.settings[element.name] ? props.settings[element.name].line : 'default';
			});
		return (value);
	}, [props.settings, props.relations]);
	const tableInstance = useTable({
		columns,
		data
	})
	
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	  } = tableInstance;
	
	return (
		<> {show ? 
			<PopupUpdateSettings
				row={rowInfo}
				settings={props.settings}
				updateSettings={props.updateSettings}
				setShow={setShow}
				shapes={shapes}
				colors={colors}
				lineType={lineType}
				linesWidth={linesWidth}
				/> : null}
			{props.relations ? 
			<StylesComponent>
				<table {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row, i) => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps()} onClick={() => handlePopup(row.values)}>
							{row.cells.map(cell => {
								if (cell.column.id === 'color') {
									if (cell.value === 'default') {
										cell.value = colors[0];
									}
									return <td {...cell.getCellProps()} style={{backgroundColor: cell.value}}></td>
								} else if (cell.column.id === 'shape') {
									if (cell.value === 'default') {
										cell.value = 'circle';
									}
									return <td {...cell.getCellProps()}>{shapes.find(element => element.name === cell.value)?.svg}</td>
								} else if (cell.column.id === 'line') {
									if (cell.value === 'default') {
										cell.value = 'solid';
									}
									return <td {...cell.getCellProps()}><div style={{borderBottom: '10px ' + cell.value + ' black', width: '100px'}}/></td>
								} else if (cell.column.id === 'content') {
									if (cell.value.length === 0) {
										cell.value = ['Nom'];
									}
									return <td {...cell.getCellProps()}>{cell.value.join(" - ")}</td>
								}
								return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
							})}
							</tr>
						)
						})}
					</tbody>
				</table>
			</StylesComponent>
			: null}
		</>
	);
}

export default RelationshipSettings;
