import React from "react";

function SuggestionModal(props) {
	return (
		<div
			ref={props.ref}
			className={
				props.suggestions.length > 0 ? "input--list" : "input--list--empty"
			}>
			{props.suggestions.map((res, i) => {
				let color = "rgb(86,148,128)";
				if (props.settings && props.settings[res.type])
					color = props.settings[res.type].color;
				return (
				<div
					key={i}
					className='cartridge--list--name'
					style={{ backgroundColor:
						props.position === 'left' ?
						color :
						"lightgrey" }}
					onClick={() => props.cleanSuggestions(res)}>
					{res.name}
				</div>
			)})}
		</div>
	);
}

export { SuggestionModal };
