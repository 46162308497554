import React, { useState } from "react";

import "./PopupUpdateSettings.css";
import "../../../Form/Form.css"
import ButtonStyle from "../../../../styled-components/ButtonStyle";
import { componentColor, cancelColor } from "../../../../utils/color";

function PopupUpdateSettings(props) {
    // const [shape, setShape] = useState(props.row.shape);
    const [color, setColor] = useState(props.row.color);
    const [content, setContent] = useState(props.settings[props.row.name[0]] ? props.settings[props.row.name[0]].content : 'default');

    const handleClose = (e) => {
        e.preventDefault();
        props.setShow(false);
    }

    const token = sessionStorage.getItem("cool-jwt");

    const updateSettings = () => {
        const newSettings = props.settings;
        if (newSettings[props.row.name[0]]) {
            newSettings[props.row.name[0]].color = color;
            newSettings[props.row.name[0]].shape = props.row.shape;
            // newSettings[props.row.name[0]].shape = shape;
            newSettings[props.row.name[0]].content = content;
        } else {
            let newSetting = {};
            newSetting.color = color;
            newSetting.shape = props.row.shape;
            // newSetting.shape = shape;
            newSetting.content = content;
            newSettings[props.row.name[0]] = newSetting;
        }
        props.updateSettings(token, newSettings);
    }

    // const generateShapes = () => {
    //     const shapes = props.shapes.map((element, i) => 
    //         <div key={i} className={element.name === shape ? 'setting--selected' : 'setting'} onClick={() => setShape(element.name)}>{element.svg}</div>
    //     )
    //     return shapes;
    // }

    const generateColors = () => {
        const colors = props.colors.map((element, i) => 
            <div key={i} className={element === color ? 'setting--selected' : 'setting'} onClick={() => setColor(element)} style={{backgroundColor: element, borderlineWidth: 3}}></div>
        )
        return colors;
    }

    const generateContent = () => {
        const contents = props.row.content.map((element, i) => 
            <span key={i} className={element === content ? 'content setting--selected' : 'content setting'} onClick={() => setContent(element)}>{element}</span>
        )
        return contents;
    }

	return (
		<div className='container--popupSettings'>
            <div className='background--popupSettings' onClick={handleClose}></div>
            <form className='container--form'>
                <h2>Mettre a jour les parametres pour {props.row.name[0]}</h2>
                {/* <div>
                    <h3>Forme</h3>
                    <div className='settings'>
                    {generateShapes()}
                    </div>
                </div> */}
                <div>
                    <h3>Couleur</h3>
                    <div className='settings'>
                    {generateColors()}
                    </div>
                </div>
                <div>
                    <h3>Contenu affiche</h3>
                    <div className='settings'>
                    {generateContent()}
                    </div>
                </div>
                <div className='container--form--button'>
                    <ButtonStyle
                        color={cancelColor}
                        onClick={() => props.setShow(false)}>
                        ANNULER
                    </ButtonStyle>
                    <ButtonStyle color={componentColor} onClick={() => updateSettings()}>
                        CONFIRMER
                    </ButtonStyle>
                </div>
            </form>
		</div>
	);
}

export { PopupUpdateSettings };
