import { CHANGE_PAGE } from "./index";

// ---------------------------- CHANGE NEMU --------------------------------------------------

// export const changeMenu = (aInt) => (dispatch) => {
// 	dispatch({
// 		type: CHANGE_MENU,
// 		payload: aInt,
// 	});
// };

// ---------------------------- CHANGE PAGE --------------------------------------------------

export const changePage = (aInt) => (dispatch) => {
	dispatch({
		type: CHANGE_PAGE,
		payload: aInt,
	});
};
