import {
	GET_RELATIONS,
	GET_RELATION_INFO,
	GET_PROPERTY_LIST_RELATION,
	DELETE_RELATION,
	ADD_RELATION,
	UPDATE_RELATION,
	GET_MAP_NODE,
} from "./index";

import axios from "axios";
import { ERROR } from "./userTypes";

const REACT_APP_GET_ALL_RELATIONS="/graph/relations";
const REACT_APP_GET_RELATION_INFO="/graph/relations/#type#";
const REACT_APP_GET_PROPERTY_RELATION="/graph/relations/property/#type#";
const REACT_APP_ADD_RELATION="/graph/relations";
const REACT_APP_DELETE_RELATION="/graph/relations/#relationId#";
const REACT_APP_UPDATE_RELATION="/graph/relations/update";
const token = sessionStorage.getItem("cool-jwt");

export const getRelations = () => (dispatch) => {
	const url = REACT_APP_GET_ALL_RELATIONS;
	axios
		.get(url, {
			headers: {
				Authorization: token
			}
		})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_RELATIONS,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Request failed", err);
		});
};

export const getRelationInfo = (type) => (dispatch) => {
	const url = REACT_APP_GET_RELATION_INFO;
	axios
		.get(url.replace("#type#", encodeURIComponent(type)))
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_RELATION_INFO,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Request failed", err);
		});
};

export const getPropertyListRelation = (relationType) => (dispatch) => {
	const url = REACT_APP_GET_PROPERTY_RELATION;

	axios
		.get(url.replace("#type#", encodeURIComponent(relationType)))
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_PROPERTY_LIST_RELATION,
				payload: data,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const deleteRelation = (relationId) => (dispatch) => {
	const url = REACT_APP_DELETE_RELATION;

	axios
		.delete(url.replace("#relationId#", encodeURIComponent(relationId)), {
			headers: {
				Authorization: token
			}
		})
		.then((response) => {
			dispatch({
				type: DELETE_RELATION,
				payload: relationId,
			})
		}
		)
		.catch((err) => {
			console.log("Add failed", err.response.data);
			dispatch({type: ERROR, payload: err.response.data});
		});
};

export const addRelation = (relationType, newRelation) => (dispatch) => {
	const url = REACT_APP_ADD_RELATION;
	var relationBody = {
		relationType: relationType.type,
		componentOriginType: newRelation.componentOriginType,
		componentOriginName: newRelation.componentOriginName,
		componentTargetType: newRelation.componentTargetType,
		componentTargetName: newRelation.componentTargetName,
		data: newRelation.data,
	};

	var relationPayload = {};

	if (relationBody.data === undefined) {
		Object.defineProperties(relationPayload, {
			origin: {
				value: newRelation.componentOriginName,
			},
			target: {
				value: newRelation.componentTargetName,
			},
			type: {
				value: relationType.type,
			},
		});
	} else {
		Object.defineProperties(relationPayload, {
			origin: {
				value: newRelation.componentOriginName,
			},
			target: {
				value: newRelation.componentTargetName,
			},
			type: {
				value: relationType.type,
			},
			data: {
				value: newRelation.data,
			},
		});
	}

	axios
		.put(url, relationBody, {
			headers: {
				Authorization: token
			}
		})
		.then((data) => {
			relationPayload = data.data;
			relationPayload.data = {};
			dispatch({ type: ADD_RELATION, payload: relationPayload })
			dispatch({ type: GET_MAP_NODE, payload: relationPayload })})
		.catch((err) => {
			console.log("Add failed", err.response.data);
			dispatch({type: ERROR, payload: err.response.data});
		});
};

export const updateRelation = (dataToUpdate) => (dispatch) => {
	const url = REACT_APP_UPDATE_RELATION;

	var updateBody = {};

	const dataUpdated = Object.entries(dataToUpdate);

	dataUpdated.map((inputField) => {
		return Object.defineProperty(updateBody, inputField[0], {
			value: inputField[1],
			writable: true,
			enumerable: true,
			configurable: true,
		});
	});

	axios
		.put(url, updateBody, {
			headers: {
				Authorization: token
			}
		})
		.then((data) => {
			updateBody = data.data;
			updateBody.oldId = dataToUpdate.id;
			dispatch({ type: UPDATE_RELATION, payload: updateBody })})
		.catch((err) => {
			console.log("Add failed", err.response.data);
			dispatch({type: ERROR, payload: err.response.data});
		});
};
