import React from "react";
import { ReactComponent as SearchLogo } from '../../assets/img/magnifying-glass-solid.svg';

function InputBox(props) {

	return (
		<div className='inputbox'>
			<input
				placeholder="Saisir deux caractères ou plus"
				value={props.query}
				onChange={props.handleChange}
				onKeyUp={props.search}
				onKeyPress={props.handleKeypressEnter}
			/>
			<SearchLogo style={{height: '25px', width: '25px', fill: 'grey'}}></SearchLogo>
		</div>
	);
}

export { InputBox };
