import React from "react";
import { Link } from "react-router-dom";

import "./MenuHeader.css";

import logo from "../../assets/Logo.png";

function MenuHeader({ changePage }) {

	return (
		<div className='container--logo'>
			<Link to='/'>
				<img src={logo} className='logo' alt='logo' onClick={() => changePage(0)} />
			</Link>
		</div>
	);
}

export { MenuHeader };

// <img data-tip='Export CSV' src={exp} alt='export' />
// <img data-tip='Import CSV' src={imp} alt='import' />
