

	export const getStrokeDasharray = (type, settings) => {
		if(type) {
			if (settings && settings[type] && settings[type].line) {
				switch(settings[type].line) {
					case 'dotted':
						return "2,2";
					case 'dashed':
						return "10,10";
					default:
						return 'none';
				}
			}
		}
		return 'none';
	}