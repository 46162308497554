

	export const updateNodePossibility = (types, nodes) => {
		let possibility = [];
		types.forEach(type => {
			let poss = [];
			nodes.forEach(node => {
				if (node.type === type) {
					poss = poss.concat(Object.keys(node));
				}
			})
			possibility[type] = [...new Set(poss.filter(
				(prop) => 
					prop !== 'id' &&
					prop !== 'name' &&
					prop !== 'description' &&
					prop !== 'index' &&
					prop !== 'y' &&
					prop !== 'x' &&
					prop !== 'fx' &&
					prop !== 'fy' &&
					prop !== 'vy' &&
					prop !== 'vx' &&
					prop !== 'cx' &&
					prop !== 'cy',
				))];
		});
		return possibility; // this.setState({nodePossibility: possibility});
	}