import React, { useState, useRef, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { getComponents, getNodeNames } from "../../action/componentAction";
import { getRelations } from "../../action/relationAction";
import { changePage } from "../../action/routingAction";

import {
	mapColor,
	componentColor,
	relationColor,
	settingsColor,
} from "../../utils/color";

import "./MenuAction.css";
import "./MenuHeader.css";

import map from "../../assets/menuAction/cartographie_color.png";
import composants from "../../assets/menuAction/composant.svg";
import relations from "../../assets/menuAction/relations_color.png";
import parametres from "../../assets/menuAction/parametres_color.png";
import excel from "../../assets/menuHeader/excel.png";
import excel_hover from "../../assets/menuHeader/excel_hover.png";
import infos from "../../assets/menuHeader/infos.png";
import infos_hover from "../../assets/menuHeader/infos_hover.png";
import logout from "../../assets/menuHeader/logout.png";
import logout_hover from "../../assets/menuHeader/logout_hover.png";
import jwt_decode from "jwt-decode";
import { Login } from "../../routes";

function MenuActionComponent(props) {
	const [hover, setHover] = useState(false);
	const [show, setShow] = useState(false);
	const [excelHover, setExcelHover] = useState(false);
	const [infosHover, setInfosHover] = useState(false);
	const [logoutHover, setLogoutHover] = useState(false);
	const [showLogin, setShowLogin] = useState(false);

	const history = useHistory();

	const hiddenFileInput = useRef();

	const user = sessionStorage.getItem("cool-jwt");

	const isAdmin = useMemo(() => {
		if (user) {
			const decode = jwt_decode(user);
			if (decode.isAdmin) {
				return true;
			}
			return false;
		}
	}, [user])

	const handleDeconnect = () => {
		sessionStorage.removeItem("cool-jwt");
		history.push("/");
		window.location.reload(true);
	};

	const showMenu = () => {
		setHover(true);
	};
	const hideMenu = () => {
		setHover(false);
		setShow(false);
	};

	const showSettings = (page) => {
		setShow(true);
		props.changePage(page);
	};

	const getFromAPIForMap = (page) => {
		if (props.isLogged) {
			props.getComponents();
			props.getRelations();
			props.getNodeNames();
			props.changePage(page);
		} else {
			setShowLogin(true);
		}
	};
	const getComponentFromAPI = (page) => {
		if (props.isLogged) {
			props.getComponents();
			props.changePage(page);
		} else {
			setShowLogin(true);
		}
	};
	const getRelationFromAPI = (page) => {
		if (props.isLogged) {
			props.getRelations();
			props.getComponents();
			props.changePage(page);
		} else {
			setShowLogin(true);
		}
	};
	
	const handleChange = event => {
		event.preventDefault();
		const fileUploaded = event.target.files[0];
		const reader = new FileReader(); 
        const formData = new FormData();
        formData.append('csv', event.target.files[0]);
		reader.onload = function(event) {
			props.setImportcsv({string: event.target.result.toString(), file: formData});
			props.setPopupImport(true);
			hiddenFileInput.current.value = null;
		}
		reader.readAsText(fileUploaded);
	  };

	  
	const handleClick = event => {
		if (props.isLogged) {
			hiddenFileInput.current.click();
		} else {
			setShowLogin(true);
		}
	};

	return (
		<>
		{showLogin ? <Login login={props.login}></Login> : null}
			<div
				className='container--menuAction--dep'
				style={{height: props.isLogged ? 520 : 445}}
				onMouseEnter={showMenu}
				onMouseLeave={hideMenu}
				>
					<div className='white--font--menu'>
					</div>
				<Link
					to='/mapzone'
					className={
						props.page === 1 ? "action--button select" : "action--button"
					}
					onClick={() => getFromAPIForMap(1)}>
					<img src={map} alt='map' />
					<span
						className={hover ? "button--dep" : "button--hide"}
						style={{ color: mapColor }}>
						Cartographie
					</span>
				</Link>
				<Link
					to='/components'
					className={
						props.page === 2 ? "action--button select" : "action--button"
					}
					onClick={() => getComponentFromAPI(2)}>
					<img src={composants} alt='composants' />
					<span
						className={hover ? "button--dep" : "button--hide"}
						style={{ color: componentColor }}>
						Composants
					</span>
				</Link>
				<Link
					to='/relations'
					className={
						props.page === 3 ? "action--button select" : "action--button"
					}
					onClick={() => getRelationFromAPI(3)}>
					<img src={relations} alt='relations' />
					<span
						className={hover ? "button--dep" : "button--hide"}
						style={{ color: relationColor }}>
						Relations
					</span>
				</Link>
				<div
					className={
						props.page === 4 ? "action--button select" : "action--button"
					}
					onClick={() => showSettings(4)}>
					<img src={parametres} alt='parametres' />
					<span
						className={hover ? "button--dep" : "button--hide"}
						style={{ color: settingsColor }}>
						Paramètres
					</span>
					{show ?
					<div className="settingsbutton" onMouseLeave={() => setShow(false)}>
						<Link
							to='/settings/node'
							onClick={() => getRelationFromAPI(3)}>
								<span
									className={hover ? "" : "button--hide"}
									style={{ color: componentColor }}>
									Composants
								</span>
						</Link>
						<Link
							to='/settings/relation'
							onClick={() => getRelationFromAPI(3)}>
								<span
									className={hover ? "" : "button--hide"}
									style={{ color: relationColor }}>
									Relations
								</span>
						</Link>
						<Link
							to='/settings/user'
							onClick={() => getRelationFromAPI(3)}>
								<span
									className={hover ? "" : "button--hide"}
									style={{ color: settingsColor }}>
									Mon Compte
								</span>
						</Link>
						{ isAdmin ?
						<>
							<Link
								to='/settings/properties'
								onClick={() => getRelationFromAPI(3)}>
									<span
										className={hover ? "" : "button--hide"}
										style={{ color: settingsColor }}>
										Proprietes
									</span>
							</Link>
							<Link
								to='/settings/database'
								onClick={() => getRelationFromAPI(3)}>
									<span
										className={hover ? "" : "button--hide"}
										style={{ color: settingsColor }}>
										Database
									</span>
							</Link>
							<Link
								to='/settings/admin'
								onClick={() => getRelationFromAPI(3)}>
									<span
										className={hover ? "" : "button--hide"}
										style={{ color: settingsColor }}>
										Administration
									</span>
							</Link>
						</>
						: null }
					</div> : null }
				</div>
					<input
							type="file"
							accept=".csv"
							ref={hiddenFileInput}
							style={{display: 'none'}}
							onChange={handleChange}
							/>
						<div
							className="action--button"
							onClick={() => handleClick()}>
							{excelHover ? (
								<img
									data-tip='Import csv'
									src={excel_hover}
									alt='info'
									onMouseLeave={() => setExcelHover(false)}
								/>
							) : (
								<img
									src={excel}
									alt='info'
									onMouseEnter={() => setExcelHover(true)}
								/>
							)}
							<span
								className={hover ? "button--dep" : "button--hide"}
								style={{ color: mapColor }}>
								Import csv
							</span>
						</div>
						<Link
							to='/info'
							onClick={() => props.changePage(5)}
							className="action--button">
							{infosHover ? (
								<img
									data-tip='Info logiciel'
									src={infos_hover}
									alt='info'
									onMouseLeave={() => setInfosHover(false)}
								/>
							) : (
								<img
									src={infos}
									alt='info'
									onMouseEnter={() => setInfosHover(true)}
								/>
							)}
							<span
								className={hover ? "button--dep" : "button--hide"}
								style={{ color: mapColor }}>
								Info Logiciel
							</span>
						</Link>
						{
							props.isLogged ?
							<div
								className="action--button"
								onClick={() => handleDeconnect()}>
								{logoutHover ? (
									<img
										data-tip='Deconnexion'
										src={logout_hover}
										className='logout'
										alt='exit'
										onMouseLeave={() => setLogoutHover(false)}
									/>
								) : (
									<img
										src={logout}
										className='logout'
										alt='exit'
										onMouseEnter={() => setLogoutHover(true)}
									/>
								)}
								<span
									className={hover ? "button--dep" : "button--hide"}
									style={{ color: mapColor }}>
									Deconnexion
								</span>
							</div>
							: null
						}

			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
	page: state.routingReducer.page,
});

const mapDispatchToProps = (dispatch) => ({
	changePage: (aInt) => dispatch(changePage(aInt)),
	getComponents: () => dispatch(getComponents()),
	getRelations: () => dispatch(getRelations()),
	getNodeNames: () => dispatch(getNodeNames()),
});
const MenuAction = connect(
	mapStateToProps,
	mapDispatchToProps,
)(MenuActionComponent);
export { MenuAction };

// ==========================

// <div
// 	className={show ? "action--button--settings" : "settings--hide"}
// 	onMouseLeave={hideSettings}>
// 	<Link
// 		to='/settings--components'
// 		className='settings'
// 		onClick={() => props.changePage(4)}>
// 		<span>Composants</span>
// 	</Link>
// 	<Link
// 		to='/settings--relations'
// 		className='settings'
// 		onClick={() => props.changePage(4)}>
// 		<span>Relations</span>
// 	</Link>
// 	<Link
// 		to='/settings--properties'
// 		className='settings'
// 		onClick={() => props.changePage(4)}>
// 		<span>Propriété</span>
// 	</Link>
// </div>

// ==========================

// <div
// 			className={
// 				hover ? "container--menuAction--dep" : "container--menuAction--min"
// 			}
// 			onMouseEnter={showMenu}
// 			onMouseLeave={hideMenu}>
// 			<Link
// 				to='/mapzone'
// 				className={
// 					props.page === 1 ? "action--button select" : "action--button"
// 				}
// 				onClick={() => getFromAPIForMap(1)}>
// 				<img src={map} alt='map' />
// 				<p className={hover ? "button--dep" : "button--hide"}>Cartographie</p>
// 			</Link>
// 			<Link
// 				to='/components'
// 				className={
// 					props.page === 2 ? "action--button select" : "action--button"
// 				}
// 				onClick={() => getComponentFromAPI(2)}>
// 				<img src={composants} alt='composants' />
// 				<span className={hover ? "button--dep" : "button--hide"}>
// 					Composants
// 				</span>
// 			</Link>
// 			<Link
// 				to='/relations'
// 				className={
// 					props.page === 3 ? "action--button select" : "action--button"
// 				}
// 				onClick={() => getRelationFromAPI(3)}>
// 				<img src={relations} alt='relations' />
// 				<span className={hover ? "button--dep" : "button--hide"}>
// 					Relations
// 				</span>
// 			</Link>
// 			<Link
// 				to=''
// 				className={
// 					props.page === 4 ? "action--button select" : "action--button"
// 				}
// 				onClick={() => showSettings(4)}>
// 				<img src={parametres} alt='parametres' />
// 				<span className={hover ? "button--dep" : "button--hide"}>
// 					Paramètres
// 				</span>
// 			</Link>
// 		</div>
