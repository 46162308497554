import React, { useState } from "react";
import ReactTooltip from "react-tooltip";

import { relationColor, cancelColor } from "../../../utils/color";

import ButtonStyle from "../../../styled-components/ButtonStyle";
import { capitalizeWord, sortedFunction } from "../../../utils/Function";

import "../Form.css";
import { InputAddProps } from "../..";

function FormRelation(props) {
	const [relationType, setRelationType] = useState({
		type: props.type,
	});
	const [double, setDouble] = useState(false);
	const [customType, setCustomType] = useState('');

	const [newRelation, setNewRelation] = useState({
		componentOriginType: props.selected ? props.selected[0].type : "",
		componentOriginName: props.selected ? props.selected[0].Nom : "",
		componentTargetType: props.selected ? props.selected[1].type : "",
		componentTargetName: props.selected ? props.selected[1].Nom : "",
	});
	const [inputFields, setInputFields] = useState([
		{ property: "", content: "" },
	]);

	const handleChangeType = (event) => {
		setRelationType({
			[event.target.name]: event.target.value,
		});
	};

	const handleChange = (event) => {
		setNewRelation({
			...newRelation,
			[event.target.name]: event.target.value,
		});
		if (event.target.name === "componentOriginType") {
			propsGetComponentOriginNames(event.target.value);
		} else if (event.target.name === "componentTargetType") {
			propsGetComponentTargetNames(event.target.value);
		}
	};

	const formatNewInput = () => {
		if (inputFields.length === 1 && inputFields[0].property === '') {
			return undefined;
		}
		let inputs = [];
		for (const input of inputFields) {
			inputs = {
				...inputs,
				[input.property]: input.content,
			}
		}
		inputs = {...inputs};
		return inputs;
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		if (relationType.type === '' && customType !== '') {
			relationType.type = customType;
		}
		newRelation.data = formatNewInput();
		props.addRelation(relationType, newRelation);
		props.setPopupCreate(false);
	};

	// =======================================================================

	const propsGetComponentOriginNames = props.getComponentOriginNames;
	const propsGetComponentTargetNames = props.getComponentTargetNames;

	// =======================================================================

	return (
		<form onSubmit={handleSubmit} className='container--form'>
			<h2 style={{ color: relationColor }}>AJOUTER UNE RELATION</h2>
			<div className='container--tuple--relation'>
				<div className='container--info--tuple'>
					<div className='group--input'>
						<select
							name='componentOriginType'
							onChange={handleChange}
							value={newRelation.componentOriginType}
							required
							style={{ color: relationColor }}>
							<option value=''></option>
							{props.selected ? (props.selected.map((component, i) => {
									return (
										<option key={i} value={component.type}>
											{component.type}
										</option>
									);
								})) : (props.components &&
								props.components.map((component, i) => {
									return (
										<option
											key={i}
											className='optionList'
											value={component.name}
											style={{ color: relationColor }}>
											{component.name}
										</option>
									);
								}))}
						</select>
						<span
							className='floating--label--select required'
							style={{ color: relationColor }}>
							Composant origine
						</span>
					</div>
					<div className='group--input'>
						<select
							name='componentOriginName'
							onChange={handleChange}
							value={newRelation.componentOriginName}
							required
							style={{ color: relationColor }}>
							<option value=''></option>
							{props.selected ? (props.selected.map((component, i) => {
									return (
										<option key={i} value={component.name}>
											{component.name}
										</option>
									);
								})) : (props.componentOriginName &&
								props.componentOriginName.map((component, i) => {
									return (
										<option key={i} value={component.name}>
											{component.name}
										</option>
									);
								}))}
						</select>
						<span
							className='floating--label--select required'
							style={{ color: relationColor }}>
							Nom du composant d'origine
						</span>
					</div>
				</div>
				<div className='container--info--tuple'>
					<div className='group--input rerel'>
						{relationType.type === '' && !props.typeFromGraph ? 
						<input 
							name='type' 
							style={{ color: relationColor }}
							value={customType} 
							onChange={(e) => setCustomType(capitalizeWord(e.target.value))}/> : null }
						<select
							name='type'
							onChange={handleChangeType}
							value={
								props.typeFromGraph ? props.typeFromGraph : relationType.type
							}
							required={customType === ''}
							style={{ color: relationColor, width: relationType.type === '' && !props.typeFromGraph ? '15px' : 'auto' }}>
							<option value=''></option>
							{props.relations.map((relation, i) => {
								return (
									<option key={i} value={relation.name}>
										{relation.name}
									</option>
								);
							})}
						</select>
						<span
							className='floating--label--select required'
							style={{ color: relationColor }}>
							Relation
						</span>
					</div>
				</div>
				<div className='container--info--tuple'>
					<div className='group--input'>
						<select
							name='componentTargetType'
							onChange={handleChange}
							value={newRelation.componentTargetType}
							required
							style={{ color: relationColor }}>
							<option value=''></option>
							{props.selected ? (props.selected.map((component, i) => {
									return (
										<option key={i} value={component.type}>
											{component.type}
										</option>
									);
								})) : (props.components &&
								props.components.map((component, i) => {
									return (
										<option key={i} value={component.name}>
											{component.name}
										</option>
									);
								}))}
						</select>
						<span
							className='floating--label--select required'
							style={{ color: relationColor }}>
							Composant cible
						</span>
					</div>
					<div className='group--input'>
						<select
							name='componentTargetName'
							onChange={handleChange}
							value={newRelation.componentTargetName}
							required
							style={{ color: relationColor }}>
							<option value=''></option>
							{props.selected ? (props.selected.map((component, i) => {
									return (
										<option key={i} value={component.name}>
											{component.name}
										</option>
									);
								})) : (props.componentTargetName &&
								props.componentTargetName.map((component, i) => {
									return (
										<option key={i} value={component.name}>
											{component.name}
										</option>
									);
								}))}
						</select>
						<span
							className='floating--label--select required'
							style={{ color: relationColor }}>
							Nom du composant cible
						</span>
					</div>
				</div>
			</div>
			<InputAddProps
				type={props.type}
				components={props.components}
				inputFields={inputFields}
				setInputFields={setInputFields}
				properties={sortedFunction(props.info)}
				double={double}
				setDouble={setDouble}
				relation={true}
				propertiesList={props.propertiesListColumnRelation ? props.propertiesListColumnRelation.filter(property => 
					property !== 'Created'
					&& property !== 'Updated'
					&& property !== 'UpdatedBy'
					&& property !== 'CreatedBy') : []}
			/>
			<div className='container--form--button'>
				<ButtonStyle
					color={cancelColor}
					onClick={() => props.setPopupCreate(false)}>
					ANNULER
				</ButtonStyle>
				<ButtonStyle color={relationColor} type='submit'>
					CONFIRMER
				</ButtonStyle>
			</div>
			<ReactTooltip place='right' effect='solid' />
		</form>
	);
}

export { FormRelation };
