import React from "react";

import "./LoadCartosPopup.css";
import { ReactComponent as Trash } from '../../../../assets/img/trash-alt-solid.svg';
import { mapDeleteCarto, mapLoadCarto } from "../../../../action/mapAction";
import { connect } from "react-redux";
import { useEffect } from "react";

function LoadCartosPopupComponent(props) {

    useEffect(() => {
        props.mapLoadCarto(sessionStorage.getItem("cool-jwt"));
    }, []);
    
    const generateGraphs = () => {
        const savedGraph = props.savedCartos;
        if (savedGraph) {
            return (savedGraph.map((graph, index) => {
                return (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Trash style={{ with: '20px', height: '20px', fill: 'red', marginLeft: '120px' }} onClick={() => props.mapDeleteCarto(sessionStorage.getItem("cool-jwt"), graph.id)} />
                        <div key={'graphSave-' + index} onClick={() => {props.loadGraph(graph.data);props.setLastCartoLoaded(graph);props.setPopup()}} className="graph__save__graph">
                            <svg width={"100px"} height={"100px"} viewBox={"0 0 1000 1000"} dangerouslySetInnerHTML={{__html: graph.data.image.data}}>
                            </svg>
                        </div>
                        <p>{graph.name}</p>
                        <p>{graph.data.date}</p>
                    </div>
                );
            }))
        }
    }

	return (
		<div className='container--popupSettings'>
            <div className='background--popupSettings' onClick={() => props.setPopup()}></div>
            <div className='container--carto'>
                <h2 style={{ paddingBottom: '20px'}}>Choisir une cartographie a importer</h2>
                <div className="carto--list">
                    {generateGraphs()}
                </div>
            </div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	savedCartos: state.mapReducer.savedCartos,
});

const mapDispatchToProps = (dispatch) => ({
	mapLoadCarto: (token) => dispatch(mapLoadCarto(token)),
    mapDeleteCarto: (token, carto) => dispatch(mapDeleteCarto(token, carto))
});

const LoadCartosPopup = connect(mapStateToProps, mapDispatchToProps)(LoadCartosPopupComponent);

export { LoadCartosPopup };
