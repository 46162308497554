import React, { useMemo, useState } from "react";

import { useTable } from 'react-table';
import { StylesComponent } from "./NodeSettingsStyle";
import { ReactComponent as Square } from '../../../assets/img/square-regular.svg';
import { ReactComponent as Circle } from '../../../assets/img/circle-regular.svg';
import { ReactComponent as Cube } from '../../../assets/img/cube-solid.svg';
import { PopupUpdateSettings } from "./PopupUpdateSettings/PopupUpdateSettings";
import { colors } from "../../../utils/color";

const NodeSettings = (props) => {
	const [rowInfo, setRowInfo] = useState();
	const [show, setShow] = useState(false);

	const shapes = [
		{name: 'circle', svg: <Circle/>},
		{name: 'square', svg: <Square/>},
		{name: 'cube', svg: <Cube/>}
	];

	const handlePopup = (row) => {
		setShow(true);
		setRowInfo(row);
	}

	// eslint-disable-next-line
	const columns = useMemo(
		() => [
		  {
			Header: 'Composant',
			accessor: 'name'
		  },
		  {
			Header: 'Nombre',
			accessor: 'number'
		  },
		  {
			Header: 'Nb propriétés',
			accessor: 'propertiesCount'
		  },
		  {
			Header: 'Couleur',
			accessor: 'color'
		  },
		  {
			Header: 'Forme',
			accessor: 'shape'
		  },
		  {
			Header: 'Contenu',
			accessor: 'content'
		  },
		],
		[]
	  )
	const data = useMemo(() => {
		const value = props.nodes && props.settings ? props.nodes : [];
			value.forEach(element => {
				element.shape = props.settings[element.name] ? props.settings[element.name].shape : 'default';
				element.color = props.settings[element.name] ? props.settings[element.name].color : 'default';
				element.content = element.content.filter(element => 
					element !== 'Created'
					&& element !== 'CreatedBy'
					&& element !== 'Updated'
					&& element !== 'UpdatedBy'
				);
				element.propertiesCount = element.content.length;
			});
		return (value);
	}, [props.settings, props.nodes]);
	const tableInstance = useTable({
		columns,
		data
	})
	
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	  } = tableInstance;
	
	return (
		<> {show ? 
			<PopupUpdateSettings
				row={rowInfo}
				settings={props.settings}
				updateSettings={props.updateSettings}
				setShow={setShow}
				shapes={shapes}
				colors={colors}
				/> : null}
			{props.nodes ? 
			<StylesComponent>
				<table {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row, i) => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps()} onClick={() => handlePopup(row.values)}>
							{row.cells.map(cell => {
								if (cell.column.id === 'color') {
									if (cell.value === 'default') {
										cell.value = colors[0];
									}
									return <td {...cell.getCellProps()} style={{backgroundColor: cell.value}}></td>
								} else if (cell.column.id === 'shape') {
									if (cell.value === 'default') {
										cell.value = 'circle';
									}
									return <td {...cell.getCellProps()}>{shapes.find(element => element.name === cell.value)?.svg}</td>
								} else if (cell.column.id === 'content') {
									if (cell.value.length === 0) {
										cell.value = ['Nom'];
									}
									return <td {...cell.getCellProps()}>{cell.value.join(" - ")}</td>
								}
								return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
							})}
							</tr>
						)
						})}
					</tbody>
				</table>
			</StylesComponent>
			: null}
		</>
	);
}

export default NodeSettings;
