import React from "react";

import { ReactComponent as DeleteLogo } from "../../assets/img/trash-alt-solid.svg";
import { componentColor, relationColor } from "../../utils/color";

export const DeleteButton = (props) => {
	const clickOnDeleteComponent = (componentId, componentType, rowName) => {
		props.setPopupDelete(true);
		props.setComponentId(componentId);
		props.getRowName(rowName);
		props.getComponentRelationNumber(componentType, rowName);
	};

	const clickOnDeleteRelation = (relationId, rowOrigin, rowTarget) => {
		props.setPopupDelete(true);
		props.setRelationId(relationId);
		props.getRelationInstance(rowOrigin, rowTarget);
	};

	return (
		<DeleteLogo
			className='delete--logo'
			alt='delete'
			style={{fill: props.componentPage ? componentColor : relationColor}}
			onClick={() => {
				props.componentPage
					? clickOnDeleteComponent(
							props.row.original.id,
							props.type,
							props.row.original.Nom,
					  )
					: clickOnDeleteRelation(
							props.row.original.id,
							props.row.original.origin,
							props.row.original.target,
					  );
			}}
			data-tip='Supprimer'
		/>
	);
};
