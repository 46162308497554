import React from "react";
import { ReactComponent as Pencil } from '../../assets/img/pencil-solid.svg';
import { componentColor, relationColor } from "../../utils/color";

export const UpdateButton = (props) => {
	const clickOnUpdateComponent = (row) => {
		props.setPopupUpdate(true);
		props.setInfoUpdate(row);
		props.getPropertyListComponent(props.type);
	};

	const clickOnUpdateRelation = (row) => {
		props.setPopupUpdate(true);
		props.setInfoUpdate(row);
		props.getPropertyListRelation(props.type);
	};

	return (
		<Pencil
			src={props.componentPage ? props.updateComponent : props.updateRelation}
			className='update--logo'
			style={{fill: props.componentPage ? componentColor : relationColor}}
			alt='update'
			onClick={() => {
				props.componentPage
					? clickOnUpdateComponent(props.row.original, props.type)
					: clickOnUpdateRelation(props.row.original, props.type);
			}}
			data-tip='Mettre à jour'
		/>
	);
};
