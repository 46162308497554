import {
	GET_SETTINGS,
    DELETE_SETTINGS,
    ADD_SETTINGS,
    UPDATE_SETTINGS,
	GET_DATABASE,
	UPDATE_DATABASE,
	GET_USER_LIST,
	GET_ROLE_LIST,
	ADD_ROLE,
	ADD_USER,
	DELETE_ROLE,
	DELETE_USER,
	UPDATE_ROLE,
	UPDATE_USER,
	GET_USER
} from "../action";

import {
	GET_ALL_PROPERTIES,
	UPDATE_PROPERTIE,
	DELETE_PROPERTIES
} from "../action";

export const settingsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_SETTINGS:
			return { ...state, settings: action.payload.settings, title: action.payload.title};

		case DELETE_SETTINGS:
			return { ...state, settings: {} };

		case ADD_SETTINGS:
			state.settings.push(action.payload);
			state = { ...state };
			return state;

		case UPDATE_SETTINGS:
			return state;

		case UPDATE_DATABASE:
			return state;

		case GET_DATABASE:
			return {...state, databases: action.payload};
		
		case GET_USER_LIST: {
			return {...state, userList: action.payload.users}
		}

		case GET_USER: {
			return {...state, user: action.payload}
		}
		
		case GET_ROLE_LIST: {
			return {...state, roleList: action.payload.roles}
		}
		
		case ADD_ROLE: {
			return {...state, roleList: state.roleList.concat([action.payload])}
		}
		
		case ADD_USER: {
			return {...state, userList: state.userList.concat([action.payload])}
		}
		
		case DELETE_ROLE: {
			return {...state, roleList: state.roleList.filter(el => el.name !== action.payload.name), userList: state.userList.map(el => {
				el.role = el.role.filter(rol => rol.name !== action.payload.name);
				return el;
			})}
		}
		
		case DELETE_USER: {
			return {...state, userList: state.userList.filter(el => el.username !== action.payload.username)}
		}
		
		case UPDATE_ROLE: {
			const {roleData, oldRoleData} = action.payload;
			return {...state, roleList: state.roleList.map(el => {
				if (el.name === oldRoleData.name) {
					return roleData;
				}
				return el;
			})}
		}
		
		case UPDATE_USER: {
			const {userData, oldUserData} = action.payload;
			return {...state, userList: state.userList.map(el => {
				if (el.username === oldUserData.username) {
					return userData;
				}
				return el;
			})}
		}

		default:
			return state;
	}
};

export const propertiesReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_PROPERTIES:
			return { ...state, properties: action.payload };

		case DELETE_PROPERTIES:
			return { ...state, properties: {} };

		case UPDATE_PROPERTIE:
			const propertie = state.properties.find(element => element.propertie === action.payload.old);
			propertie.propertie = action.payload.new;
			return state;

		default:
			return state;
	}
};