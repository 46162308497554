import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getUser, updatePassword } from "../../../action/settingsAction";
import ButtonStyle from "../../../styled-components/ButtonStyle";
import { settingsColor } from "../../../utils/color";


const UserSettingsComponent = (props) => { 
    const user = sessionStorage.getItem("cool-jwt");

    const userData = props.user;
    useEffect(() => {
        if (user) {
            props.getUser(user);
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const oldPass = data.get('password1');
        const newPass = data.get('password2');
        props.updatePassword(user, oldPass, newPass);
    }

	return (
		<>
            {userData ? 
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}> 
				<h1>Mon Compte</h1>
                
            <form className='container--form' style={{width: '50%'}} onSubmit={handleSubmit}>
                <div>
                    <h3>Identifiant</h3>
                    <input disabled name="username" value={userData.username} type="text"></input>
                </div>
                <div>
                    <h3>Nom</h3>
                    <input disabled required name="lastname" value={userData.lastname} type="text"></input>
                </div>
                <div>
                    <h3>Prenom</h3>
                    <input disabled required name="firstname" value={userData.firstname} type="text"></input>
                </div>
                <div>
                    <h3>Description</h3>
                    <input disabled required name="function" value={userData.function} type="text"></input>
                </div>
                <div>
                    <h3>Mot de passe</h3>
                    <input required name="password1" type="text" placeholder="Ancien mot de passe"></input>
                    <input required name="password2" type="text" placeholder="Nouveau mot de passe"></input>
                </div>
                <div className='container--form--button'>
                    <ButtonStyle color={settingsColor} type='submit'>
                        CONFIRMER
                    </ButtonStyle>
                </div>
            </form>
			</div>
            : null }
		</>
	);
}

const userStateToProps = (state) => ({
	user: state.settingsReducer.user,
})

const userDispatchToProps = (dispatch) => ({
    getUser: (user) => dispatch(getUser(user)),
    updatePassword: (user, oldPass, newPass) => dispatch(updatePassword(user, oldPass, newPass))
})

const UserSettings = connect(userStateToProps, userDispatchToProps)(UserSettingsComponent)

export { UserSettings };
