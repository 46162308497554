import React from "react";

export const GlobalFilter = ({ gotoPage, filter, setFilter }) => {
	const style = {
		height: "30px",
		padding: "10px",
		borderRadius: "20px",
		boxShadow: "2px 0px 3px #ccc",
		border: "none",
		outline: "none",
	};
	return (
		<span>
			<input
				value={filter || ""}
				onChange={(e) => {
					gotoPage(0);
					setFilter(e.target.value)
				}}
				style={style}
				placeholder='Rechercher'
			/>
		</span>
	);
};
