import { getNodeRadius } from "./get/getNodeRadius";
import * as d3 from "d3";

	export const wrap = (settings) => (d, i , nodes) => {
		var text = d3.select(nodes[i]),
            words = text.text().split(/\s+/).reverse(),
			width = getNodeRadius(d.type, settings) * 1.5,
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            x = text.attr("x"),
            y = text.attr("y"),
            dy = 0, //parseFloat(text.attr("dy")),
            tspan = text.text(null)
                        .append("tspan")
                        .attr("x", x)
                        .attr("y", y)
                        .attr("dy", dy + "em");
		let j = 0;
        while (word = words.pop()) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width && j !== 0) {
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                tspan = text.append("tspan")
                            .attr("x", x)
                            .attr("y", y)
                            .attr("dy", ++lineNumber * lineHeight + dy + "em")
                            .text(word);
            }
			j++;
        }
	}