import React, { useState } from "react";

import "../../../Form/Form.css"
import ButtonStyle from "../../../../styled-components/ButtonStyle";
import { settingsColor, cancelColor } from "../../../../utils/color";

function PopupAddUser(props) {
    const [userData, setUserData] = useState({
        username: '',
        firstname: '',
        lastname: '',
        function: '',
        password: '',
    });
    const [error, setError] = useState('');

    const handleClose = (e) => {
        e.preventDefault();
        props.setShow(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.addUser(userData);
        props.setShow(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'username' && props.userList.find(el => el.username === value)) {
            setError('Cet identifiant est deja utilise');
        } else if (error !== '' && name === 'username') {
            setError('');
        }
        setUserData({ ...userData, [name]: value });
    }

	return (
		<div className='container--popupSettings'>
            <div className='background--popupSettings' onClick={handleClose}></div>
            <form className='container--form' onSubmit={handleSubmit}>
                <h2>Ajouter un nouvel utilisateur</h2>
                <div>
                    <h3>Identifiant</h3>
                    <input required name="username" value={userData.username} onChange={handleChange} type="text"></input>
                </div>
                <div>
                    <h3>Nom</h3>
                    <input required name="lastname" value={userData.lastname} onChange={handleChange} type="text"></input>
                </div>
                <div>
                    <h3>Prenom</h3>
                    <input required name="firstname" value={userData.firstname} onChange={handleChange} type="text"></input>
                </div>
                <div>
                    <h3>Description</h3>
                    <input required name="function" value={userData.function} onChange={handleChange} type="text"></input>
                </div>
                <div>
                    <h3>Mot de passe</h3>
                    <input required name="password" value={userData.password} onChange={handleChange} type="text"></input>
                </div>
                <div className='container--form--button'>
                    <ButtonStyle
                        color={cancelColor}
                        onClick={() => props.setShow(false)}>
                        ANNULER
                    </ButtonStyle>
                    {error !== '' ? (
					<span className='duplicate--property'>{error}</span>
				) : (
                    <ButtonStyle color={settingsColor} type='submit'>
                        CONFIRMER
                    </ButtonStyle>
                )}
                </div>
            </form>
		</div>
	);
}

export { PopupAddUser };
