import { LOGIN, GET_USER_WITH_ID } from "./index";

import axios from "axios";
import { RESET_ERROR } from "./userTypes";

const REACT_APP_USER_LOGIN="/graph/user/login";
const REACT_APP_GET_USER_WITH_ID="/graph/user/#userId#";

// ----------------------------  LOGIN --------------------------------------------------

export const login = (userInfo) => (dispatch) => {
	const url = REACT_APP_USER_LOGIN;
	var login = {
		username: userInfo.username,
		password: userInfo.password,
	};

	axios
		.post(url, login)
		.then((res) => {
			dispatch({
				type: LOGIN,
				payload: res.data,
			});
			sessionStorage.setItem("cool-jwt", res.data.token);
			window.location.reload();
		})
		.catch((err) =>
			alert("combinaison identifiant & mot de passe incorrect :( !!"),
		);
};

export const resetError = () => (dispatch) => {
	dispatch({type: RESET_ERROR, payload: ''})
}

// --------------------------------- GET USER WITH ID --------------------------------------------------

export const getUserWithId = (userId) => (dispatch) => {
	const url = REACT_APP_GET_USER_WITH_ID;
	axios
		.get(url.replace("#userId#", encodeURIComponent(userId)))
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_USER_WITH_ID,
				payload: data.results[0],
			});
		})
		.catch(function (error) {
			console.log("Request failed", error);
		});
};
