const GET_NODE_NAMES = "GET_NODE_NAMES";
const GET_COMPONENTS = "GET_COMPONENTS";
const GET_COMPONENT_INFO = "GET_COMPONENT_INFO";
const GET_COMPONENT_TO_UPDATE = "GET_COMPONENT_TO_UPDATE";
const GET_COMPONENT_ORIGIN_NAME = "GET_COMPONENT_ORIGIN_NAME";
const GET_COMPONENT_TARGET_NAME = "GET_COMPONENT_TARGET_NAME";
const GET_COMPONENT_RELATION_NUMBER = "GET_COMPONENT_RELATION_NUMBER";
const GET_PROPERTY_LIST_COMPONENT = "GET_PROPERTY_LIST_COMPONENT";
const ADD_COMPONENT = "ADD_COMPONENT";
const ADD_PROPERTY = "ADD_PROPERTY";
const DELETE_COMPONENT = "DELETE_COMPONENT";
const SEE_RELATIONS = "SEE_RELATIONS";
const UPDATE_COMPONENT = "UPDATE_COMPONENT";
// const RECORD_COLUMN_NAME = "RECORD_COLUMN_NAME";

export {
	GET_NODE_NAMES,
	GET_COMPONENTS,
	GET_COMPONENT_INFO,
	GET_COMPONENT_TO_UPDATE,
	GET_COMPONENT_ORIGIN_NAME,
	GET_COMPONENT_TARGET_NAME,
	GET_COMPONENT_RELATION_NUMBER,
	GET_PROPERTY_LIST_COMPONENT,
	ADD_COMPONENT,
	ADD_PROPERTY,
	DELETE_COMPONENT,
	SEE_RELATIONS,
	UPDATE_COMPONENT,
	// RECORD_COLUMN_NAME,
};
