import React, { useState } from "react";

import "./SaveCartoPopup.css";
import "../../../Form/Form.css"
import ButtonStyle from "../../../../styled-components/ButtonStyle";
import { componentColor, cancelColor } from "../../../../utils/color";

function SaveCartoPopup(props) {
    const [name, setName] = useState('');

    const user = sessionStorage.getItem("cool-jwt");

	return (
		<div className='container--popupSettings'>
            <div className='background--popupSettings' onClick={() => props.setPopup()}></div>
            <div className='container--form'>
                { Object.keys(props.lastCartoLoaded).length > 0 ? 
                <>
                <h2>Mettre a jour la derniere cartographie</h2>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div className="graph__save__graph">
                            <svg width={"100px"} height={"100px"} viewBox={"0 0 1000 1000"} dangerouslySetInnerHTML={{__html: props.lastCartoLoaded.data.image.data}}>
                            </svg>
                        </div>
                        <p>{props.lastCartoLoaded.name}</p>
                        <p>{props.lastCartoLoaded.data.date}</p>
                    </div>
                <div className='container--form--button'>
                    <ButtonStyle
                        color={cancelColor}
                        onClick={() => props.setPopup()}>
                        ANNULER
                    </ButtonStyle>
                    <ButtonStyle color={componentColor} onClick={() => {props.updateCarto(props.lastCartoLoaded, user);props.setPopup()}}>
                        CONFIRMER
                    </ButtonStyle>
                </div>
                </>
                : null }
                <h2>Choisir un nom pour votre nouvelle cartographie</h2>
                <div>
                    <input onChange={(e) => setName(e.target.value)}></input>
                </div>
                <div className='container--form--button'>
                    <ButtonStyle
                        color={cancelColor}
                        onClick={() => props.setPopup()}>
                        ANNULER
                    </ButtonStyle>
                    <ButtonStyle color={componentColor} onClick={() => {props.saveCarto(name);props.setPopup()}}>
                        CONFIRMER
                    </ButtonStyle>
                </div>
            </div>
		</div>
	);
}

export { SaveCartoPopup };
