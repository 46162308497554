import React, { useState } from "react";
import "../css/Login.css";

function Login(props) {
	const [userInfo, setUserInfo] = useState({
		username: "",
		password: "",
	});

	const handleChange = (event) => {
		setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
	};

	const submit = (event) => {
		event.preventDefault();
		props.login(userInfo);
	};

	return (
		<form
			onSubmit={(event) => submit(event)}
			className='container--form--login'>
			<div className=''>
				<div className='form--connexion'>
					<h1>Connexion</h1>
					<div className='form--connexion--input'>
						<div className='form--connexion--label'>
							<label>Identifiant</label>
						</div>
						<input
							onChange={handleChange}
							name='username'
							value={userInfo.username}
							type='text'
							required
						/>
					</div>
					<div className='form--connexion--input'>
						<div className='form--connexion--label'>
							<label>Mot de passe</label>
						</div>
						<input
							onChange={handleChange}
							name='password'
							value={userInfo.password}
							type='password'
							required
							placeholder='*****'
						/>
					</div>
					<button className='btn--signin' type='submit'>
						Se connecter
					</button>
				</div>
			</div>
		</form>
	);
}

export { Login };
