import {
	GET_NODE_NAMES,
	GET_COMPONENTS,
	GET_COMPONENT_INFO,
	GET_COMPONENT_ORIGIN_NAME,
	GET_COMPONENT_TARGET_NAME,
	GET_COMPONENT_RELATION_NUMBER,
	GET_PROPERTY_LIST_COMPONENT,
	GET_MAP_NODE,
	DELETE_COMPONENT,
	ADD_COMPONENT,
	SEE_RELATIONS,
	UPDATE_COMPONENT,
} from "./index";

import axios from "axios";
import { strUcFirst } from "../utils/Function";
import { ERROR } from "./userTypes";


const REACT_APP_GET_ALL_COMPONENTS="/graph/components";
const REACT_APP_GET_COMPONENT_INFO="/graph/components/#type#";
const REACT_APP_GET_PROPERTY_COMPONENT="/graph/components/property/#type#";
const REACT_APP_GET_COMPONENT_NAME="/graph/components/#type#/name";
const REACT_APP_GET_NODE_NAMES="/graph/components/names";
const REACT_APP_ADD_COMPONENT="/graph/components";
const REACT_APP_DELETE_COMPONENT="/graph/components/#componentType#/#componentId#";
const REACT_APP_UPDATE_COMPONENT="/graph/components/update";
const REACT_APP_SEE_COMPONENT_RELATIONS="/graph/components/listRelations/#componentId#";
const REACT_APP_GET_COMPONENT_RELATION_NUMBER="/graph/list/#componentType#/#componentName#";
const token = sessionStorage.getItem("cool-jwt");

export const getComponents = () => (dispatch) => {
	const url = REACT_APP_GET_ALL_COMPONENTS;
	axios
		.get(url, {
			headers: {
				Authorization: token
			}
		})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_COMPONENTS,
				payload: data,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const getNodeNames = () => (dispatch) => {
	const url = REACT_APP_GET_NODE_NAMES;
	axios
		.get(url, {
			headers: {
				Authorization: token
			}
		})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_NODE_NAMES,
				payload: data,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const getPropertyListComponent = (componentType) => (dispatch) => {
	const url = REACT_APP_GET_PROPERTY_COMPONENT;
	axios
		.get(url.replace("#type#", encodeURIComponent(componentType)))
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_PROPERTY_LIST_COMPONENT,
				payload: data,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const getComponentInfo = (type) => (dispatch) => {
	const url = REACT_APP_GET_COMPONENT_INFO;
	axios
		.get(url.replace("#type#", encodeURIComponent(type)))
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_COMPONENT_INFO,
				payload: data,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const getComponentRelationNumber =
	(componentType, componentName) => (dispatch) => {
	const url = REACT_APP_GET_COMPONENT_RELATION_NUMBER;
		axios
			.get(
				url
					.replace("#componentType#", encodeURIComponent(componentType))
					.replace("#componentName#", encodeURIComponent(componentName)),
			)
			.then((res) => res.data)
			.then((data) => {
				dispatch({
					type: GET_COMPONENT_RELATION_NUMBER,
					payload: data,
				});
			})
			.catch(function (err) {
				console.log("Request failed", err);
			});
	};

export const getComponentOriginNames = (componentType) => (dispatch) => {
	const url = REACT_APP_GET_COMPONENT_NAME;
	axios
		.get(url.replace("#type#", encodeURIComponent(componentType)))
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_COMPONENT_ORIGIN_NAME,
				payload: data,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const getComponentTargetNames = (componentType) => (dispatch) => {
	const url = REACT_APP_GET_COMPONENT_NAME;
	axios
		.get(url.replace("#type#", encodeURIComponent(componentType)))
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_COMPONENT_TARGET_NAME,
				payload: data,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const deleteComponent = (componentType, componentId) => (dispatch) => {
	const url = REACT_APP_DELETE_COMPONENT;
	axios
		.delete(
			url
				.replace("#componentType#", encodeURIComponent(componentType))
				.replace("#componentId#", encodeURIComponent(componentId)), {
					headers: {
						Authorization: token
					}
				}
		)
		.then((response) => {
			dispatch({
				type: DELETE_COMPONENT,
				payload: { componentId, componentType },
			})
		}
		)
		.catch((err) => {
			console.log("Delete failed", err.response.data);
			dispatch({type: ERROR, payload: err.response.data});
		});
};

export const addComponent = (newComponent, inputFields, update) => (dispatch) => {
	const url = REACT_APP_ADD_COMPONENT;
	var componentBody = {
		...newComponent
	};

	inputFields.map((inputField) => {
		return Object.defineProperty(
			componentBody,
			strUcFirst(inputField.property),
			{
				value: inputField.content,
				writable: true,
				enumerable: true,
				configurable: true,
			},
		);
	});

	var payloadObj = {
		id: 0,
		Nom: newComponent.name,
	};

	inputFields.map((inputField) => {
		return Object.defineProperty(payloadObj, strUcFirst(inputField.property), {
			value: inputField.content,
			writable: true,
			enumerable: true,
			configurable: true,
		});
	});

	axios
		.put(url, componentBody, {
			headers: {
				Authorization: token
			}
		})
		.then((data) => {
			dispatch({ type: ADD_COMPONENT, payload: data.data })
			dispatch({ type: GET_MAP_NODE, payload: [data.data] })
		})
		.catch((err) => {
			console.log("Add failed", err.response.data);
			dispatch({type: ERROR, payload: err.response.data});
		});
};

export const seeRelations = (componentId) => (dispatch) => {
	const url = REACT_APP_SEE_COMPONENT_RELATIONS;

	axios
		.get(url.replace("#componentId#", encodeURIComponent(componentId)))
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: SEE_RELATIONS,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Add failed", err);
		});
};

export const updateComponent = (componentToUpdate) => (dispatch) => {
	const url = REACT_APP_UPDATE_COMPONENT;

	let newObj = {};
	let payloadObj = {};

	const objUpdated = Object.entries(componentToUpdate);

	objUpdated.map((inputField) => {
		return Object.defineProperty(newObj, inputField[0], {
			value: inputField[1],
			writable: true,
			enumerable: true,
			configurable: true,
		});
	});
	// ===============================================================================

	Object.defineProperties(payloadObj, {
		Nom: {
			value: componentToUpdate.name,
		},
	});

	objUpdated.map((inputField) => {
		return Object.defineProperty(payloadObj, inputField[0], {
			value: inputField[1],
			writable: true,
			enumerable: true,
			configurable: true,
		});
	});

	// ===============================================================================

	axios
		.put(url, newObj, {
			headers: {
				Authorization: token
			}
		})
		.then((response) => {
			dispatch({ type: UPDATE_COMPONENT, payload: payloadObj })
		})
		.catch((err) => {
			console.log("Add failed", err.response.data);
			dispatch({type: ERROR, payload: err.response.data});
		});
};
