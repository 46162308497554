import { combineReducers } from "redux";

import { componentReducer } from "./componentReducer";
import { relationReducer } from "./relationReducer";
import { dataReducer } from "./dataReducer";
import { routingReducer } from "./routingReducer";
import { mapReducer } from "./mapReducer";
import { settingsReducer, propertiesReducer } from "./settingsReducer";
import { uploadReducer } from "./uploadReducer";
import { userReducer } from "./userReducer";

const rootReducer = combineReducers({
	componentReducer,
	relationReducer,
	dataReducer,
	routingReducer,
	mapReducer,
	settingsReducer,
	propertiesReducer,
	uploadReducer,
	userReducer,
});

export default rootReducer;
