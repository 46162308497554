import React, { useState } from "react";
import { ReactComponent as Bin } from "../../../../assets/img/trash-alt-solid.svg"
import { ReactComponent as Pencil } from "../../../../assets/img/pencil-solid.svg"
import { useMemo } from "react";
import { useTable } from "react-table";
import { StylesComponent } from "./RoleSettingsStyle";
import { PopupUpdateRole } from "../PopupUpdate/PopupUpdateRole";
import { componentColor } from "../../../../utils/color";

const RoleSettings = (props) => {
	const [showRoleUpdatePopup, setShowRoleUpdatePopup] = useState(false);
	const [role, setRole] = useState({
        name: '',
        rules: {
            nodes: {
                cantView: [],
                cantEdit: []
            },
            links: {
                cantView: [],
                cantEdit: []
            }
        }
    })

	const handleDelete = (row) => {
		if (row.name !== 'default' && row.name !== 'admin') {
			props.deleteRole(row);
		}
	}

	const handleUpdate = (row) => {
		if (row.name !== 'admin') {
			setRole(row);
			setShowRoleUpdatePopup(true);
		}
	}

	const canEdit = (row) => {
		if (row.name === 'admin')
			return 'not-allowed';
		return 'pointer';
	}

	const canDelete = (row) => {
		if (row.name === 'default' || row.name === 'admin')
			return 'not-allowed';
		return 'pointer';
	}

	// eslint-disable-next-line
	const columns = useMemo(
		() => [
			{
				id: "delete",
				Header: "",
				disableSortBy: true,
				accessor: "delete",
				Cell: (row) => (
					<Bin style={{width: '15px', cursor: canDelete(row.row.original)}} onClick={() => handleDelete(row.row.original)}></Bin>
				),
				sticky: "left",
			},
			{
				id: "update",
				Header: "",
				disableSortBy: true,
				accessor: "update",
				Cell: (row) => (
					<Pencil style={{width: '15px', cursor: canEdit(row.row.original), fill: componentColor}} onClick={() => handleUpdate(row.row.original)}></Pencil>
				),
				width: 30,
				sticky: "left",
			},
		  {
			Header: 'Role',
			accessor: 'name'
		  },
		  {
			Header: 'Composants',
			columns: [
				{
					Header: 'Ne peut voir',
					accessor: 'rules.nodes.cantView'
				},
				{
					Header: 'Ne peut modifier',
					accessor: 'rules.nodes.cantEdit'
				}
			]
		  },
		  {
			Header: 'Relations',
			columns: [
				{
					Header: 'Ne peut voir',
					accessor: 'rules.links.cantView'
				},
				{
					Header: 'Ne peut modifier',
					accessor: 'rules.links.cantEdit'
				}
			]
		  },
		],
		[]
	  )
	  
	const data = useMemo(() => {
		const value = props.roleList ? props.roleList : [];
			value.forEach(element => {
				element.links = JSON.stringify(element.rules.links);
				element.nodes = JSON.stringify(element.rules.nodes);
			});
		return (value);
	}, [props.roleList]);
	const tableInstance = useTable({
		columns,
		data
	})
	
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	  } = tableInstance;

	return (
		<>
		{showRoleUpdatePopup ?
			<PopupUpdateRole 
				role={role} 
				setShow={setShowRoleUpdatePopup}
				updateRole={props.updateRole}
				roleList={props.roleList}
				nodes={props.nodes}
				links={props.links}>

				</PopupUpdateRole> : null}
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
				<StylesComponent>
					<table {...getTableProps()}>
						<thead>
							{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
								))}
							</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map((row, i) => {
							prepareRow(row)
							return (
								<tr {...row.getRowProps()}>
								{row.cells.map(cell => {
									if (cell.column.parent
									&& (cell.column.parent.Header === 'Composants'
									|| cell.column.parent.Header === 'Relations')) {
										if (cell.value && cell.value.length === 0) {
											return <td {...cell.getCellProps()}>-</td>
										}
									}
									return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
								})}
								</tr>
							)
							})}
						</tbody>
					</table>
				</StylesComponent>
			</div>
		</>
	);
}

export default RoleSettings;
