import { CHANGE_PAGE } from "../action";

const iniState = {
	// caseId: 0,
	page: 0,
};

export const routingReducer = (state = iniState, action) => {
	switch (action.type) {
		// case CHANGE_MENU:
		// 	return { ...state, caseId: action.payload };

		case CHANGE_PAGE:
			return { ...state, page: action.payload };

		default:
			return state;
	}
};
