import React, { Component } from "react";
import "../../css/graphtest2.css";
import * as d3 from "d3";
import { ReactComponent as UploadLogo } from '../../assets/img/upload-solid.svg';
import { ReactComponent as DownloadLogo } from '../../assets/img/download-solid.svg';
import { ReactComponent as ResetLogo } from '../../assets/img/eraser-solid.svg';
import { ReactComponent as ExportLogo } from '../../assets/img/file-export-solid.svg';
import { ReactComponent as PlusLogo } from '../../assets/img/plus-solid.svg';
import { ReactComponent as ExpandLogo } from '../../assets/img/expand-svgrepo-com.svg';
import { ReactComponent as EyeSlash } from '../../assets/img/eye-slash-solid.svg';
import { ReactComponent as Link } from '../../assets/img/link-solid.svg';
import { ReactComponent as Lock } from '../../assets/img/lock-open-solid.svg';
import { ReactComponent as CSV } from '../../assets/img/file-csv-solid.svg';
import { ReactComponent as PNG } from '../../assets/img/file-image-solid.svg';
import { ReactComponent as SVG } from '../../assets/img/svg-svgrepo-com.svg';
import ContextMenu from "./ContextMenu";
import { PopupCreate, PopupDelete, PopupUpdate } from "..";

import { colors } from "../../utils/color";
import _ from "lodash";
import GraphSettings from "./GraphSettings/GraphSettings";
import { tick } from "./GraphFunctions/d3js/tick";
import { initZoom } from "./GraphFunctions/d3js/zoom";
import { hideFilter } from "./GraphFunctions/filter/filter";
import { downloadCSV2 } from "./GraphFunctions/export/exportCSV";
import { downloadJPEG } from "./GraphFunctions/export/exportJPEG";
import { createSVG, downloadSVG } from "./GraphFunctions/export/exportSVG";
import { updateNodes } from "./GraphFunctions/d3js/nodes/updateNodes";
import { updateLinks } from "./GraphFunctions/d3js/links/updateLinks";
import { updateMarkers } from "./GraphFunctions/d3js/markers/updateMarkers";
import { updateIcons } from "./GraphFunctions/d3js/icons/updateIcons";
import { updateNodePossibility } from "./GraphFunctions/d3js/nodes/updateNodePossibility";
import { updateLinkPossibility } from "./GraphFunctions/d3js/links/updateLinkPossibility";
import { formatData } from "./GraphFunctions/formatData";
import { getInitialGraphState } from "./GraphFunctions/initialGraphState";
import { LoadCartosPopup } from "./GraphCartos/Load/LoadCartosPopup";
import { SaveCartoPopup } from "./GraphCartos/Save/SaveCartoPopup";

function getFromLocalStorage(keys) {
	//localStorage.clear(); // si probleme avec local storage il peut etre utile de le clear une fois puis de recommenter la ligne
	let data = localStorage.getItem(keys);
	if (data) {
		try {
			data = JSON.parse(data);
		}
		catch {
			return null;
		}
		return data;
	}
		
	return null;
}

function setInLocalStorage(keys, data) {
	localStorage.setItem(keys, JSON.stringify(data));

}

class GraphTest2 extends Component {
	constructor(props) {
		super(props);
		this.state = 				getInitialGraphState();
		this.componentCleanup =		this.componentCleanup.bind(this);
		this.handleContextMenu = 	this.handleContextMenu.bind(this);
		this.handleClick =			this.handleClick.bind(this);

		this.setShowExport = 		this.setShowExport.bind(this);
		this.setShowRel	=			this.setShowRel.bind(this);
		this.downloadCSV = 			this.downloadCSV.bind(this);

		this.setSelectedType = 		this.setSelectedType.bind(this);
		this.setFilterVisibility = 	this.setFilterVisibility.bind(this);
		this.setSettingsVisibility = this.setSettingsVisibility.bind(this);
		this.updateNodeSettings	=	this.updateNodeSettings.bind(this);
		this.updateLinkSettings = 	this.updateLinkSettings.bind(this);
		this.updateNode = 			this.updateNode.bind(this);

		this.configBtn = 			this.configBtn.bind(this);
		this.saveBtn=				this.saveBtn.bind(this);
		this.deleteNode = 			this.deleteNode.bind(this);
		this.deleteLink = 			this.deleteLink.bind(this);
		this.dataBtn = 				this.dataBtn.bind(this);
		this.typeBtn = 				this.typeBtn.bind(this);

		this.closeModal = 			this.closeModal.bind(this);
		this.closeDataModal = 		this.closeDataModal.bind(this);

		this.localStorageUpdated = 	this.localStorageUpdated.bind(this);

		this.deployAll = 			this.deployAll.bind(this);
		this.releaseNodes = 		this.releaseNodes.bind(this);
		this.loadGraph = 			this.loadGraph.bind(this);
		this.setGraphReload =		this.setGraphReload.bind(this);
		this.setReloadFilter = 		this.setReloadFilter.bind(this);
		this.addSelectedElement = 	this.addSelectedElement.bind(this);
		this.removeSelectedElement =this.removeSelectedElement.bind(this);
		this.setPopupLoadCartos =	this.setPopupLoadCartos.bind(this);
		this.setPopupSaveCarto =	this.setPopupSaveCarto.bind(this);
		this.saveLastAction =		this.saveLastAction.bind(this);
		this.loadLastAction = 		this.loadLastAction.bind(this);
		this.setLastCartoLoaded = 	this.setLastCartoLoaded.bind(this);
		this.updateCarto = 			this.updateCarto.bind(this);
	}

	setReloadFilter() {
		this.setState({reloadFilter: !this.state.reloadFilter});
	}

    loadGraph (graph) {

		_.each(graph.nodes, node => {
			node.fx = node.x;
			node.fy = node.y;
		});

		_.each(graph.links, link => {
			if (typeof link.source === 'object') {
				link.source = link.source.id;
			}
			if (typeof link.target === 'object') {
				link.target = link.target.id;
			}
		})

		d3.selectAll("marker").remove();

		this.setState({	nodes2: graph.nodes,
			links2: graph.links,
			nodesTypes: graph.nodesTypes,
			linksTypes: graph.linksTypes,
			markers: [],
			filters: graph.filters,
			reloadFilter: true,
		});
		setTimeout(() => {
			this.links.selectAll("g").style("visibility", "visible");
			this.nodes.selectAll("g").style("visibility", "visible");
		}, 50)
    }

	setSelectedType(newType) {
		this.setState({
			selectedType: newType
		});
	}

	componentCleanup() {

		let links = [];
		if(this.simulation.force("link")) {
			links = _.cloneDeep(this.simulation.force("link").links());

			_.each(links, link => {
				link.source = link.source.id;
				link.target = link.target.id;
			});
		}
		const autoSavedGraph = {
			nodes: this.simulation.nodes(),
			links: links, image: createSVG(), 
			nodesTypes: this.state.nodesTypes, 
			linksTypes: this.state.linksTypes,
			filters: this.state.filters,
		};
		setInLocalStorage("autosavedGraph", autoSavedGraph);

		document.removeEventListener("click", this.handleClick);

		window.removeEventListener('beforeunload', this.componentCleanup);
		this.svg.remove();
		setInLocalStorage('lastActions', []);
	}

	handleContextMenu(event) {
		let showDataBtn = false,
		nodeData = {},
		linkData = {},
		props = [],
		data = {},
		type = '';

		event.preventDefault()

		let pos = d3.pointer(event)
		if(event.target.parentNode.parentNode.classList.contains('node') || event.target.parentNode.parentNode.classList.contains('wrap')) {
			data = event.target.parentNode.parentNode.__data__
			props = Object.keys(data).filter(
				(prop) => 
					prop !== 'name' &&
					prop !== 'description' &&
					prop !== 'index' &&
					prop !== 'y' &&
					prop !== 'x' &&
					prop !== 'fx' &&
					prop !== 'fy' &&
					prop !== 'vy' &&
					prop !== 'vx' &&
					prop !== 'cx' &&
					prop !== 'cy',
				);
			props.forEach((element) => {
				nodeData[element] = data[element];
			})
			this.props.getComponentRelationNumber(nodeData.type, nodeData.Nom);
			showDataBtn = true;
			type = 'node';
		} else if (event.target.parentNode.classList.contains('link-text')) {
			data = event.target.parentNode.__data__
			linkData.origin = data.source.name;
			linkData.target = data.target.name;
			linkData.rowOrigin = data.source.name;
			linkData.rowTarget = data.target.name;
			linkData.idOrigin = data.source.id;
			linkData.idTarget = data.target.id;
			props = Object.keys(data).filter(
				(prop) => 
					prop !== 'index' &&
					prop !== 'source' &&
					prop !== 'target' &&
					prop !== 'data' &&
					prop !== 'sameIndex' &&
					prop !== 'sameTotal' &&
					prop !== 'sameLowerHalf' &&
					prop !== 'sameMiddleLink' &&
					prop !== 'sameUneven' &&
					prop !== 'sameTotalHalf' &&
					prop !== 'sameArcDirection' &&
					prop !== 'sameIndexCorrected' &&
					prop !== 'maxSameHalf' &&
					prop !== 'from',
				);
			props.forEach((element) => {
				linkData[element] = data[element];
			})
			type = 'link';
		} else if (event.target.classList.contains('expand')) {
			pos[0] = event.pageX;
			pos[1] = event.pageY;
			this.props.getRelationTypes(this.state.nodes2.map(node => node.id));
			type = 'deploy';
		} else {
			type = 'node';
		}
		this.setState({contextMenuShow: true,
			anchorPoint: {x: pos[0], y: pos[1]},
			contextMenuShowData: false,
			contextMenuShowExport: false,
			contextMenuShowRel: false,
			contextMenuType: type,
			contextMenuShowDataBtn: showDataBtn,
			contextMenuNodeData: nodeData,
			contextMenuLinkData: linkData,
		});
	}

	handleClick(event) {
		const pathBtns = d3.selectAll("g.path-btn")
		if(!event.target.classList.contains('context__btn--panel-btn') &&
			!event.target.classList.contains('path-btn__slice') &&
			!event.target.classList.contains('link-text-path') &&
			!event.target.classList.contains('expand') &&
			!event.target.classList.contains('button-btn_slice')) { // if no click on context menu btton
			if(this.state.contextMenuShow)
				this.setState({contextMenuShow: false})

			if(pathBtns && event.target.parentNode && (event.target.parentNode.classList && 
				!(event.target.parentNode.classList.contains("node") || event.target.parentNode.classList.contains("wrap") || event.target.parentNode.classList.contains("path-btn__wrapper")))) {
				// if there is a pathbtn 
				//  and target is a not node or a pathbtn
						d3.selectAll("g.pie-open").classed("pie-open", false); 
						d3.selectAll("circle.selected").classed("selected", false); // unselect all node
						pathBtns.remove(); // close all path btn
			}
		}
	}

	componentDidMount() {
		
		let
		nodeConfigLocalStorage = getFromLocalStorage("nodeConfig"),
		linkConfigLocalStorage = getFromLocalStorage("linkConfig");
		setInLocalStorage('lastActions', []);

		this.colorScheme10 = colors;
		if (nodeConfigLocalStorage) {
			this.nodeConfigLocalStorage = nodeConfigLocalStorage;
		}
		else {
			this.nodeConfigLocalStorage = {};
		}

		if(linkConfigLocalStorage) {
			this.linkConfigLocalStorage = linkConfigLocalStorage;
		}
		else {
			this.linkConfigLocalStorage = {};
		}

		window.addEventListener('beforeunload', this.componentCleanup);
		
		document.addEventListener("click", this.handleClick);
		document.addEventListener("keydown", (e) => {
			if (e.ctrlKey && e.key === 'z')
				this.loadLastAction();
		})

		const autoSave = getFromLocalStorage("autosavedGraph");
		if (autoSave) {
			this.loadGraph(autoSave);
		}

		this.setState(({
			savedCartos: this.props.savedCartos
		}))

		this.drawGraph();
		this.saveLastAction();
	}

	loadSavedData(data) {
		const nodes = data.nodes, 
		links = data.links;

		return [nodes, links];
	}


	componentDidUpdate(prevProps, prevState) {
		if (this.props.relationsNode !== prevProps.relationsNode
			|| this.props.dataFromInput !== prevProps.dataFromInput
			|| this.props.lastRelations !== prevProps.lastRelations) {
			const { 
				newNodes,
				newLinks,
				newNodesTypes,
				newLinksTypes
			} = formatData(this.state.nodes2, this.state.links2, this.state.nodesTypes, this.state.linksTypes, this.props.dataFromInput, this.props.relationsNode, this.props.lastRelations);
			this.setState({
				nodes2: newNodes,
				links2: newLinks,
				nodesTypes: newNodesTypes,
				linksTypes: newLinksTypes
			})
			this.props.setCartoNodes(newNodes);
		}

		if (this.props.suggestionSelect) {
			this.props.setSuggestionSelect();
			let selectedId = this.state.selectedElements;
			d3.selectAll("g.node")
				.filter((d) => d.id === this.props.suggestionSelect.id)
				.each(function (d) {
					d3.select(this).classed("selected", true);
					selectedId.add(d)
				});
				this.setState({selectedElements: selectedId})
		}

		if (!_.isEqual(this.state.nodes2, prevState.nodes2) || !_.isEqual(this.state.links2, prevState.links2)) {
			updateNodes(this.state.nodes2, this.nodes, this.props.settings, this.simulation, this.handleContextMenu, this.addSelectedElement, this.removeSelectedElement, this.deleteNode, this.deployAll);
			updateLinks(this.state.links2, this.links, this.props.settings, this.handleContextMenu);
			updateMarkers(this.state.links2, this.markers, this.props.settings);
			this.simulation.nodes(this.state.nodes2);
			this.simulation.force("link", d3.forceLink(this.state.links2).id((d) => {return d.id;}).distance(200));
				
			this.setState({nodePossibility: updateNodePossibility(this.state.nodesTypes, this.state.nodes2)});
			this.setState({linkPossibility: updateLinkPossibility(this.state.linksTypes, this.state.links2)});
			this.simulation.alpha(1).restart();
			if (this.state.ctrlZ) {
				this.setState(({
					ctrlZ: false
				}));
			} else {
				this.saveLastAction();
			}
		}

		if (this.state.updatedNodeConfig) {
			updateNodes(this.state.nodes2, this.nodes, this.props.settings, this.simulation, this.handleContextMenu, this.addSelectedElement, this.removeSelectedElement, this.deleteNode, this.deployAll);
			this.setState({updatedNodeConfig: false});
		}

		if (this.state.updatedLinkConfig) {
			updateLinks(this.state.links2, this.links, this.props.settings, this.handleContextMenu);
			updateMarkers(this.state.links2, this.markers, this.props.settings);
			this.setState({updatedLinkConfig: false});
		}
	}

	saveLastAction() {
		const actions = getFromLocalStorage('lastActions');
		let links;
		if (this.simulation.force("link")) {
			links = _.cloneDeep(this.simulation.force("link").links());
			
			_.each(links, link => {
				link.source = link.source.id;
				link.target = link.target.id;
			});
		}
		const graph = {
			nodes: this.simulation.nodes(),
			nodes2: this.state.nodes2,
			links: links,
			nodesTypes: this.state.nodesTypes, 
			linksTypes: this.state.linksTypes,
			filters: this.state.filters,
			nodesExpanded: this.state.nodesExpanded,
		}
		let newActions = graph;
		if (actions.length < 10)
			actions.push(newActions);
		else if (actions.length > 10) {
			actions.push(newActions);
			actions.shift();
		}
		setInLocalStorage('lastActions', actions);
	}

	loadLastAction() {
		const lastActions = getFromLocalStorage('lastActions');
		if (lastActions.length > 1) {
			lastActions.pop();
			this.props.resetGraph();
			this.setState(({
				ctrlZ: true,
				nodesExpanded: lastActions[lastActions.length - 1].nodesExpanded
			}));
			this.loadGraph(lastActions[lastActions.length - 1]);
			this.props.setCartoNodes(lastActions[lastActions.length - 1].nodes2);
			this.releaseNodes();
			setInLocalStorage('lastActions', lastActions);
		}
	}

	componentWillUnmount() {
		this.componentCleanup();
        window.removeEventListener('beforeunload', this.componentCleanup);
	}

	truncText(text, textSize) {
		if(text && text.length > textSize) 
			return text.substring(0, textSize)+'...';
		else
			return text;
	}

	localStorageUpdated(e) {
		console.log(e)
	}

	configBtn() {
		this.setState((state) => ({ showingModal: !state.showingModal }));
	}

	setGraphReload() {
		this.setState((state) => ({ reloadGraphs: !state.reloadGraphs }));
	}

	saveBtn(name) {
		let links;
		if(this.simulation.force("link")) {
			links = _.cloneDeep(this.simulation.force("link").links());
			
			_.each(links, link => {
				link.source = link.source.id;
				link.target = link.target.id;
			});
		}
		const graph = {
			name: name,
			date: new Date().toLocaleString('fr-FR', { timeZone: 'UTC' }),
			nodes: this.simulation.nodes(),
			links: links,
			image: createSVG(), 
			nodesTypes: this.state.nodesTypes, 
			linksTypes: this.state.linksTypes,
			filters: this.state.filters,
		}
		hideFilter(this.state.filters, this.nodes, this.links, this.state.links2)
		this.props.mapSaveCarto(sessionStorage.getItem("cool-jwt"), graph);
		console.log('Complete graph: ' + (JSON.stringify(graph).length / 1000));
	}

	updateCarto(carto) {
		let links;
		if(this.simulation.force("link")) {
			links = _.cloneDeep(this.simulation.force("link").links());
			
			_.each(links, link => {
				link.source = link.source.id;
				link.target = link.target.id;
			});
		}
		const graph = {
			name: carto.name,
			date: new Date().toLocaleString('fr-FR', { timeZone: 'UTC' }),
			nodes: this.simulation.nodes(),
			links: links,
			image: createSVG(), 
			nodesTypes: this.state.nodesTypes, 
			linksTypes: this.state.linksTypes,
			filters: this.state.filters,
		}
		carto.data = graph;
		hideFilter(this.state.filters, this.nodes, this.links, this.state.links2)
		this.props.mapUpdateCarto(carto, sessionStorage.getItem("cool-jwt"));
		console.log('Complete graph: ' + (JSON.stringify(graph).length / 1000));
	}

	typeBtn(event, type, typesClass) {
		if(!this.state.typeToConfig !== type) {
			if (typesClass === "nodes")
				this.setState((state) => ({	typeToConfig: 		type,
										showingNodeTypeModal: 	true,
										showingLinkTypeModal: false}));
			else if (typesClass === "links")
				this.setState((state) => ({	typeToConfig: 		type,
										showingLinkTypeModal: 	true,
										showingNodeTypeModal: false}));
		} else 
			this.setState((state) => ({	typeToConfig: 		"",
				showingLinkTypeModal: 	false,
				showingNodeTypeModal: false}));
	}


	deleteNode(nodeId, event) {
		const newArrayNodes = this.state.nodes2.filter(
			(dataNode) => !nodeId.includes(dataNode.id));

		const newArrayLinks = this.state.links2.filter(
			(datalinks) =>
				!nodeId.includes(datalinks.source.id) &&
				!nodeId.includes(datalinks.target.id),
		);
		
		const newSelectedElement = new Set(this.state.selectedElements);
		const newNodesExpanded = this.state.nodesExpanded.filter(el => !nodeId.includes(el));

		nodeId.forEach((element) => {
			newSelectedElement.delete(element);
		})

		this.setState({
			nodes2: newArrayNodes,
			links2: newArrayLinks,
			selectedElements: newSelectedElement,
			nodesExpanded: newNodesExpanded,
			nodesDeleted: [...new Set(this.state.nodesDeleted.concat(nodeId))]
		});
		this.props.setCartoNodes(newArrayNodes);
	}

	
	deleteLink(linkId, event) {
		const newArrayLinks = this.state.links2.filter(
			(datalinks) =>
				datalinks.id !== linkId,
		);
		
		this.setState({
			links2: newArrayLinks
		});
	}

	closeModal() {
		this.setState({ showingModal: false });
	}

	closeDataModal() {
		this.setState({ showingDataModal: false });
	}

	drawGraph() {
		this.svg = d3
			.select("div.graph")
			.append("svg")
			.classed("full-size", true)
			.attr("width", this.props.width)
			.attr("height", this.props.height);

		this.gZoom = this.svg.append("g")
						.classed("gZoom", true);


		// function colorGroup(group, colorToUse) {
		// 	this.nodes.filter((d) => d.type === group).attr("fill", colorToUse);
		// }

		this.simulation = d3.forceSimulation()
			.force("x", d3.forceX(this.props.width / 2))
			.force("y", d3.forceY(this.props.height / 2))
			.force("charge", d3.forceManyBody().strength(-3000))
			.force("link", d3.forceLink().distance(200));

		this.markers = this.gZoom
			.append("defs")
			.classed("markers", true);
		
		updateMarkers(this.state.links2, this.markers, this.props.settings);
		
		this.icons = this.gZoom
			.append("defs")
			.classed("icons", true);
		updateIcons(this.icons)
		

		this.selectionModal = d3.select("div.selectionModal");
// est-ce que la section suivante est utile ou peut on la remplacer par du CSS uniquement ? (Olivier 03/11/2021)
		this.links = this.gZoom
			.append("g")
			.attr("stroke", "#999")
			.attr("stroke-opacity", 0.6)
			.attr("fill", "none")
			.classed("links", true);
// fin de la section qui me semble inutile (Olivier 03/11/2021)

		updateLinks(this.state.links2, this.links, this.props.settings);

		this.nodes = this.gZoom.append("g")
			.classed("nodes", true);

		updateNodes(this.state.nodes2, this.nodes, this.props.settings, this.simulation, this.handleContextMenu, this.addSelectedElement, this.removeSelectedElement, this.deleteNode, this.deployAll);

		this.simulation.on("tick", () => tick(this.links, this.nodes, this.props.settings));
		
		this.svg.on("mousedown", (event) => {
			if (!event.shiftKey && event.which !== 3) {
						//if no ctrlkey unselect previous selection and destroy roundmenu
						d3.selectAll("g.selected").classed("selected", false);
						this.setState({ selectedElements: new Set() });
						// if(this.pathBtns && event.target.nodeName === "svg")
						// 	this.pathBtns.remove()
					}
		})
		this.zoom = initZoom(this.props.width, this.props.height, this.nodes, this.links, this.gZoom)
		this.svg.call(this.zoom);
	}

	releaseNodes() {
		this.nodes.selectAll("g")
			.each((d) => {
				d.fx = null;
				d.fy = null;
			})
	}

		setFilterVisibility() {
			this.props.setOpenFilter(!this.state.showFilter);
			this.setState({showFilter: !this.state.showFilter});
		}

		downloadCSV() {
			downloadCSV2(this.state.nodes2, this.state.links2);
		}

		setShowExport() {
			this.setState((state) => ({contextMenuShowExport: !this.state.contextMenuShowExport}));
		}

		setSettingsVisibility() {
			this.setState((state) => ({showSettings: !this.state.showSettings}));
		}

		updateNodeSettings() {
			this.setState((state) => ({updatedNodeConfig: true}));
		}

		updateLinkSettings() {
			this.setState((state) => ({updatedLinkConfig: true}));
		}

		setShowRel(node) {
			this.props.getRelationTypes([node.id]);
			this.setState((state) => ({
				contextMenuShowRel: !this.state.contextMenuShowRel,
				contextMenuShowData: false
			}));
		}

		dataBtn() {
			this.setState((state) => ({
				contextMenuShowData: !state.contextMenuShowData,
				contextMenuShowRel: false
			}));
		}

		deployAll(nodeIds) {
			const newNodesExpanded = [...new Set(this.state.nodesExpanded.concat(nodeIds))]
			this.setState(({
				nodesExpanded: newNodesExpanded
			}));
			this.props.mapNodeRelation(newNodesExpanded, this.state.nodesDeleted);
		}
		addSelectedElement(element) {
			this.setState((prevState) => {
				return {selectedElements: prevState.selectedElements.add(element)}
			})
		}

		removeSelectedElement(element) {
			this.setState((prevState) => {
				const selected = this.state.selectedElements;
				selected.delete(element)
				return {selectedElements: selected};
			})
		}

		updateNode(node) {
			const oldnode = this.state.nodes2.find(el => el.name === node.name);
			const newkeys = Object.keys(node);
			newkeys.forEach(key => {
				if (!oldnode[key] || oldnode[key] !== node[key]) {
					oldnode[key] = node[key];
				}
			})
		}

		setPopupLoadCartos() {
			this.setState(({
				popupLoadCartos: !this.state.popupLoadCartos,
			}))
		}

		setLastCartoLoaded(carto) {
			this.setState(({
				lastCartoLoaded: carto,
			}))
		}

		setPopupSaveCarto() {
			this.setState(({
				popupSaveCarto: !this.state.popupSaveCarto,
			}))
		}

	render() {
		return (
			<div id="context" className="graph-wrapper">
				{this.state.contextMenuShow ? (
					<ContextMenu
						anchorPoint={this.state.anchorPoint}
						saveBtn={this.saveBtn}
						setPopupCreate={this.props.setPopupCreate}
						setPopupDelete={this.props.setPopupDelete}
						setPopupUpdate={this.props.setPopupUpdate}
						removeNode={this.deleteNode}
						removeLink={this.deleteLink}
						configBtn={this.configBtn}
						dataBtn={this.dataBtn}
						showDataBtn={this.state.contextMenuShowDataBtn}
						showData={this.state.contextMenuShowData}
						showExport={this.state.contextMenuShowExport}
						showRel={this.state.contextMenuShowRel}
						setShowExport={this.setShowExport}
						setShowRel={this.setShowRel}
						nodeData={this.state.contextMenuNodeData}
						linkData={this.state.contextMenuLinkData}
						setType={this.setSelectedType}
						type={this.state.contextMenuType}
						selectForLink={this.state.selectForLink}
						selectedElements={Array.from(this.state.selectedElements)}
						downloadCSV={this.downloadCSV}
						deployAll={this.deployAll}
						relationsTypes={this.props.relationTypes}
						mapNodeRelationsByType={this.props.mapNodeRelationsByType}
						nodes={this.state.nodes2}
						links={this.state.links2}
						releaseNodes={this.releaseNodes}
						>
					</ContextMenu>
				) : null}
				<div className="graph__empty-create">
					{
						this.state.nodes2.length === 0 ? <PlusLogo className='graphBtn' style={{fill: 'lightblue', width: '40px', marginLeft: '5px', marginRight: '5px'}} onClick={() => {this.setSelectedType(true);this.props.setPopupCreate(true)}} title="Ajouter un composant"/> : 
						<>
							<PlusLogo className='graphBtn' style={{fill: 'grey', width: '40px', marginLeft: '5px', marginRight: '5px'}} onClick={() => {this.setSelectedType(true);this.props.setPopupCreate(true)}} title="Ajouter un composant"/>
							<ExpandLogo className='graphBtn expand' style={{fill: 'grey', width: '40px', marginLeft: '5px', marginRight: '5px'}} onClick={this.handleContextMenu} title="Tout Deployer"/>
							<Lock className='graphBtn' style={{fill: 'grey', width: '40px', marginLeft: '5px', marginRight: '5px'}} onClick={() => this.releaseNodes()} title="Tout Relacher"/>
							{
								this.state.selectedElements.size > 0 ?
								( this.state.selectedElements.size === 2 ? 
								<>
									<EyeSlash className="graphBtn" style={{fill: 'grey', width: '40px', marginLeft: '5px', marginRight: '5px'}} onClick={() => this.deleteNode(Array.from(this.state.selectedElements).map(el => el.id))} title="Supprimer les composant"/>
									<Link className="graphBtn" style={{fill: 'grey', width: '40px', marginLeft: '5px', marginRight: '5px'}} onClick={() => {this.setSelectedType(false);this.props.setPopupCreate(true)}} title="Ajouter une relation"/>
								</> :
									<EyeSlash className="graphBtn" style={{fill: 'grey', width: '40px', marginLeft: '5px', marginRight: '5px'}} onClick={() => this.deleteNode(Array.from(this.state.selectedElements).map(el => el.id))} title="Supprimer les composant"/>) :
								null
							}
						</>
					}
				</div>
				{this.state.nodes2.length === 0 ?
				<div className="graph__empty-text">
					<b>Aucun element a afficher.</b><br/>
					Demarrez votre cartographie en saisissant au moins deux caracteres dans la recherche<br/>
					ou creez un composant en cliquant sur <PlusLogo style={{fill: 'lightblue', width: '20px'}}/>
				</div> : null
				}
				<div className="graph__empty-saveCarto">
					{
						this.state.nodes2.length === 0 ? <UploadLogo className='graphBtn' style={{fill: 'grey', width: '40px'}} onClick={() => this.setPopupLoadCartos()} title="Charger une carto"/> : 
						<>
							<ResetLogo className='graphBtn' style={{fill: 'grey', width: '40px'}} onClick={() => {this.setState(getInitialGraphState()); this.props.resetGraph();}} title="Supprimer la carto"/>
							<UploadLogo className='graphBtn' style={{fill: 'grey', width: '40px'}} onClick={() => this.setPopupLoadCartos()} title="Charger une carto"/>
							<DownloadLogo className='graphBtn' style={{fill: 'grey', width: '40px'}} onClick={() => this.setPopupSaveCarto()} title="Sauvegarder la carto"/>
							<ExportLogo className='graphBtn' style={{fill: 'grey', height: '40px'}} onClick={() => this.setState(state => ({ export: true }))} title="Exporter la carto"/>
						</>
					}
				</div>
					{
						this.state.export ?
						<div className="graph__empty-exportCarto">
								<CSV className='graphBtn' style={{fill: 'grey', width: '40px'}} onClick={() => {this.downloadCSV(); this.setState(state => ({ export: false }))}} title="Exporter en csv"/>
								<PNG className='graphBtn' style={{fill: 'grey', width: '40px'}} onClick={() => {downloadJPEG(); this.setState(state => ({ export: false }))}} title="Exporter en jpeg"/>
								<SVG className='graphBtn' style={{fill: 'grey', width: '50px'}} onClick={() => {downloadSVG(); this.setState(state => ({ export: false }))}} title="Exporter en svg"/>
						</div> : null
					}
				<GraphSettings
					nodes2={this.state.nodes2}
					links2={this.state.links2}
					nodes={this.nodes}
					links={this.links}
					markers={this.markers}
					nodesType={this.state.nodesTypes}
					linksType={this.state.linksTypes}
					nodePossibility={this.state.nodePossibility}
					linkPossibility={this.state.linkPossibility}
					show={this.state.showSettings}
					setVisibility={this.setSettingsVisibility}
					settings={this.props.settings}
					updateSettings={this.props.updateSettings}
					typeBtnClick={this.typeBtn}
					updateNode={this.updateNodeSettings}
					updateLink={this.updateLinkSettings}
					mapAllNodesByType={this.props.mapAllNodesByType}
					mapAllRelationsByType={this.props.mapAllRelationsByType}
					>
				</GraphSettings>
				<div id='graph' className='graph' onClick={() => this.setState((state) => ({export: false}))}/>
				{this.props.popupDelete && (
					<PopupDelete
						instance={!this.state.selectedType ? this.state.contextMenuLinkData : false}
						deleteRelation={this.props.deleteRelation}
						relationId={this.state.contextMenuLinkData.id}
						
						componentId={this.state.contextMenuNodeData.id}
						componentType={this.state.contextMenuNodeData.type}
						name={this.state.contextMenuNodeData.Nom}
						relationNumber={this.props.componentRelationNumber}
						deleteComponent={this.props.deleteComponent}
						componentRelationList={this.props.componentRelationList}
						seeRelations={this.props.seeRelations}
						setPopupDelete={this.props.setPopupDelete}
						removeLink={this.deleteLink}
						removeNode={this.deleteNode}
					/>
				)}
				{this.state.popupLoadCartos && (
					<LoadCartosPopup
						setPopup={this.setPopupLoadCartos}
						loadGraph={this.loadGraph}
						setLastCartoLoaded={this.setLastCartoLoaded}
						reloadGraph={this.setGraphReload}
						reload={this.state.reloadGraphs}
					/>
				)}
				{this.state.popupSaveCarto && (
					<SaveCartoPopup
						lastCartoLoaded={this.state.lastCartoLoaded}
						setPopup={this.setPopupSaveCarto}
						saveCarto={this.saveBtn}
						updateCarto={this.updateCarto}
					/>
				)}
				{this.props.popupCreate && (
					<PopupCreate
						setPopupCreate={this.props.setPopupCreate}
						addRelation={this.props.addRelation}
						relations={this.props.relations}
						components={this.props.components}
						info={this.props.Info}
						componentPage={this.state.selectedType}
						origin={this.state.selectedName}

						selected={Array.from(this.state.selectedElements)}
					/>
				)}
				{this.props.popupUpdate && (
					<PopupUpdate
						type = {this.state.selectedType ? this.state.contextMenuNodeData.type : this.state.contextMenuLinkData.type}
						infoUpdate={this.state.selectedType ? this.state.contextMenuNodeData : this.state.contextMenuLinkData}
						setPopupUpdate={this.props.setPopupUpdate}
						componentPage={this.state.selectedType}
						relationPage={!this.state.selectedType}
						nodeNames={this.props.nodeNames}
						updateNode={this.updateNode}
					/>
				)}
			</div>
		);
	}
}

export default GraphTest2;