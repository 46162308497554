import {
	GET_MAP_ALL_RELATIONS,
	GET_MAP_COMPONENT_RELATIONS,
	GET_MAP_NODE,
	GET_MAP_COMPONENT_RELATIONS_TYPES,
	GET_MAP_CARTO,
	SAVE_MAP_CARTO,
	UPDATE_MAP_CARTO,
	DELETE_MAP_CARTO
} from "./index";

import axios from "axios";


const REACT_APP_MAP_COMPONENT_RELATIONS="/graph/map/listRelations"
const REACT_APP_MAP_COMPONENT_RELATIONS_TYPES="/graph/map/listRelationsTypes"
const REACT_APP_MAP_COMPONENT_RELATIONS_BY_TYPE="/graph/map/listRelationsByType"
const REACT_APP_MAP_ALL_RELATIONS="/graph/map"
const REACT_APP_MAP_ALL_RELATIONS_BY_TYPE="/graph/map/listAllRelationByType"
const REACT_APP_MAP_ALL_NODES_BY_TYPE="/graph/map/listAllNodeByType"
const REACT_APP_MAP_NODE="/graph/map/nodes"
const REACT_APP_MAP_LOAD_CARTO="/graph/map/carto/#token#"
const REACT_APP_MAP_SAVE_CARTO="/graph/map/carto/#token#"
const REACT_APP_MAP_UPDATE_CARTO="/graph/map/carto/update/#token#"
const REACT_APP_MAP_DELETE_CARTO="/graph/map/carto/delete/#token#"
const token = sessionStorage.getItem("cool-jwt");

// ================================================================

export const mapAllRelations = () => (dispatch) => {
	const url = REACT_APP_MAP_ALL_RELATIONS;

	axios
		.get(url, {
			headers: {
				Authorization: token
			}
		})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_MAP_ALL_RELATIONS,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Add failed", err);
		});
};

// ================================================================

export const mapAllNodesByType = (type, nodes) => (dispatch) => {
	const url = REACT_APP_MAP_ALL_NODES_BY_TYPE;

	axios
		.put(url, {type: type, id: nodes}, {
			headers: {
				Authorization: token
			}
		})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_MAP_NODE,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Add failed", err);
		});
};

// ================================================================

export const mapAllRelationsByType = (type) => (dispatch) => {
	const url = REACT_APP_MAP_ALL_RELATIONS_BY_TYPE;

	axios
		.put(url, {type: type}, {
			headers: {
				Authorization: token
			}
		})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_MAP_COMPONENT_RELATIONS,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Add failed", err);
		});
};

// ================================================================

export const mapNodeRelations = (componentsId, deletedNodes) => (dispatch) => {
	const url = REACT_APP_MAP_COMPONENT_RELATIONS;

	axios
		.put(url, {componentsId: componentsId, deletedNodes}, {
			headers: {
				Authorization: token
			}
		})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_MAP_COMPONENT_RELATIONS,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Add failed", err);
		});
};

// ================================================================

export const mapNode = (nodesId) => (dispatch) => {
	const url = REACT_APP_MAP_NODE;

	axios
		.put(url, {nodesId: nodesId}, {
			headers: {
				Authorization: token
			}
		})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_MAP_NODE,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Add failed", err);
		});
};

// ================================================================

export const mapNodeRelationsTypes = (componentsId) => (dispatch) => {
	const url = REACT_APP_MAP_COMPONENT_RELATIONS_TYPES;

	axios
		.put(url, {componentsId: componentsId}, {
			headers: {
				Authorization: token
			}
		})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_MAP_COMPONENT_RELATIONS_TYPES,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Add failed", err);
		});
};

// ================================================================

export const mapNodeRelationsByType = (componentsId, relationType) => (dispatch) => {
	const url = REACT_APP_MAP_COMPONENT_RELATIONS_BY_TYPE;

	axios
		.put(url, {componentsId: componentsId, relationType: relationType}, {
			headers: {
				Authorization: token
			}
		})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_MAP_COMPONENT_RELATIONS,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Add failed", err);
		});
};

// ================================================================

export const mapLoadCarto = (token) => (dispatch) => {
	const url = REACT_APP_MAP_LOAD_CARTO;

	axios
		.get(url.replace("#token#", encodeURIComponent(token)), {
			headers: {
				Authorization: token
			}
		})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_MAP_CARTO,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Add failed", err);
		});
};

// ================================================================

export const mapSaveCarto = (token, carto) => (dispatch) => {
	const url = REACT_APP_MAP_SAVE_CARTO;

	axios
		.put(url.replace("#token#", encodeURIComponent(token)), {carto: carto}, {
			headers: {
				Authorization: token
			}
		})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: SAVE_MAP_CARTO,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Add failed", err);
		});
};

// ================================================================

export const mapUpdateCarto = (carto, token) => (dispatch) => {
	const url = REACT_APP_MAP_UPDATE_CARTO;

	axios
		.put(url.replace("#token#", encodeURIComponent(token)), {carto: carto}, {
			headers: {
				Authorization: token
			}
		})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: UPDATE_MAP_CARTO,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Add failed", err);
		});
};

// ================================================================

export const mapDeleteCarto = (token, carto) => (dispatch) => {
	const url = REACT_APP_MAP_DELETE_CARTO;

	axios
		.put(url.replace("#token#", encodeURIComponent(token)), {carto: carto}, {
			headers: {
				Authorization: token
			}
		})
		.then((res) => {
			dispatch({
				type: DELETE_MAP_CARTO,
				payload: carto
			})
		})
		.catch((err) => {
			console.log("DELETE failed", err);
		});
};