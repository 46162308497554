import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { uploadCsv } from "../../action/uploadAction";
import { readString } from 'react-papaparse';

import "./PopupImport.css";
import "../Form/Form.css";
import { ValidateFields } from "./ValidateFields/ValidateFields";
import ButtonStyle from "../../styled-components/ButtonStyle";
import { uploadColor, cancelColor, componentColor, relationColor } from '../../utils/color';
import { StylesComponent } from './PopupImportStyle';
import { capitalizeString } from "../../utils/Function";

function PopupImportComponent(props) {
    const [fields, setFields] = useState([]);
	const [types, setTypes] = useState();

    const columns = useMemo(() => {
        const result = readString(props.importCsv.string);
        const columns = result.data[0].map((element) => {
            let column = {};
            column.Header = element;
            column.accessor = element;
			return column;
        });
        return columns;
    }, [props.importCsv]);

    const data = useMemo(() => {
        const result = readString(props.importCsv.string);
        result.data.splice(0, 1);
        const rows = [];
        result.data.forEach((row) => {
            const ro = {};
            row.forEach((element, index) => {
				if (element && element.length > 0) {
					ro[columns[index].accessor] = element;
				}
            });
			if (Object.keys(ro).length !== 0) {
				rows.push(ro);
			}
        })
        return rows;
    }, [props.importCsv, columns])

	const handleSubmit = (e) => {
		e.preventDefault();
		props.uploadCsv(props.importCsv.file, types, fields);
		props.setPopupImport(false);
	}

	const generateNodeCompare = () => {
		const changes = props.nodeChanges ? props.nodeChanges : [];
		return (
			data.map((element) => {
				if (element[fields.find(field => field.field === 'Nom').accessor] === undefined)
					return <></>;
				let old = changes.find(change => change.Nom === capitalizeString(element[fields.find(field => field.field === 'Nom').accessor]));
				return (
					<tr>
						<td className='change__container'>
							{old ? Object.keys(old).map((key) => {
								return (
									<div className='change__content'>
										<div>
											{key + ': '}
										</div>
										<div>
											{old[key]}
										</div>
									</div>
								)
							}) : 'N/A'}
						</td>
						<td className='change__container'>
							{Object.keys(element).map((key) => {
								const field = fields.find(field => key === field.accessor).field;
								if (field) {
									return (
										<div className='change__content'>
											<div>
												{field + ': '}
											</div>
											<div>
												{field === 'Nom' ? capitalizeString(element[key]) : element[key]}
											</div>
										</div>
									)
								} else {
									return <></>;
								}
							})}
						</td>
					</tr>
				)
			})
		);
	}

	const generateRelCompare = () => {
		const changes = props.relChanges ? props.relChanges : [];
		return (
			data.map((element) => {
				if (element[fields.find(field => field.field === 'Source').accessor] === undefined
					|| element[fields.find(field => field.field === 'SourceType').accessor] === undefined
					|| element[fields.find(field => field.field === 'Cible').accessor] === undefined
					|| element[fields.find(field => field.field === 'CibleType').accessor] === undefined)
					return <></>;
				let old = changes.find(change => 
					change.Source === element[fields.find(field => field.field === 'Source').accessor]
					&& change.SourceType[0] === element[fields.find(field => field.field === 'SourceType').accessor]
					&& change.Cible === element[fields.find(field => field.field === 'Cible').accessor]
					&& change.CibleType[0] === element[fields.find(field => field.field === 'CibleType').accessor]);
				return (
					<tr>
						<td className='change__container'>
							{old ? Object.keys(old).map((key) => {
								return (
									<div className='change__content'>
										<div>
											{key + ': '}
										</div>
										<div>
											{old[key]}
										</div>
									</div>
								)
							}) : 'N/A'}
						</td>
						<td className='change__container'>
							{Object.keys(element).map((key) => {
								const field = fields.find(field => key === field.accessor).field;
								if (field) {
									return (
										<div className='change__content'>
											<div>
												{field + ': '}
											</div>
											<div>
												{element[key]}
											</div>
										</div>
									)
								} else {
									return <></>;
								}
							})}
						</td>
					</tr>
				)
			})
		);
	}

	const updateNumber = () => {
		if (types.type === 'node') {
			return <h2>Vous vous appretez à mettre a jour {props.nodeChanges.length} composants de type {types.node}</h2>
		} else {
			return <h2>Vous vous appretez à mettre a jour {props.relChanges.length} relations de type {types.relation}</h2>
		}
	}

	const addNumber = () => {
		const count = types.type === 'node' ? props.nodeChanges.length : props.relChanges.length;
		const dataLength = data.reduce((prev, val) => {
			if (types.type === 'node') {
				if (val[fields.find(field => field.field === 'Nom').accessor] === undefined)
					return prev;
				return prev + 1;
			} else {
				if (val[fields.find(field => field.field === 'Source').accessor] === undefined
					|| val[fields.find(field => field.field === 'Cible').accessor] === undefined
					|| val[fields.find(field => field.field === 'SourceType').accessor] === undefined
					|| val[fields.find(field => field.field === 'CibleType').accessor] === undefined)
					return prev;
				return prev + 1;
			}
		}, 0);
		if (dataLength - count > 0)
			return <h3>Et à en ajouter {data.length - count}</h3>;
	}

	return (
		<div className='container--popup--import'>
			<div className='background--popup--import'></div>
            { fields.length > 0 ?
			<div className='import__form'>
				<form className='container--form' onSubmit={handleSubmit}>
					{(props.nodeChanges || props.relChanges) ? updateNumber() : null}
					{(props.nodeChanges || props.relChanges) ? addNumber() : null}
						<StylesComponent className='customScrollbar' color={types.type === 'node' ? componentColor : relationColor}>
							<table>
								<thead>
									<tr>
										<th>
											Anciennes valeurs {types.type === 'node' ? 'Composant' : 'Relation'}
										</th>
										<th>
											Nouvelles valeurs {types.type === 'node' ? 'Composant' : 'Relation'}
										</th>
									</tr>
								</thead>
								<tbody>
									{types.type === 'node' ? generateNodeCompare() : generateRelCompare()}
								</tbody>
							</table>
						</StylesComponent>
					<div className='container--form--button'>
						<ButtonStyle
							color={cancelColor}
							onClick={() => props.setPopupImport(false)}>
							ANNULER
						</ButtonStyle>
						<ButtonStyle color={uploadColor}>
							CONFIRMER
						</ButtonStyle>
					</div>
				</form>
			</div>
			: 
			<ValidateFields 
				setFields={setFields} 
				setTypes={setTypes}
				csvFields={columns}
				csvData={data}
				relations={props.relations}
				components={props.components}
				setPopupImport={props.setPopupImport}
				></ValidateFields>}
		</div>
	);
}

const mapStateToProps = (state) => ({
	uploadSuccess: state.uploadReducer.success,
    nodeChanges: state.uploadReducer.nodeChanges,
    relChanges: state.uploadReducer.relChanges,
});

const mapDispatchToProps = (dispatch) => ({
    uploadCsv: (data, types, fields) => dispatch(uploadCsv(data, types, fields)),
});

const PopupImport = connect(
    mapStateToProps,
	mapDispatchToProps,
)(PopupImportComponent);

export { PopupImport };
