import React, { useState } from "react";
import { ReactComponent as Trash } from '../../../../../assets/img/trash-alt-solid.svg';
import { ReactComponent as EyeSolid } from '../../../../../assets/img/eye-solid.svg';
import { ReactComponent as EyeSlashSolid } from '../../../../../assets/img/eye-slash-solid.svg';
import { ReactComponent as Expand } from '../../../../../assets/img/expand-svgrepo-com.svg';
import "./Filter.css"


export const isDate = (string) => {
    let match = string.indexOf("date");
    if (string === "Created" || string === "Updated") {
        return true;
    }
    match = match === -1 ? string.indexOf("Date") : match;
    if (match !== -1) {
        if (match === 0 && (!string[4] || string[4] === ' ')) {
            return true;
        } else if ((!string[match -1] || string[match - 1] === " ") 
            && (!string[match + 4] || string[match + 4] === ' ')) {
                return true;
            }
    } 
    return false;
}

const Filter = (props) => {
    const [filter, setFilter] = useState('');

    const handleValue = (value) => {
        if (isDate(props.filterData.propertie)) {
            if (props.filterData.values.includes(value)) {
                let tmp = props.filterData.values;
                tmp.splice(tmp.indexOf(value), 1);
                props.setFilterData(props.id, 'values', tmp);
            } else {
                if (value === '>' || value === '<') {
                    props.setFilterData(props.id, 'values', [value]);
                } else if (props.filterData.values.find((el) => el === '>' || el === '<')) {
                    let tmp = props.filterData.values;
                    if (tmp.indexOf('>') === -1) {
                        tmp.splice(tmp.indexOf('<'), 1);
                    } else {
                        tmp.splice(tmp.indexOf('>'), 1);
                    }
                    props.setFilterData(props.id, 'values', tmp.concat([value]));
                } else {
                    props.setFilterData(props.id, 'values', props.filterData.values.concat([value]));
                }
            }
        } else if (props.filterData.values.includes(value)) {
            let tmp = props.filterData.values;
            tmp.splice(tmp.indexOf(value), 1);
            props.setFilterData(props.id, 'values', tmp);
        } else {
            props.setFilterData(props.id, 'values', props.filterData.values.concat([value]));
        }
    }

    const selectProperty = () => {
        let propertyList;
        if (props.filterData.type === 'node') {
            propertyList = props.nodePossibility && props.nodePossibility[props.filterData.node];
        } else {
            propertyList = props.linkPossibility && props.linkPossibility[props.filterData.link];
        }
        if (!propertyList.find(prop => prop === 'Orphelins'))
            propertyList.push('Orphelins');
        let options = propertyList && propertyList.sort((a, b) => a[0].localeCompare(b[0])).map((propertie, id) => {
            return (
                <option key={id} value={propertie} id={id}>
                    {propertie}
                </option>
            )
        })
        return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    {props.filterData.propertie === 'undefined' ? 
                    <select value={props.filterData.propertie} 
                        style={{borderRadius: '10px'}}
                        onChange={(e) => {props.setFilterData(props.id, 'propertie', e.target.value);props.setFilterData(props.id, 'apply', true)}}>
                        <option value='undefined'>Choisir la propriete</option>
                        {options}
                    </select> :
                    <span style={{ width: '180px'}}>{props.filterData.propertie}</span>}
                    { props.filterData.propertie !== 'undefined' ? 
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div>
                            { props.filterData.apply ? 
                            <div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', backgroundColor: 'lightgrey', borderRadius: '10px', width: '50px'}} onClick={() => props.setFilterData(props.id, 'apply', !props.filterData.apply)}>
                                <div style={{width: 15, height: 15, backgroundColor: 'red', borderRadius: '50%'}}></div>
                                <EyeSlashSolid style={{width: 20, height: 20}}/>
                            </div> :
                            <div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', backgroundColor: 'lightgrey', borderRadius: '10px', width: '50px'}} onClick={() => props.setFilterData(props.id, 'apply', !props.filterData.apply)}>
                                <EyeSolid style={{width: 20, height: 20}}/>
                                <div style={{width: 15, height: 15, backgroundColor: 'green', borderRadius: '50%'}}></div>
                            </div>}
                        </div> 
                    </div>
                    : null }
                </div>
                { props.filterData.propertie !== 'undefined' ? 
				<div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '70px'}}>
					<Expand style={{width: 15, height: 15}}/>
					<Trash onClick={() => props.removeFilter(props.id)} style={{width: 15, height: 15}}/>
				</div> : null }
            </div>
        )

    }

    const generateValues = () => {
        let valueList = [];
        if (props.filterData.type === 'node') {
            props.nodes.forEach((element) => {
                if (element[props.filterData.propertie] !== undefined && element.type === props.filterData.node) {
                    valueList.push(element[props.filterData.propertie]);
                }
            });
        } else {
            props.links.forEach((element) => {
                if (element[props.filterData.propertie] !== undefined && element.type === props.filterData.link) {
                    valueList.push(element[props.filterData.propertie]);
                }
            });
        }
        valueList = [...new Set(valueList)];
        let options = valueList && valueList.sort((a, b) => a.toString().localeCompare(b.toString())).filter(val => {
            if (typeof val === 'string')
                return val.toLowerCase().includes(filter.toLowerCase());
            return val.toString().toLowerCase().includes(filter.toLowerCase());
        }).map((value, id) => {
            return (
                <label key={id} >
                    <input id={id} value={value} 
                        style={{marginLeft: 10, marginRight: 10, marginBottom: 10}}
                        checked={isDate(props.filterData.propertie) ? props.filterData.values.includes(value) : !props.filterData.values.includes(value)} 
                        onChange={() => handleValue(value)} type="checkbox"></input>
                    {value}
                </label>
            )
        })
        let dates;
        if (isDate(props.filterData.propertie)) {
            dates = (
                <>
                    <label>
                        <input value={">"} 
                            style={{marginLeft: 10, marginRight: 10, marginBottom: 10}}
                            checked={props.filterData.values.includes(">")} 
                            onChange={() => handleValue(">")} type="checkbox"></input>
                        {"Superieur a"}
                    </label>
                    <label>
                        <input value={"<"} 
                            style={{marginLeft: 10, marginRight: 10, marginBottom: 10}}
                            checked={props.filterData.values.includes("<")} 
                            onChange={() => handleValue("<")} type="checkbox"></input>
                        {"Inferieur a"}
                    </label>
                </>);
        }
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <input 
                        style={{marginLeft: 10, marginRight: 10, marginBottom: 10, borderRadius: '10px', width: '50%'}}
                        type="text" placeholder='Recherche' onChange={(e) => setFilter(e.target.value)}></input>
                    <button style={{ width: '20%', fontSize: '12px', borderRadius: '10px'}} onClick={() => props.setFilterData(props.id, 'values', [])}>Tout cocher</button>
                    <button style={{ width: '20%', fontSize: '12px', borderRadius: '10px'}} onClick={() => props.setFilterData(props.id, 'values', valueList.concat(['empty']))}>Tout décocher</button>
                </div>
                <div className="graph__filter__values customScrollbar">
                    <label>
                        <input value="empty" 
                            style={{marginLeft: 10, marginRight: 10, marginBottom: 10}}
                            checked={isDate(props.filterData.propertie) ? props.filterData.values.includes('empty') : !props.filterData.values.includes('empty')}
                            onChange={() => handleValue('empty')} type="checkbox"></input>
                        Vide
                    </label>
                    {dates}
                    {options}
                </div>
            </>
        )
    }

    const generateDate = () => {
        return (
            <>
                <hr/>
                <input 
                    value={props.filterData.date}
                    style={{marginLeft: 10, marginRight: 10, marginBottom: 10}}
                    type="date" 
                    placeholder='Choisissez une date' 
                    data-date-format="DD MM YYYY"
                    onChange={(e) => props.setFilterData(props.id, 'date', e.target.value)}></input>
                <hr/>
            </>
        )
    }

	return (
		<div className="graph__filter__cartridge graph__filter__select">
            { props.filterData.open && props.filterData.type !== 'undefined' ? selectProperty() : null}
            { props.filterData.open && props.filterData.type !== 'undefined' && props.filterData.propertie !== 'undefined' && props.filterData.propertie !== 'Orphelins' ? generateValues() : null}
            { props.filterData.open && props.filterData.type !== 'undefined' && props.filterData.propertie !== 'undefined' && props.filterData.values.find((el) => el === '>' || el === '<') ? generateDate() : null}
		</div>
	);
}

export default Filter;
