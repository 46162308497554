import * as d3 from "d3";
import expandLogoSvg from "../../../../../assets/img/expand-svgrepo-com.svg";
import eyeSlash from "../../../../../assets/img/eye-slash-solid.svg";
import lockOpen from "../../../../../assets/img/lock-open-solid.svg";
import dotIcon from "../../../../../assets/img/ellipsis-solid.svg";

	export const updateIcons = (icons) => {
		icons.append("g").attr("id", "expand-icon").attr("fill", "#6a6a6a").attr("color", "#6a6a6a")
		icons.append("g").attr("id", "eye-icon").attr("fill", "#6a6a6a").attr("color", "#6a6a6a")
		icons.append("g").attr("id", "lock-icon").attr("fill", "#6a6a6a").attr("color", "#6a6a6a")
		icons.append("g").attr("id", "dot-icon").attr("fill", "#6a6a6a").attr("color", "#6a6a6a")
		d3.xml(expandLogoSvg)
			.then(data => {
				d3.select('#expand-icon').node()?.append(data.documentElement)
			})
		d3.xml(eyeSlash)
			.then(data => {
				d3.select('#eye-icon').node()?.append(data.documentElement)
			})
		d3.xml(lockOpen)
			.then(data => {
				d3.select('#lock-icon').node()?.append(data.documentElement)
			})
		d3.xml(dotIcon)
			.then(data => {
				d3.select('#dot-icon').node()?.append(data.documentElement)
			})
	}