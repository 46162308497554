import React, { useMemo, useState } from "react";

import { InputAddProps } from "../..";

import { sortedFunction } from "../../../utils/Function";
import { componentColor, cancelColor } from "../../../utils/color";

import ButtonStyle from "../../../styled-components/ButtonStyle";

import "../Form.css";

function UpdateComponent(props) {
	const [double, setDouble] = useState(false);

	const [propertyToUpdate, setPropertyToUpdate] = useState({
		name: props.infoUpdate.Nom,
		type: props.type,
	});

	const [inputFields, setInputFields] = useState([
		{ property: "", content: "" },
	]);

	const handleChange = (event) => {
		setPropertyToUpdate({
			...propertyToUpdate,
			[event.target.name]: event.target.value,
		});
	};

	const formatInput = (event) => {
		setPropertyToUpdate({
			...propertyToUpdate,
			[event.target.name]: event.target.value.trim(),
		});
	};

	const formatNewInput = () => {
		let inputs = [];
		for (const input of inputFields) {
			inputs = {
				...inputs,
				[input.property]: input.content,
			}
		}
		inputs = {...propertyToUpdate,...inputs};
		return inputs;
	}

	const info = Object.entries(props.infoUpdate && props.infoUpdate);

	const infoFiltered =
		info &&
		info.filter(
			(col) => col &&
				col[0] !== "id" &&
				col[0] !== "Nom" &&
				col[0] !== "type" &&
				col[0] !== "Updated" &&
				col[0] !== "Created" &&
				col[0] !== "UpdatedBy" &&
				col[0] !== "CreatedBy",
		).sort((a, b) => a[0].localeCompare(b[0]));

	useMemo(() => {
		let properties = propertyToUpdate;
		infoFiltered.forEach((info) => {
			properties = {
				...properties,
				[info[0]]: info[1],
			}
		});
		setPropertyToUpdate(properties);
	}, [props.info]);

	const renderInputComponentToUpdate = () => {
		return infoFiltered.map((x, i) => {
			return (
				<div className='group--input' key={i}>
					<input
						type='text'
						name={x[0] === 'type' ? 'Classe' : x[0]}
						value={propertyToUpdate[x[0]]}
						onChange={handleChange}
						onBlur={formatInput}
						style={{ color: componentColor }}
					/>
					<span
						className='floating--label--input--update'
						style={{ color: componentColor }}>
						{x[0] === 'type' ? 'Classe' : x[0]}
					</span>
				</div>
			);
		});
	};

	// ===========================================================

	const handleSubmit = (e) => {
		e.preventDefault();
		props.updateComponent(propertyToUpdate, props.type);
		props.setPopupUpdate(false);
		if (props.updateNode) {
			props.updateNode(propertyToUpdate);
		}
	};

	const handleSubmitWithNewProps = (e) => {
		e.preventDefault();
		props.updateComponent(formatNewInput(), props.type);
		props.setPopupUpdate(false);
		if (props.updateNode) {
			props.updateNode(formatNewInput());
		}
	};

	return (
		<form
			onSubmit={
				inputFields[0].property !== "" ? handleSubmitWithNewProps : handleSubmit
			}
			className='container--form'>
			<h2 style={{ color: componentColor }}>METTRE À JOUR LE COMPOSANT</h2>
			<div className='container--tuple--update'>
				<div className='container--cartridge'>
					<div
						className='cartridgeName'
						style={{ backgroundColor: componentColor, maxWidth: 200 }}>
							{propertyToUpdate.name}
					</div>
					<div
						className='cartridgeName'
						style={{ backgroundColor: componentColor, maxWidth: 200 }}>
							{propertyToUpdate.type}
					</div>
				</div>
				{renderInputComponentToUpdate()}
			</div>
			{ props.propertiesListColumnComponent ?
			<InputAddProps
				type={props.type}
				components={props.components}
				inputFields={inputFields}
				setInputFields={setInputFields}
				properties={Object.keys(props.infoUpdate) || sortedFunction(props.info)}
				double={double}
				setDouble={setDouble}
				propertiesList={props.propertiesListColumnComponent.filter(property => 
					property !== 'Created'
					&& property !== 'Updated'
					&& property !== 'UpdatedBy'
					&& property !== 'CreatedBy'
					&& property !== 'Nom')}
			/> : null }
			<div className='container--form--button'>
				<ButtonStyle
					color={cancelColor}
					onClick={() => props.setPopupUpdate(false)}>
					ANNULER
				</ButtonStyle>
				{double ? (
					<span className='duplicate--property'>Propriété déjà existante</span>
				) : (
					<ButtonStyle color={componentColor} type='submit'>
						CONFIRMER
					</ButtonStyle>
				)}
			</div>
		</form>
	);
}

export { UpdateComponent };
