import React, { useState, useMemo } from "react";

import { InputAddProps } from "../..";

import { componentColor, cancelColor } from "../../../utils/color";

import ButtonStyle from "../../../styled-components/ButtonStyle";

import "../Form.css";
import { capitalizeString, capitalizeWord, sortedFunction } from "../../../utils/Function";

function CloneComponent(props) {
	const [double, setDouble] = useState(false);
	const [inUse, setInUse] = useState(false);
	const [customType, setCustomType] = useState('');

	const [newComponent, setNewComponent] = useState({
		type: props.type,
		name: "",
	});

	const [inputFields, setInputFields] = useState([
		{ property: "", content: "" },
	]);
	
	const names = useMemo(() => {
		return props.info.map((comp) => {
			return comp.Nom
		})
	}, [props.info]);

	const handleChange = (event) => {
		if (event.target.name === 'name') {
			setNewComponent({
				...newComponent,
				[event.target.name]: capitalizeString(event.target.value),
			});
			if (!names.find(name => name === capitalizeString(event.target.value))) {
				setInUse(false);
			} else {
				setInUse(true);
			}
		} else {
			setNewComponent({
				...newComponent,
				[event.target.name]: event.target.value,
			});
		}
	};

	const formatInput = (event) => {
		setNewComponent({
			...newComponent,
			[event.target.name]: event.target.value.trim(),
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let update = true;
		if (newComponent.type === '' && customType !== '') {
			newComponent.type = customType;
			if (props.type !== undefined) {
				update = false;
			}
		}
		if (props.type !== undefined && newComponent.type !== props.type) {
			update = false;
		}
		props.addComponent(newComponent, inputFields, update);
		props.setPopupClone(false);
	};

	const info = Object.entries(props.infoUpdate && props.infoUpdate);

	const infoFiltered =
		info &&
		info.filter(
			(col) => col &&
				col[0] !== "id" &&
				col[0] !== "Nom" &&
				col[0] !== "type" &&
				col[0] !== "Updated" &&
				col[0] !== "Created" &&
				col[0] !== "UpdatedBy" &&
				col[0] !== "CreatedBy",
		).sort((a, b) => a[0].localeCompare(b[0]));

		useMemo(() => {
			let properties = newComponent;
			infoFiltered.forEach((info) => {
				properties = {
					...properties,
					[info[0]]: info[1],
				}
			});
			setNewComponent(properties);
		}, [props.infoUpdate]);

		const handlePropertieChange = (event) => {
			setNewComponent({
				...newComponent,
				[event.target.name]: event.target.value,
			});
		};

	const renderInputComponentToUpdate = () => {
		return infoFiltered.map((x, i) => {
			return (
				<div className='group--input' key={i}>
					<input
						type='text'
						name={x[0] === 'type' ? 'Classe' : x[0]}
						value={newComponent[x[0]]}
						onChange={handlePropertieChange}
						onBlur={formatInput}
						style={{ color: componentColor }}
					/>
					<span
						className='floating--label--input--update'
						style={{ color: componentColor }}>
						{x[0] === 'type' ? 'Classe' : x[0]}
					</span>
				</div>
			);
		});
	};

	return (
		<form onSubmit={handleSubmit} className='container--form'>
			<h2 style={{ color: componentColor }}>Cloner un composant</h2>
			<div className='container--tuple'>
				<div className='group--input'>
					{newComponent.type === '' ? 
					<input 
						name='type' 
						style={{ color: componentColor }}
						value={customType} 
						onChange={(e) => setCustomType(capitalizeWord(e.target.value))}/> : null }
					<select
						name='type'
						onChange={handleChange}
						value={newComponent.type}
						style={{ color: componentColor, width: newComponent.type === '' ? '15px' : 'auto' }}
						required={customType === ''}>
						<option value=''></option>
						{ props.components.map((component, i) => {
							return (
								<option key={i} value={component.name}>
									{component.name}
								</option>
							);
						})}
					</select>
					<span
						className='floating--label--select required'
						style={{ color: componentColor }}>
						Catégorie de composant
					</span>
				</div>
				<div className='group--input'>
					<input
						type='text'
						name='name'
						value={newComponent.name}
						onChange={handleChange}
						onBlur={formatInput}
						style={{ color: componentColor, borderBottomColor: componentColor }}
						required
					/>
					<span
						className='floating--label--input required'
						style={{ color: componentColor }}>
						Nom du composant
					</span>
					{inUse ? 
					<span className="floating--label--input--error"
						style={{ color: cancelColor }}>Nom utilisé</span> : null }
				</div>
			</div>
			<div className='container--tuple--update'>
				{renderInputComponentToUpdate()}
			</div>
			{ props.propertiesListColumnComponent ?
			<InputAddProps
				type={props.type}
				components={props.components}
				inputFields={inputFields}
				setInputFields={setInputFields}
				properties={Object.keys(props.infoUpdate) || sortedFunction(props.info)}
				double={double}
				setDouble={setDouble}
				propertiesList={props.propertiesListColumnComponent.filter(property => 
					property !== 'Created'
					&& property !== 'Updated'
					&& property !== 'UpdatedBy'
					&& property !== 'CreatedBy'
					&& property !== 'Nom')}
			/> : null }
			<div className='container--form--button'>
				<ButtonStyle
					color={cancelColor}
					onClick={() => props.setPopupClone(false)}>
					ANNULER
				</ButtonStyle>
				<ButtonStyle color={componentColor} type='submit' disabled={inUse}>
					CONFIRMER
				</ButtonStyle>
			</div>
		</form>
	);
}

export { CloneComponent };
