import { LOGIN, LOGOUT, ERROR, RESET_ERROR } from "../action/userTypes";

export const userReducer = (state = {}, action) => {
	switch (action.type) {

		case LOGIN:
			return state;

		case LOGOUT:
			return state;

		case ERROR:
			return {...state, error: action.payload}

		case RESET_ERROR:
			return {...state, error: ''}

		default:
			return state;
	}
};