import {
	GET_NODE_NAMES,
	GET_COMPONENTS,
	GET_COMPONENT_INFO,
	GET_COMPONENT_ORIGIN_NAME,
	GET_COMPONENT_TARGET_NAME,
	GET_COMPONENT_RELATION_NUMBER,
	GET_PROPERTY_LIST_COMPONENT,
	DELETE_COMPONENT,
	ADD_COMPONENT,
	SEE_RELATIONS,
	UPDATE_COMPONENT,
} from "../action";

export const componentReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_NODE_NAMES:
			return { ...state, nodeNames: action.payload };

		case GET_COMPONENTS:
			return { ...state, components: action.payload };

		case GET_COMPONENT_INFO:
			return { ...state, componentInfo: action.payload };

		case GET_COMPONENT_ORIGIN_NAME:
			return { ...state, componentOriginName: action.payload };

		case GET_COMPONENT_TARGET_NAME:
			return { ...state, componentTargetName: action.payload };

		case GET_COMPONENT_RELATION_NUMBER:
			return { ...state, componentRelationNumber: action.payload };

		case GET_PROPERTY_LIST_COMPONENT:
			return { ...state, propertiesListColumnComponent: action.payload };

		case DELETE_COMPONENT:
			let componentsInfo = state.componentInfo;
			let nodeNames = state.nodeNames;
			let components = state.components;
			let res;
			let resNode;
			if (componentsInfo) {
				res = componentsInfo.filter(
					(component) => component.id !== action.payload.componentId,
				);
				resNode = nodeNames.filter(
					(node) =>  node.id !== action.payload.componentId,
				);
			}
			if (components) {
				const comp = components.findIndex(component => component.name[0] === action.payload.componentType);
				if (comp !== -1 && components[comp].number === 1) {
					components.splice(comp, 1);
				} else if (comp !== -1) {
					components[comp].number -= 1;
				}
			}
			return { ...state, componentInfo: res, nodeNames: resNode, components };

		case ADD_COMPONENT:
			if (action.payload.update !== undefined && action.payload.update === false) {
				return state;
			}
			delete action.payload.update;
			let newComponent = [];
			if (!state.components.find(el => el.name[0] === action.payload.type)) {
				newComponent[0] = {
					name: [action.payload.type],
					number: 1,
					content: Object.keys(action.payload).filter(el =>
						el !== 'id' &&
						el !== 'name' &&
						el !== 'type'
					)
				};
			} else if (state.components.find(el => el.name[0] === action.payload.type)) {
				state.components.find(el => el.name[0] === action.payload.type).number += 1;
			}
			return { ...state,
				componentInfo: (state.componentInfo ? state.componentInfo.concat(action.payload) : [action.payload]), 
				lastComponents: (state.lastComponents ? state.lastComponents.concat([action.payload.id]) : [action.payload.id]),
				components: state.components.concat(newComponent)
			 };

		case SEE_RELATIONS:
			return { ...state, componentRelationList: action.payload };

		case UPDATE_COMPONENT:
			if (state.componentInfo) {
				const index = state.componentInfo.findIndex(
					(item) => item.Nom === action.payload.Nom,
				);
				let item = state.componentInfo[index];
				const { name, type, ...updateData } = action.payload;
	
				let newObj = [{ ...item, ...updateData }];
	
				newObj[0].Nom = action.payload.name;
	
				let final = state.componentInfo.map(
					(obj) => newObj.find((o) => o.Nom === obj.Nom) || obj,
				);
				return { ...state, componentInfo: final };
			} else {
				return state;
			}

		default:
			return state;
	}
};
