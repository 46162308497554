import React from "react";
import { Switch, Route, Redirect } from "react-router";
import NodeSettings from "../components/Settings/NodeSettings/NodeSettings";
import PropertiesSettings from "../components/Settings/PropertiesSettings/PropertiesSettings";
import RelationshipSettings from "../components/Settings/RelationshipSettings/RelationshipSettings";
import DatabaseSettings from "../components/Settings/DatabaseSettings/DatabaseSettings";
import { AdminSettings } from "../components/Settings/AdminSettings/AdminSettings";
import { UserSettings } from "../components/Settings/UserSettings/UserSettings";

const Settings = (props) => {
	return (
		<>
			<div className='big--container'>
				<div className='container--component--page' style={{height: '90vh'}}>
					<Route exact path="/settings" render={() => <Redirect to="/" />} />
					<Switch>
						<Route path='/settings/node' render={() => <NodeSettings nodes={props.components} settings={props.settings} updateSettings={props.updateSettings}/>} />
						<Route path='/settings/relation' render={() => <RelationshipSettings relations={props.relations} settings={props.settings} updateSettings={props.updateSettings}/>} />
						<Route path='/settings/properties' render={() => <PropertiesSettings properties={props.properties} updatePropertie={props.updatePropertie}/>} />
						<Route path='/settings/database' render={() => <DatabaseSettings databases={props.databases} updateDatabase={props.updateDatabase}/>} />
						<Route path='/settings/admin' render={() => <AdminSettings />} />
						<Route path='/settings/user' render={() => <UserSettings />} />
					</Switch>
				</div>
			</div>
		</>
	);
}

export { Settings };
