import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import {
	Cartridge,
	//Table,
	ReactTable,
	PopupDelete,
	PopupCreate,
	PopupUpdate,
	Loader,
} from "../components";

import { getComponents } from "../action/componentAction";
import {
	getRelations,
	getRelationInfo,
	deleteRelation,
	updateRelation,
} from "../action/relationAction";
import { relationColor } from "../utils/color";

import { sortedFunction, flattenObj/*, formatDate */} from "../utils/Function";
import addLogo from "../assets/table/add.svg";
import { ReactComponent as ExportLogo } from '../assets/img/file-export-solid.svg';
import { ExportToCsv } from "export-to-csv";
import { PopupClone } from "../components/Popup/PopupClone";

import "bootstrap/dist/css/bootstrap.css";
import "../css/Relations.css";

function RelationsComponent(props) {
	const [type, setType] = useState("");
	const [relationId, setRelationId] = useState();
	const [infoUpdate, setInfoUpdate] = useState();
	const [popupDelete, setPopupDelete] = useState(false);
	const [popupCreate, setPopupCreate] = useState(false);
	const [popupUpdate, setPopupUpdate] = useState(false);
	const [dataFiltered, setDataFiltered] = useState();
	const update = props.propertiesListColumnRelation;
	const relationPage = true;

	const propsgetRelations = props.getRelations;
	const propsGetComponents = props.getComponents;

	useEffect(() => {
		propsgetRelations();
		propsGetComponents();
	}, [propsgetRelations, propsGetComponents, relationPage]);

	const propsGetRowInfo = props.getRelationInfo;

	useEffect(() => {
		propsGetRowInfo(props.type);
	}, [propsGetRowInfo, props.type]);

	const relations = props.relations && props.relations;
	const relationInfo = props.relationInfo && props.relationInfo;
	const components = props.components && props.components;

	const renderRelationsList = () => {
		return (
			relations &&
			relations.sort((a, b) => {
				return a.name.localeCompare(b.name);
			}).map((relation, i) => {
				return (
					<Cartridge
						key={i}
						name={relation.name}
						number={relation.number}
						color={"#8758A0"}
						setType={setType}
						type={type}
					/>
				);
			})
		);
	};

	let dataColumns = relationInfo && relationInfo.map((x) => x.data);

	const relationData = useMemo(() => {
		return (
			relationInfo &&
			relationInfo.map((obj) => {
				const id = obj.id;
				const origin = obj.labelOrigin + ': ' + obj.origin;
				const target = obj.labelTarget + ': ' + obj.target;
				const idOrigin = obj.idOrigin;
				const idTarget = obj.idTarget;
				const type = obj.type;
				const { ...rest } = flattenObj(obj.data);
	
				return { id, origin, target, type, idOrigin, idTarget, ...rest };
			})
		);
	}, [props.relationInfo])
		

	const renderPopupUpdate = () => {
		if (popupUpdate) {
			return (
				<PopupUpdate
					type={type}
					infoUpdate={infoUpdate}
					setPopupUpdate={setPopupUpdate}
					relationPage={relationPage}
					nodeNames={props.nodeNames}
				/>
			);
		}
	};

	const exportcsv = () => {
		const options = { 
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalSeparator: '.',
			useTextFile: false,
			useBom: true,
			useKeysAsHeaders: true,
		  };
		const csvExporter = new ExportToCsv(options);
		const dataOnOrder = dataFiltered.sort((a, b) => {
			if (Object.keys(a).length < Object.keys(b).length)
				return 1;
			if (Object.keys(a).length > Object.keys(b).length)
				return -1;
			return 0;
		});
		const header = Object.keys(dataOnOrder[0]);
		dataFiltered.forEach(data => {
			header.forEach(key => {
				if (!data[key])
					data[key] = '';
			})
			const fromTo = relationInfo.find(rel => rel.id === data.id);
			if (fromTo) {
				data['Type de composant source'] = fromTo.labelOrigin;
				data['Type de composant cible'] = fromTo.labelTarget;
				data['origin'] = fromTo.origin;
				data['target'] = fromTo.target;
			}
			Object.keys(data).forEach(key => {
				console.log(key);
				if (key === "Created_year_low" ||
					key === "Created_month_low" ||
					key === "Created_day_low" ||
					key === "Created_hour_low" ||
					key === "Created_minute_low" ||
					key === "Created_second_low" ||
					key === "Created_nanosecond_low" ||
					key === "Updated_year_low" ||
					key === "Updated_month_low" ||
					key === "Updated_day_low" ||
					key === "Updated_hour_low" ||
					key === "Updated_minute_low" ||
					key === "Updated_second_low" ||
					key === "Updated_nanosecond_low" ||
					key === "Updated" ||
					key === "UpdatedBy" ||
					key === "Created" ||
					key === "CreatedBy")
					delete data[key];
			});
		});
		const value = csvExporter.generateCsv(dataFiltered, true);
		const file = new Blob([value], {type: 'text/plain'});
		var downloadLink = document.createElement("a");
		downloadLink.href = URL.createObjectURL(file);
		downloadLink.download = "relations.csv";
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	}

	return (
		<div className='big--container'>
			<div className='container--relation--page'>
				<div className='container--button--add'>
					<h1 className='page--title' style={{ color: relationColor }}>
						RELATIONS
					</h1>
					<img
						data-tip="Ajouter une relation"
						src={addLogo}
						alt='Ajouter'
						className='add--logo'
						onClick={() => setPopupCreate(true)}
					/>
					{
						relationInfo && type ?
						<ExportLogo className='add--logo' style={{fill: relationColor}} onClick={() => exportcsv()} title="Exporter les composants"/>
						: null
					}
				</div>
				{popupDelete && (
					<PopupDelete
						setPopupDelete={setPopupDelete}
						instance={props.rowInstance && props.rowInstance}
						deleteRelation={props.deleteRelation}
						relationId={relationId}
					/>
				)}
				{props.popupClone && (
					<PopupClone
						type={type}
						infoUpdate={infoUpdate}
						relationInfo={relationInfo}
						setPopupClone={props.setPopupClone}
						relationPage={relationPage}
						nodeNames={props.nodeNames}
					/>
				)}
				{popupCreate && (
					<PopupCreate
						setPopupCreate={setPopupCreate}
						// addRelation={props.addRelation}
						relations={relations}
						components={components}
						info={relationInfo}
						type={type}
					/>
				)}
				{update && renderPopupUpdate()}
				{relations ? (
					<div className='relation'>{renderRelationsList()}</div>
				) : (
					<Loader />
				)}
				{relationInfo && type && (
					<ReactTable
						type={type}
						rowInfo={relationData}
						columns={sortedFunction(dataColumns)}
						getRowInfo={props.getRelationInfo}
						setRelationId={setRelationId}
						setPopupDelete={setPopupDelete}
						setPopupCreate={setPopupCreate}
						setPopupUpdate={setPopupUpdate}
						setPopupClone={props.setPopupClone}
						setInfoUpdate={setInfoUpdate}
						setDataFiltered={setDataFiltered}
						relationPage={relationPage} 
						updateRelation={props.updateRelation}
					/>
				)}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	relations: state.relationReducer.relations,
	relationInfo: state.relationReducer.relationInfo,
	components: state.componentReducer.components,
	rowName: state.dataReducer.rowName,
	nodeNames: state.componentReducer.nodeNames,
	rowInstance: state.dataReducer.rowInstance,
	propertiesListColumnRelation:
		state.relationReducer.propertiesListColumnRelation,
});

const mapDispatchToProps = (dispatch) => ({
	getRelations: () => dispatch(getRelations()),
	getComponents: () => dispatch(getComponents()),
	getRelationInfo: (type) => dispatch(getRelationInfo(type)),
	deleteRelation: (relationId) => dispatch(deleteRelation(relationId)),
	updateRelation: (relationToUpdate) => dispatch(updateRelation(relationToUpdate)),
});

const Relations = connect(
	mapStateToProps,
	mapDispatchToProps,
)(RelationsComponent);

export { Relations };
