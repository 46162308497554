import React, { Component } from "react";
import "./GraphSettings.css";
import { LinkPopup } from "./GraphSettingsPopup/LinkPopup/LinkPopup";
import { NodePopup } from "./GraphSettingsPopup/NodePopup/NodePopup";
import { colors } from "../../../utils/color";
import { ReactComponent as Square } from '../../../assets/img/square-regular.svg';
import { ReactComponent as Circle } from '../../../assets/img/circle-regular.svg';
import { ReactComponent as Cube } from '../../../assets/img/cube-solid.svg';
import { ReactComponent as Double } from '../../../assets/img/angle-double-left-solid.svg';
import { ReactComponent as LinkCircle } from '../../../assets/img/arrow-circle-left-solid.svg';
import { ReactComponent as Arrow } from '../../../assets/img/arrow-left-solid.svg';
import { ReactComponent as LinkSquare } from '../../../assets/img/caret-square-left-solid.svg';
import { ReactComponent as EyeSolid } from '../../../assets/img/eye-solid.svg';
import { ReactComponent as RotateLeftSolid } from '../../../assets/img/eraser-solid.svg';
import GraphFilter from "./GraphFilter/GraphFilter";
import { hideFilter } from "../GraphFunctions/filter/filter";
import moment from "moment";

class GraphSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
			type: 'node',
			show: false,
			name: 'default',
            filters: [],
        };
		this.setType = 			this.setType.bind(this);
		this.generateNodes = 	this.generateNodes.bind(this);
		this.generateLinks = 	this.generateLinks.bind(this); 
		this.setShow =			this.setShow.bind(this);
		this.handlePopup = 		this.handlePopup.bind(this);
		this.setFilter = 		this.setFilter.bind(this);
		this.addFilter = 		this.addFilter.bind(this);
		this.removeFilter =		this.removeFilter.bind(this);
		
		this.nodeShapes = [
			{name: 'circle', svg: <Circle/>},
			{name: 'square', svg: <Square/>},
			{name: 'cube', svg: <Cube/>}
		];

		this.linkShapes = [
			{name: 'circle', svg: <LinkCircle/>},
			{name: 'square', svg: <LinkSquare/>},
			{name: 'double', svg: <Double/>},
			{name: 'Arrow', svg: <Arrow/>}
		];

		this.linesWidth = ['1px', '2px', '3px', '4px', '5px', '6px', '7px', '8px', '9px', '10px'];
		this.lineType = ['dotted', 'dashed', 'solid', 'double'];

		this.sizes = ['10px', '20px', '30px', '40px', '50px', '60px'];
	
		this.colors = colors;
    }

	componentDidUpdate(prevProps, prevState) {
		hideFilter(this.state.filters, this.props.nodes, this.props.links, this.props.links2);
	}

	setType(newType) {
		this.setState((state) => ({type: newType, show: false}));
	}

	setFilter(id, opt, value) {
		let filterList = this.state.filters;
		filterList[id][opt] = value;
		this.setState({filters: filterList});
		if (opt === 'propertie') {
			const filterType = filterList[id].type;
			const name = filterList[id][filterType];
			const sameFilters = filterList.filter(el => el.type === filterType && el[filterType] === name);
			if (sameFilters[sameFilters.length - 1].propertie !== 'undefined')
				this.addFilter(filterType, name, false);
		}
	}

	removeFilter(id) {
		let filterList = this.state.filters;
		filterList.splice(id, 1);
		this.setState({filters: filterList});
	}

	addFilter(type, name, hide) {
		this.setState({
			filters: this.state.filters.concat({
				open: true,
				type: type,
				hide: hide,
				node: type === 'node' ? name : 'undefined',
				link: type === 'link' ? name : 'undefined',
				propertie: 'undefined',
				values: [],
				apply: false,
				date: moment().format("YYYY-MM-DD"),
			})
		});
	}

	generateLinks() {
		return (
			this.props.linksType.map((element, index) => {
				return (
					<div key={index} className="graph__settings__content__cartridge">
						<GraphFilter
							name={element}
							type='link'
							filters={this.state.filters}
							setFilter={this.setFilter}
							addFilter={this.addFilter}
							removeFilter={this.removeFilter}
							mapAllNodesByType={this.props.mapAllNodesByType}
							mapAllRelationsByType={this.props.mapAllRelationsByType}
							handlePopup={this.handlePopup}
							nodes={this.props.nodes2}
							links={this.props.links2}
							nodesType={this.props.nodesTypes}
							linksType={this.props.linksTypes}
							nodePossibility={this.props.nodePossibility}
							linkPossibility={this.props.linkPossibility}
							settings={this.props.settings}> 
						</GraphFilter>
					</div>
				);
			})
		);
	}

	generateNodes() {
		return (
			this.props.nodesType.map((element, index) => {
				return (
					<div key={index} className="graph__settings__content__cartridge">
						<GraphFilter
							name={element}
							type='node'
							filters={this.state.filters}
							setFilter={this.setFilter}
							addFilter={this.addFilter}
							removeFilter={this.removeFilter}
							mapAllNodesByType={this.props.mapAllNodesByType}
							handlePopup={this.handlePopup}
							nodes={this.props.nodes2}
							links={this.props.links2}
							nodesType={this.props.nodesTypes}
							linksType={this.props.linksTypes}
							nodePossibility={this.props.nodePossibility}
							linkPossibility={this.props.linkPossibility}
							settings={this.props.settings}>
						</GraphFilter>
					</div>
				);
			})
		);
	}

	setShow() {
		this.setState((state) => ({show: !this.state.show}));
	}

	handlePopup(name) {
		this.setState((state) => ({show: true, name: name}));
	}

	render() {
		return (
			<>
				{this.state.show ? 
					(this.state.type === 'node' ?
					<NodePopup
						name={this.state.name}
						settings={this.props.settings}
						updateSettings={this.props.updateSettings}
						setShow={this.setShow}
						shapes={this.nodeShapes}
						sizes={this.sizes}
						contents={this.props.nodePossibility[this.state.name]}
						updateGraph={this.props.updateNode}
						/>
						:
					<LinkPopup
						name={this.state.name}
						settings={this.props.settings}
						updateSettings={this.props.updateSettings}
						setShow={this.setShow}
						shapes={this.linkShapes}
						contents={this.props.linkPossibility[this.state.name]}
						lineType={this.lineType}
						linesWidth={this.linesWidth}
						updateGraph={this.props.updateLink}
						/>) 
				: null}
				<div className="graph__settings__extend" style={{right: this.props.show ? '30%' : '0px', width: '40px'}} onClick={() => this.props.setVisibility(!this.props.show)}>
					<EyeSolid style={{ fill: 'grey', width: '25px'}} />
				</div>
				<div className="graph__settings__holder" style={{visibility: this.props.show ? 'visible' : 'hidden'}}>
					<div className="graph__settings__menu" >
						<div
							className="graph__settings__menu__select"
							onClick={() => this.setType('node')}
							style={{borderBottom: this.state.type === 'node' ? '2px solid rgb(71, 168, 220)' : '2px solid rgba(34,36,38,.15)'}}>
							<span>
								Composants {this.props.nodes2.length} {this.state.type === 'node' ? <RotateLeftSolid style={{width: 15, height: 15}} onClick={() => {this.setState((state) => ({hide: [], filters: []}))}}/> : null}
							</span>
						</div>
						<div
							className="graph__settings__menu__select"
							onClick={() => this.setType('link')}
							style={{borderBottom: this.state.type === 'link' ? '2px solid rgb(71, 168, 220)' : '2px solid rgba(34,36,38,.15)'}}>
							<span>
								Relations {this.props.links2.length} {this.state.type === 'link' ? <RotateLeftSolid style={{width: 15, height: 15}} onClick={() => {this.setState((state) => ({hide: [], filters: []}))}}/> : null}
							</span>
						</div>
					</div>
					<div className="graph__settings__content">
						<div className="graph__settings__list">
							{this.props.settings && this.state.type === 'node' ? this.generateNodes() : this.generateLinks()}
						</div>
					</div>
					{/* <hr></hr>
					<div className="graph__settings__options">
					</div> */}
				</div>
			</>
		);
	}
}

export default GraphSettings;
