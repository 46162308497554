import React from "react";

import { componentColor, relationColor } from "../../utils/color";

import "./TablePagination.css";

function TablePagination(props) {
	return (
		<div
			className='container--pagination'
			style={{ color: props.componentPage ? componentColor : relationColor}}>
			<select
				value={props.pageSize}
				onChange={(e) => props.setPageSize(Number(e.target.value))}
				style={
					props.componentPage
						? { color: componentColor }
						: { color: relationColor }
				}>
				{[...new Set([props.pageSize, 20, 50])].map((pageSize) => (
					<option key={pageSize} value={pageSize}>
						{pageSize}
					</option>
				))}
			</select>
			<span>Lignes par page</span>
			
			<button
				style={
					props.componentPage
						? { color: componentColor }
						: { color: relationColor }
				}
				onClick={() => props.gotoPage(0)}
				disabled={!props.canPreviousPage}>
				{"<<"}
			</button>
			<button
				style={
					props.componentPage
						? { color: componentColor }
						: { color: relationColor }
				}
				onClick={() => props.previousPage()}
				disabled={!props.canPreviousPage}>
				{"<"}
			</button>
			<span>
				Page{" "}
					{props.pageIndex + 1} sur {props.pageOptions.length}
			</span>
			<button
				style={
					props.componentPage
						? { color: componentColor }
						: { color: relationColor }
				}
				onClick={() => props.nextPage()}
				disabled={!props.canNextPage}>
				{">"}
			</button>
			<button
				style={
					props.componentPage
						? { color: componentColor }
						: { color: relationColor }
				}
				onClick={() => props.gotoPage(props.pageCount - 1)}
				disabled={!props.canNextPage}>
				{">>"}
			</button>
		</div>
	);
}

export { TablePagination };
