import React, { Component } from "react";
import { downloadJPEG } from "./GraphFunctions/export/exportJPEG";
import { downloadSVG } from "./GraphFunctions/export/exportSVG";
import { ReactComponent as Trash } from '../../assets/img/trash-alt-solid.svg';
import { ReactComponent as Info } from '../../assets/img/circle-info-solid.svg';
import { ReactComponent as Pencil } from '../../assets/img/pencil-solid.svg';
import { ReactComponent as Expand } from '../../assets/img/expand-svgrepo-com.svg';
import { ReactComponent as EyesSlash } from '../../assets/img/eye-slash-solid.svg';

class ContextMenu extends Component {
        generateNodeParam() {
            return (
                <>
                    <li>
                        <button className="graph__btn context__btn" onClick={() => {this.props.setType(true);this.props.setPopupUpdate(true)}}><Pencil style={{height: '20px', width: '20px'}}/> Modifier le composant</button>
                    </li>
                    <li>
                        <button className='graph__btn context__btn  context__btn--panel-btn' onClick={this.props.dataBtn}><Info style={{height: '20px', width: '20px'}}/> Afficher les  propriétés</button>
                    </li>
                    {/* <li>
                        <button className='graph__btn context__btn  context__btn--panel-btn' onClick={() => this.props.removeNode([this.props.nodeData.id])}>Masquer le composant</button>
                    </li> */}
                    <li>
                        <button className='graph__btn context__btn' onClick={() => {this.props.setType(true);this.props.setPopupDelete(true)}}><Trash style={{height: '20px', width: '20px'}}/> Supprimer le composant</button>
                    </li>
                    <li>
                        <button className='graph__btn context__btn  context__btn--panel-btn' onClick={() => this.props.setShowRel(this.props.nodeData)}><Expand style={{height: '20px', width: '20px'}}/> Deployer</button>
                    </li>
                </>
            );
        }

        generateLinkParam() {
            return (
                <>
                    <li>
                        <button className="graph__btn context__btn" onClick={() => this.props.removeLink(this.props.linkData.id)}><EyesSlash style={{height: '20px', width: '20px'}}/> Masquer la relation</button>
                    </li>
                    <li>
                        <button className='graph__btn context__btn' onClick={() => {this.props.setType(false);this.props.setPopupUpdate(true)}}><Pencil style={{height: '20px', width: '20px'}}/> Modifier la relation</button>
                    </li>
                    <li>
                        <button className='graph__btn context__btn  context__btn--panel-btn' onClick={this.props.dataBtn}><Info style={{height: '20px', width: '20px'}}/> Afficher les  propriétés</button>
                    </li>
                    <li>
                        <button className='graph__btn context__btn' onClick={() => {this.props.setType(false);this.props.setPopupDelete(true)}}><Trash style={{height: '20px', width: '20px'}}/> Supprimer la relation</button>
                    </li>
                </>
            );
        }

        generateDeployParam() {
            return (
                <>
                    <li>
                        <button className="graph__btn context__btn" onClick={() => this.props.deployAll(this.props.nodes.map(node => node.id))}>Tout</button>
                    </li>
                    {this.props.relationsTypes && this.props.relationsTypes.map((element) => (
                        <li>
                            <button className="graph__btn context__btn" onClick={() => {
                                this.props.mapNodeRelationsByType(this.props.nodes.map(node => node.id), element);
                                }}>{element}</button>
                        </li>
                    ))}
                </>
            );
        }

        generateBackParam() {
            if (this.props.nodes.length === 0 && this.props.links.length === 0) {
                return (
                    <>
                        <li>
                            <button className='graph__btn context__btn' onClick={() => {this.props.setType(true);this.props.setPopupCreate(true)}}>Créer un composant</button>
                        </li>
                    </>
                )
            }
            return (
                <>
                    <li>
                        <button className="graph__btn context__btn" onClick={this.props.saveBtn}>Sauvegarder</button>
                    </li>
                    <li>
                        <button className='graph__btn context__btn' onClick={this.props.resetGraph}>Reset</button>
                    </li>
                    <li>
                        <button className='graph__btn context__btn' onClick={this.props.setShowExport}>Générer un fichier</button>
                    </li>
                    <li>
                        <button className='graph__btn context__btn' onClick={() => {this.props.setType(true);this.props.setPopupCreate(true)}}>Créer un composant</button>
                    </li>
                    <li>
                        <button className='graph__btn context__btn' onClick={() => {this.props.releaseNodes()}}>Relacher tout</button>
                    </li>
                    <li>
                        <button className='graph__btn context__btn' onClick={() => {this.props.deployAll(this.props.nodes.map(node => node.id))}}>Deployer tout</button>
                    </li>
                </>
            );
        }

        generateMultiParam() {
            let selected = [];
            this.props.selectedElements.forEach(element => {
                selected.push(element.id);
            });;
            if (selected.length === 2) {
                return (
                    <>
                        <li>
                            <button className="graph__btn context__btn" onClick={() => this.props.removeNode(selected)}>Masquer les composants</button>
                        </li>
                        <li>
                            <button className='graph__btn context__btn' onClick={() => {this.props.setType(false);this.props.setPopupCreate(true)}}>Créer une relation</button>
                        </li>
                    </>
                )
            } else {
                return (
                    <li>
                        <button className="graph__btn context__btn" onClick={() => this.props.removeNode(selected)}>Masquer les composants</button>
                    </li>
                )
            }
        }

        getMenuWidth() {
            let menu = document.getElementById('context-menu');
            let style = getComputedStyle(menu);
            let width = parseInt(style.width);
            return width;
        }

        getMenuHeight() {
            let menu = document.getElementById('context-menu');
            let style = getComputedStyle(menu);
            let width = parseInt(style.height);
            return width;
        }

        isValidHttpUrl(string) {
            let url;
            
            try {
              url = new URL(string);
            } catch (_) {
              return false;  
            }
          
            return url.protocol === "http:" || url.protocol === "https:";
          }

        selectType() {
            switch (this.props.type) {
                case 'node':
                    return this.generateNodeParam()
                case 'link':
                    return this.generateLinkParam()
                case 'deploy':
                    return this.generateDeployParam()
                default:
                    return
            }
        }
        
	render() {
		return (
            <>
                <ul
                id='context-menu'
                className="context-menu"
                style={{
                    top: this.props.anchorPoint.y,
                    left: this.props.anchorPoint.x,
                    width: '300px'
                }}>
                { this.selectType() }
                
            </ul>
            { (this.props.showData && Object.keys(this.props.linkData).length === 0) && (
                <ul
                    className="context-menu"
                    style={{
                        top: this.props.anchorPoint.y + (this.getMenuHeight() / 2),
                        left: this.props.anchorPoint.x + (this.getMenuWidth() - 20)
                }}>
                     {Object.keys(this.props.nodeData).filter(
                        (prop) => 
                            prop !== 'id' &&
                            prop !== 'index' &&
                            prop !== 'name' &&
                            prop !== 'y' &&
                            prop !== 'x' &&
                            prop !== 'fx' &&
                            prop !== 'fy' &&
                            prop !== 'vy' &&
                            prop !== 'vx' &&
                            prop !== 'cx' &&
                            prop !== 'cy',
                        ).sort((a, b) => a[0].localeCompare(b[0])).map((key, index) => (
                         <li key={index} >
                             {key === 'type' ? 'Classe' : key}: {this.isValidHttpUrl(this.props.nodeData[key]) ? <a href={this.props.nodeData[key]}  target="_blank" rel="noopener noreferrer">{this.props.nodeData[key]}</a> : this.props.nodeData[key]}
                         </li>
                     ))}
                </ul>
            )}
            { (this.props.showData && Object.keys(this.props.nodeData).length === 0) && (
                <ul
                    className="context-menu"
                    style={{
                        top: this.props.anchorPoint.y + (this.getMenuHeight() / 2),
                        left: this.props.anchorPoint.x + (this.getMenuWidth() - 20)
                }}>
                     {Object.keys(this.props.linkData).filter(
                        (prop) => 
                            prop !== 'id' &&
                            prop !== 'idOrigin' &&
                            prop !== 'idTarget' &&
                            prop !== 'origin' &&
                            prop !== 'rowOrigin' &&
                            prop !== 'rowTarget' &&
                            prop !== 'target',
                        ).sort((a, b) => a[0].localeCompare(b[0])).map((key, index) => (
                         <li key={index} >
                             {key === 'type' ? 'Classe' : key}: {this.isValidHttpUrl(this.props.linkData[key]) ? <a href={this.props.linkData[key]}  target="_blank" rel="noopener noreferrer">{this.props.linkData[key]}</a> : this.props.linkData[key]}
                         </li>
                     ))}
                </ul>
            )}
            { (this.props.showExport) && (
                <ul
                    className="context-menu"
                    style={{
                        top: this.props.anchorPoint.y + (this.getMenuHeight() / 2),
                        left: this.props.anchorPoint.x + (this.getMenuWidth() - 20)
                }}>
                    <li>
                        <button className="graph__btn context__btn" onClick={downloadSVG}>SVG</button>
                    </li>
                    <li>
                        <button className="graph__btn context__btn" onClick={this.props.downloadCSV}>CSV</button>
                    </li>
                    <li>
                        <button className="graph__btn context__btn" onClick={downloadJPEG}>JPEG</button>
                    </li>
                </ul>
            )}
            { (this.props.showRel && this.props.relationsTypes) && (
                <ul
                    className="context-menu"
                    style={{
                        top: this.props.anchorPoint.y + (this.getMenuHeight() / 2),
                        left: this.props.anchorPoint.x + (this.getMenuWidth() - 20)
                }}>
                    <li>
                        <button className="graph__btn context__btn" onClick={() => this.props.deployAll([this.props.nodeData.id])}>Tout</button>
                    </li>
                    {this.props.relationsTypes.map((element) => (
                        <li>
                            <button className="graph__btn context__btn" onClick={() => {
                                this.props.mapNodeRelationsByType([this.props.nodeData.id], element);
                                }}>{element}</button>
                        </li>
                    ))}
                </ul>
            )}
            
            </>
			

		);
	}
}

export default ContextMenu;
