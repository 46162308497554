import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";

import { SuggestionModal, InputBox } from "../components";
// import GraphTest from "../components/GraphTest";
import GraphTest2 from "../components/Graph/GraphTest2";
// import Controle from "../components/graph//Controle";

import {
	getComponents,
	getComponentInfo,
	getNodeNames,
	deleteComponent,
	seeRelations,
	getComponentRelationNumber,
} from "../action/componentAction";
import { getRelations, getRelationInfo, deleteRelation } from "../action/relationAction";
import { mapNodeRelations, mapNode, mapNodeRelationsTypes, mapNodeRelationsByType, mapAllNodesByType, mapAllRelationsByType, mapSaveCarto, mapLoadCarto, mapUpdateCarto } from "../action/mapAction";
// import ButtonStyle from "../styled-components/ButtonStyle";
// import { cancelColor } from "../utils/color";

import {
	// getInfoInputList,
	// compareInput,
	//removeDuplicateObjectFromArray,
	useOnClickOutside,
} from "../utils/Function";

import "../css/MapZone.css";

function MapZoneComponent(props) {
	const [suggestions, setSuggestions] = useState([]);
	const [suggestionSelect, setSuggestionSelect] = useState();
	const [suggestionsCarto, setSuggestionsCarto] = useState([]);
	const [cartoNodes, setCartoNodes] = useState([]);
	const [query, setQuery] = useState("");
	const [inputFields, setInputFields] = useState([]);
	const [openSuggestions, setOpenSuggestions] = useState(false);
	const [openFilter, setOpenFilter] = useState(false);
	let dataFromInput;
	let type;

	const ref = useRef();
	useOnClickOutside(ref, () => props.setOpenSuggestions(false));
	const ref2 = useRef();
	useOnClickOutside(ref2, () => props.setOpenSuggestions(false));

	// =====================================

	// UseEffect
	const propsGetNodeNames = props.getNodeNames;
	const propsGetRelations = props.getRelations;
	const propsGetComponents = props.getComponents;
	const propsGetCarto = props.mapLoadCarto;

	useEffect(() => {
		propsGetComponents();
		propsGetRelations();
		propsGetNodeNames();
		propsGetCarto(sessionStorage.getItem("cool-jwt"));
	}, [propsGetNodeNames, propsGetRelations, propsGetComponents]);

	// ==========================

	// Handle propreties of input

	// props.components &&
	// 	props.components.map((component) => {
	// 		return nameArr.push(component.name);
	// 	});

	// props.relations &&
	// 	props.relations.map((relation) => {
	// 		return nameArr.push(relation.name);
	// 	});
	
	const nodeNames = props.nodeNames;

	const listNames = useMemo(() => {
		return nodeNames;
	}, [nodeNames]);

	const componentName = useMemo(() => {
		return nodeNames;
	}, [nodeNames])

	const search = (event) => {
		let input = event.target.value.toLowerCase();
		let matches = [],
			matchesCarto = [];
		if (input.length > 1) {
			matches = listNames.filter(node => node.name && node.name.toLowerCase().match(input));
			matchesCarto = cartoNodes.filter(node => node.name && node.name.toLowerCase().match(input));
			setOpenSuggestions(true);
		}
		setSuggestions(matches.filter((match) => !cartoNodes.find((input) => input.name === match.name && input.type === match.type)));
		setSuggestionsCarto(matchesCarto);
	};

	const handleChange = (event) => {
		setQuery(event.target.value);
	};

	const launch = (e) => {
		e.preventDefault();
		setQuery("");
		setSuggestions([]);
		// setCode(13);
	};

	// ========================================

	var nodesArr = [];
	props.allRelations &&
		props.allRelations.forEach((item) => {
			item.nodes.forEach((node) => {
				nodesArr.push(node);
			});
		});

	// const nodes = removeDuplicateObjectFromArray(nodesArr, "id").sort(
	// 	(a, b) => b.id - a.id,
	// );

	// =================================

	var linksArr = [];
	props.allRelations &&
		props.allRelations.forEach((item) => {
			item.links.forEach((link) => {
				linksArr.push(link);
			});
		});

	const links = linksArr.sort((a, b) => a.id - b.id);

	// =================================

	// eslint-disable-next-line array-callback-return
	links.map((link) => {
		if (
			(inputFields[0] === link.source || inputFields[0] === link.target) &&
			inputFields.length < 2
		) {
			suggestions.push(link.type);
		}
	});

	// ===============================
	// Actions pour recupérer les informations du noeud indiquer en input.

	// let nodesId;

	// if ((inputFields.length > 0 && !props.node) || (props.node && props.node.length !== inputFields.length)) {
	// 	let ids = [];
	// 	inputFields.forEach((element) => {
	// 		let id = props.nodeNames && props.nodeNames.filter((node) => node.name === element);
	// 		if (id) {
	// 			ids.push(id[0].id);
	// 		}
	// 	})
	// 	nodesId = [...new Set(ids)];
	// }
	
	
	if (props.node) {
		dataFromInput = props.node;
		type = props.node[0].type;
	}
	// else if (props.relationsNode) {
		// 	dataFromInput = props.relationsNode;
		// }
		
		const propsMapNodeRelation = props.mapNodeRelations;
		const propsMapNode = props.mapNode;

	const cleanSuggestions = (res) => {
		setQuery("");
		setSuggestions([]);
		setSuggestionsCarto([]);
		inputFields.push(res);
		if ((inputFields.length > 0 && !props.node) ||
			(props.node && props.node.length !== inputFields.length) ||
			(props.node && props.node.length === inputFields.length && inputFields.length === 1 && props.node[0].name !== inputFields[0].name)) {
			let ids = [];
			inputFields.forEach((element) => {
				let id = props.nodeNames && props.nodeNames.filter((node) => node.name === element.name && node.type === element.type);
				if (id) {
					ids.push(id[0].id);
				}
			})
			const nodesId = [...new Set(ids)];
			propsMapNode(nodesId);
		}
	};

	const cleanSuggestionsCarto = (res) => {
		setQuery("");
		setSuggestions([]);
		setSuggestionsCarto([]);
		if (cartoNodes.length > 0) {
			setSuggestionSelect(cartoNodes.find(node => node.name === res));
		}
	}

	// =============================================

	const propsGetRelationInfo = props.getRelationInfo;

	useEffect(() => {
		propsGetRelationInfo(type);
	}, [propsGetRelationInfo, type]);

	const componentRelationNumber =
		props.componentRelationNumber &&
		props.componentRelationNumber[0].number.low;

	const resetGraph = () => {
		setInputFields([]);
		setSuggestions([]);
		setSuggestionsCarto([]);
		setQuery("");
		props.resetMap();
	}

	const removeNodeName = (names) => {
		let fields = inputFields.filter((field) => !names.includes(field));
		setInputFields(fields);
	}

	//  console.log(props.lastComponents);
	//  console.log(dataFromInput);
	//  console.log(props.relationsNode);
	//  console.log(inputFields);

	// ========================================

	return (
		<div className='container--map--page'>
			<div className='container--inputbox'>
				{inputFields && (
					<InputBox
						inputFields={inputFields}
						query={query}
						handleChange={handleChange}
						search={search}
						launch={launch}
						openFilter={openFilter}
					/>
				)}

				{openSuggestions && (
					<div className="suggestionModal">
						<SuggestionModal
							ref={ref}
							setOpenSuggestions={setOpenSuggestions}
							suggestions={suggestions}
							settings={props.settings}
							componentNames={componentName}
							cleanSuggestions={cleanSuggestions}
							position='left'
						/>
						<SuggestionModal
							ref={ref2}
							setOpenSuggestions={setOpenSuggestions}
							suggestions={suggestionsCarto}
							settings={props.settings}
							cleanSuggestions={cleanSuggestionsCarto}
							position='right'
						/>
					</div>
				)}
			</div>
			<div className='GraphContainer'>
				{props.settings ?
				<GraphTest2
					width={window.innerWidth}
					height={window.innerHeight}
					settings={props.settings}
					updateSettings={props.updateSettings}
					radius={50}
					dataFromInput={dataFromInput}
					relationsNode={props.relationsNode}
					mapNodeRelation={propsMapNodeRelation}
					popupCreate={props.popupCreate}
					setPopupCreate={props.setPopupCreate}
					nodeNames={props.nodeNames}
					popupDelete={props.popupDelete}
					setPopupDelete={props.setPopupDelete}
					setPopupUpdate={props.setPopupUpdate}
					popupUpdate={props.popupUpdate}
					addRelation={props.addRelation}
					relations={props.relations}
					components={props.components}
					info={props.relationInfo}
					deleteComponent={props.deleteComponent}
					deleteRelation={props.deleteRelation}
					seeRelations={props.seeRelations}
					componentRelationList={props.componentRelationList}
					componentRelationNumber={componentRelationNumber}
					getComponentRelationNumber={props.getComponentRelationNumber}
					lastRelations={props.lastRelations}
					resetGraph={resetGraph}
					setOpenFilter={setOpenFilter}
					removeNodeName={removeNodeName}
					relationTypes={props.relationTypes}
					getRelationTypes={props.mapNodeRelationsTypes}
					mapNodeRelationsByType={props.mapNodeRelationsByType}
					suggestionSelect={suggestionSelect}
					setSuggestionSelect={setSuggestionSelect}
					mapAllNodesByType={props.mapAllNodesByType}
					mapAllRelationsByType={props.mapAllRelationsByType}
					savedCartos={props.savedCartos}
					mapSaveCarto={props.mapSaveCarto}
					setCartoNodes={setCartoNodes}
					mapUpdateCarto={props.mapUpdateCarto}
				/> : null}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	components: state.componentReducer.components,
	componentInfo: state.componentReducer.componentInfo,
	relations: state.relationReducer.relations,
	nodeNames: state.componentReducer.nodeNames,
	relationsNode: state.mapReducer.relationsNode,
	allRelations: state.mapReducer.allRelations,
	node: state.mapReducer.node,
	relationInfo: state.relationReducer.relationInfo,
	lastRelations: state.relationReducer.lastRelations,
	componentRelationList: state.componentReducer.componentRelationList,
	componentRelationNumber: state.componentReducer.componentRelationNumber,
	lastComponents: state.componentReducer.lastComponents,
	relationTypes: state.mapReducer.relationTypes,
	savedCartos: state.mapReducer.savedCartos,
});

const mapDispatchToProps = (dispatch) => ({
	getComponents: () => dispatch(getComponents()),
	getComponentInfo: (type) => dispatch(getComponentInfo(type)),
	getRelations: () => dispatch(getRelations()),
	getNodeNames: () => dispatch(getNodeNames()),
	mapNodeRelationsTypes: (componentsId) => dispatch(mapNodeRelationsTypes(componentsId)),
	mapNodeRelationsByType: (componentsId, relationType) => dispatch(mapNodeRelationsByType(componentsId, relationType)),
	mapNode: (nodeId) => dispatch(mapNode(nodeId)),
	mapNodeRelations: (componentId, deletedNodes) => dispatch(mapNodeRelations(componentId, deletedNodes)),
	getRelationInfo: (type) => dispatch(getRelationInfo(type)),
	deleteComponent: (componentType, componentId) =>
		dispatch(deleteComponent(componentType, componentId)),
	seeRelations: (componentId) => dispatch(seeRelations(componentId)),
	deleteRelation: (relationId) => dispatch(deleteRelation(relationId)),
	getComponentRelationNumber: (componentType, rowName) =>
		dispatch(getComponentRelationNumber(componentType, rowName)),
	resetMap: () => dispatch({type: "RESET_MAP", payload: []}),
	resetNode: () => dispatch({type: "RESET_NODE", payload: []}),
	mapAllNodesByType: (type, nodes) => dispatch(mapAllNodesByType(type, nodes)),
	mapAllRelationsByType: (type) => dispatch(mapAllRelationsByType(type)),
	mapSaveCarto: (token, carto) => dispatch(mapSaveCarto(token, carto)),
	mapLoadCarto: (token) => dispatch(mapLoadCarto(token)),
	mapUpdateCarto: (carto, token) => dispatch(mapUpdateCarto(carto, token))
});

const MapZone = connect(mapStateToProps, mapDispatchToProps)(MapZoneComponent);

export { MapZone };
