export const createSVG = () => {
    var svgHtml = document.getElementById("graph");
    var svgData = new XMLSerializer().serializeToString(svgHtml.firstChild.firstChild);
    const svgheight = svgHtml.offsetHeight;
    const svgwidth = svgHtml.offsetWidth;
    const svg = {
        data: svgData,
        svgheight: svgheight,
        svgwidth: svgwidth
    }
    return svg;
}

export const downloadSVG = () => {
    var svgHtml = document.getElementById("graph");
    var svgData = new XMLSerializer().serializeToString(svgHtml);
    var svgBlob = new Blob([svgData], {type:"image/svg+xml;charset=utf-8"});
    var svgUrl = URL.createObjectURL(svgBlob);
    var downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = "graph.svg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}