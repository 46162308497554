import React, { useEffect } from "react";
import { connect } from "react-redux";

import { UpdateComponent, UpdateRelation } from "..";

import {
	addComponent,
	getPropertyListComponent,
	updateComponent,
} from "../../action/componentAction";
import {
	getPropertyListRelation,
	updateRelation,
} from "../../action/relationAction";

import "./Popup.css";

function PopupUpdateComponent(props) {
	let propsGetPropertyList;

	if (props.componentPage) {
		propsGetPropertyList = props.getPropertyListComponent;
	} else if (props.relationPage) {
		propsGetPropertyList = props.getPropertyListRelation;
	}

	useEffect(() => {
		propsGetPropertyList(props.type);
	}, [propsGetPropertyList, props.type]);

	return (
		<div className='container--popup'>
			<div className='background--popup'></div>
			{props.componentPage ? (
				<UpdateComponent
					type={props.type}
					infoUpdate={props.infoUpdate}
					info={props.info}
					setPopupUpdate={props.setPopupUpdate}
					updateComponent={props.updateComponent}
					propertiesListColumnComponent={props.propertiesListColumnComponent}
					components={props.components}
					addComponent={props.addComponent}
					updateNode={props.updateNode}
				/>
			) : (
				<UpdateRelation
					type={props.type}
					nodeNames={props.nodeNames}
					infoUpdate={props.infoUpdate}
					setPopupUpdate={props.setPopupUpdate}
					updateRelation={props.updateRelation}
					propertiesListColumnRelation={props.propertiesListColumnRelation}
				/>
			)}
		</div>
	);
}
const mapStateToProps = (state) => ({
	propertiesListColumnComponent:
		state.componentReducer.propertiesListColumnComponent,
	propertiesListColumnRelation:
		state.relationReducer.propertiesListColumnRelation,
});

const mapDispatchToProps = (dispatch) => ({
	addComponent: (newComponent, inputFields) =>
		dispatch(addComponent(newComponent, inputFields)),
	updateComponent: (componentToUpdate) =>
		dispatch(updateComponent(componentToUpdate)),
	updateRelation: (dataToUpdate) => dispatch(updateRelation(dataToUpdate)),
	getPropertyListComponent: (componentType) =>
		dispatch(getPropertyListComponent(componentType)),
	getPropertyListRelation: (componentType) =>
		dispatch(getPropertyListRelation(componentType)),
});

const PopupUpdate = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PopupUpdateComponent);

export { PopupUpdate };
