import React from "react";

function Cartridge(props) {
	const cartridgeCSS = {
		padding: "0px 10px",
		margin: "5px",
		fontSize: "15px",
		fontFamily: "Betm Rounded Regular",
		borderRadius: "10px",
		cursor: "pointer",
		display: "flex",
		flexdirection: "row",
		justifyContent: "center",
		alignItems: "center",
		color: props.color,
		border: `2px solid ${props.color}`,
	};

	const cartridgeSelected = {
		...cartridgeCSS,
		color: "white",
		backgroundColor: props.color,
	};

	const changeBackGround = (e) => {
		e.target.style.backgroundColor = props.color;
		e.target.style.color = "#fff";
	};

	const leaveHovering = (e) => {
		props.type === props.name
			? (e.target.style.backgroundColor = props.color)
			: (e.target.style.backgroundColor = "transparent");
		props.type === props.name
			? (e.target.style.color = "#FFF")
			: (e.target.style.color = props.color);
	};

	return (
		<div
			style={props.type === props.name ? cartridgeSelected : cartridgeCSS}
			onClick={() => props.setType(props.name)}
			onMouseOver={changeBackGround}
			onMouseLeave={leaveHovering}>
			{props.name} {props.number ? '(' + props.number + ')' : ''}
		</div>
	);
}

export { Cartridge };
