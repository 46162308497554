import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
	Cartridge,
	ReactTable,
	PopupDelete,
	PopupCreate,
	PopupUpdate,
	Loader,
} from "../components";

import {
	getComponents,
	getComponentInfo,
	seeRelations,
	deleteComponent,
	updateComponent,
} from "../action/componentAction";

import { sortedFunction } from "../utils/Function";
import { componentColor } from "../utils/color";

import "bootstrap/dist/css/bootstrap.css";
import "../css/Components.css";
import "../components/Table/Table.css";
// import { updateRelation } from "../action/relationAction";
import addLogo from "../assets/table/add.svg";
import { ReactComponent as ExportLogo } from '../assets/img/file-export-solid.svg';
import { ExportToCsv } from "export-to-csv";
import { PopupClone } from "../components/Popup/PopupClone";

function ComponentsComp(props) {
	const [type, setType] = useState("");
	const [componentId, setComponentId] = useState();
	const [infoUpdate, setInfoUpdate] = useState();
	const [dataFiltered, setDataFiltered] = useState();
	const update = props.propertiesListColumnComponent;
	const componentPage = true;

	const propsGetComponents = props.getComponents;

	useEffect(() => {
		propsGetComponents();
	}, [propsGetComponents]);

	const propsGetRowInfo = props.getComponentInfo;

	useEffect(() => {
		propsGetRowInfo(props.type);
	}, [propsGetRowInfo, props.type]);
	
	
	const components = props.components && props.components;
	const componentInfo = props.componentInfo && props.componentInfo;
	const componentRelationNumber =
	props.componentRelationNumber &&
	props.componentRelationNumber[0].number.low;
	
	const renderComponentsList = () => {
		return (
			components &&
			components.sort((a, b) => {
				return a.name[0].localeCompare(b.name[0]);
			}).map((component, i) => {
				return (
					<Cartridge
						key={i}
						name={component.name[0]}
						number={component.number}
						color={componentColor}
						setType={setType}
						type={type}
					/>
				);
			})
		);
	};

	const renderPopupUpdate = () => {
		if (props.popupUpdate) {
			return (
				<PopupUpdate
					type={type}
					infoUpdate={infoUpdate}
					info={componentInfo}
					setPopupUpdate={props.setPopupUpdate}
					componentPage={componentPage}
					components={props.components}
				/>
			);
		}
	};

	const exportcsv = () => {
		const options = { 
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalSeparator: '.',
			useTextFile: false,
			useBom: true,
			useKeysAsHeaders: true,
		  };
		const csvExporter = new ExportToCsv(options);
		const dataOnOrder = dataFiltered.sort((a, b) => {
			if (Object.keys(a).length < Object.keys(b).length)
				return 1;
			if (Object.keys(a).length > Object.keys(b).length)
				return -1;
			return 0;
		});
		const header = Object.keys(dataOnOrder[0]);
		dataFiltered.forEach(data => {
			header.forEach(key => {
				if (!data[key])
					data[key] = '';
			})
			Object.keys(data).forEach(key => {
				if (key === "Created_year_low" ||
					key === "Created_month_low" ||
					key === "Created_day_low" ||
					key === "Created_hour_low" ||
					key === "Created_minute_low" ||
					key === "Created_second_low" ||
					key === "Created_nanosecond_low" ||
					key === "Updated_year_low" ||
					key === "Updated_month_low" ||
					key === "Updated_day_low" ||
					key === "Updated_hour_low" ||
					key === "Updated_minute_low" ||
					key === "Updated_second_low" ||
					key === "Updated_nanosecond_low" ||
					key === "Updated" ||
					key === "UpdatedBy" ||
					key === "Created" ||
					key === "CreatedBy")
					delete data[key];
			});
		});
		const value = csvExporter.generateCsv(dataOnOrder, true);
		const file = new Blob([value], {type: 'text/plain'});
		var downloadLink = document.createElement("a");
		downloadLink.href = URL.createObjectURL(file);
		downloadLink.download = "composants.csv";
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	}

	return (
		<div className='big--container'>
			<div className='container--component--page'>
				<div className='container--button--add'>
					<h1 className='page--title' style={{ color: componentColor }}>
						COMPOSANTS
					</h1>
					<img
						data-tip="Ajouter un composant"
						src={addLogo}
						alt='Ajouter'
						className='add--logo'
						onClick={() => props.setPopupCreate(true)}
					/>
					{
						componentInfo && type ?
						<ExportLogo className='add--logo' style={{fill: componentColor}} onClick={() => exportcsv()} title="Exporter les composants"/>
						: null
					}
				</div>
				{props.popupDelete && (
					<PopupDelete
						componentId={componentId}
						componentType={type}
						name={props.rowName}
						relationNumber={componentRelationNumber}
						deleteComponent={props.deleteComponent}
						componentRelationList={props.componentRelationList}
						seeRelations={props.seeRelations}
						setPopupDelete={props.setPopupDelete}
					/>
				)}
				{props.popupClone && (
					<PopupClone
						type={type}
						infoUpdate={infoUpdate}
						info={componentInfo}
						setPopupClone={props.setPopupClone}
						componentPage={componentPage}
						components={props.components}
					/>
				)}
				{props.popupCreate && (
					<PopupCreate
						type={type}
						info={componentInfo}
						components={components}
						setPopupCreate={props.setPopupCreate}
						componentPage={componentPage}
					/>
				)}
				{update && renderPopupUpdate()}
				{components ? (
					<div className='component'>{renderComponentsList()}</div>
				) : (
					<Loader />
				)}
				{componentInfo && type && (
					<ReactTable
						type={type}
						rowInfo={componentInfo}
						columns={sortedFunction(componentInfo)}
						getRowInfo={props.getComponentInfo}
						setComponentId={setComponentId}
						setPopupDelete={props.setPopupDelete}
						setPopupCreate={props.setPopupCreate}
						setPopupUpdate={props.setPopupUpdate}
						setPopupClone={props.setPopupClone}
						setInfoUpdate={setInfoUpdate}
						setDataFiltered={setDataFiltered}
						componentPage={componentPage}
						updateComponent={props.updateComponent}
					/>
				)}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	components: state.componentReducer.components,
	componentInfo: state.componentReducer.componentInfo,
	rowName: state.dataReducer.rowName,
	componentRelationNumber: state.componentReducer.componentRelationNumber,
	componentRelationList: state.componentReducer.componentRelationList,
	propertiesListColumnComponent:
		state.componentReducer.propertiesListColumnComponent,
});

const mapDispatchToProps = (dispatch) => ({
	getComponents: () => dispatch(getComponents()),
	getComponentInfo: (type) => dispatch(getComponentInfo(type)),
	seeRelations: (componentId) => dispatch(seeRelations(componentId)),
	deleteComponent: (componentType, componentId) =>
		dispatch(deleteComponent(componentType, componentId)),
	updateComponent: (componentToUpdate) => dispatch(updateComponent(componentToUpdate))
});

const Components = connect(mapStateToProps, mapDispatchToProps)(ComponentsComp);

export { Components };
