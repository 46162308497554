import * as d3 from "d3";
import { createPie } from "../../pie/createPie";
import { getNodeRadius } from "../get/getNodeRadius";

	export const nodeClick = (nodes, settings, handleContextMenu, addSelectedElement, removeSelectedElement, deleteNode, deployAll) => (event, d) => {
		nodes
			.selectAll("g").lower()
		if (event.shiftKey) {
			if (!d3.select(event.target.parentNode).classed("selected")) {
				d3.select(event.target.parentNode)
					.classed("selected", true)
					addSelectedElement(d);
			} else {
				d3.select(event.target.parentNode)
					.classed("selected", false)
					removeSelectedElement(d)
			}
		} else if (!event.shiftKey) {
			let g = event.currentTarget.parentNode, 
			parent = event.target.parentNode,
			isPieOpen = false

			if (d3.select(event.target.parentNode).classed('wrap')) {
				parent = event.target.parentNode.parentNode;
			}

			if (g) {
				isPieOpen = d3.select(g).classed("pie-open");
			}
			
			d3.selectAll("g.pie-open").classed("pie-open", false);
			
			d3.selectAll(".path-btn__wrapper").remove()
			if(!isPieOpen && g){
				createPie(parent, event.target.parentNode.__data__.x, event.target.parentNode.__data__.y, getNodeRadius(d.type, settings), 4, d, handleContextMenu, deleteNode, deployAll)
				d3.select(parent).raise();
			}
		} else if (!d3.select(event.target.parentNode).classed("selected")) {
			d3.select(event.target.parentNode)
				.classed("selected", true)
				addSelectedElement(d);
		}	else {
			d3.select(event.target.parentNode)
				.classed("selected", false)
				removeSelectedElement(d)
		}
	}