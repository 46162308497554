import { getNodeRadius } from "./nodes/get/getNodeRadius";

	export const tick = (links, nodes, settings) => {
		links
			.selectAll("g.link > path.inner")
			.attr("d", d => tickPath(d, settings, false));

		links
			.selectAll("g.link > path.hiddenPath")
			.attr("d", d => tickPath(d, settings, true))
			.style("visibility", "hidden");

		nodes
			.selectAll("g .node:not(.path-btn__slice) > circle")
			.attr("cx", (d) => d.x)
			.attr("cy", (d) => d.y);

		nodes
			.selectAll("text")
			.attr("x", (d) => d.x)
			.attr("y", (d) => d.y);

		nodes.selectAll("text > tspan")
			.attr("x", (d) => d.x)
			.attr("y", (d) => d.y);
	}

    const tickPath = (d, settings, hidden) => {
		var dx = (d.target.x - d.source.x),
			dy = (d.target.y - d.source.y),
			rdx = (d.source.x - d.target.x),
			rdy = (d.source.y - d.target.y),
			dr = Math.sqrt(dx * dx + dy * dy),
			arc = ((dr * d.maxSameHalf) * 0.5),
			targetSize = getNodeRadius(d.target.type, settings),
			sourceSize = getNodeRadius(d.source.type, settings),
			targetangle = dx < 0 ? Math.atan(dy/dx) : Math.atan(dy/dx) + Math.PI,
			sourceangle = dx < 0 ? Math.atan(rdy/rdx) + Math.PI : Math.atan(rdy/rdx);
			let space = d.sameTotal > 3 ? (60 / d.sameTotal) : 5;
			let deg = 0;
			if (d.sameIndex % 2 === 0) {
				deg = space * d.sameIndex;
				d.sameArcDirection = 1;
				if (d.from) {
					d.sameArcDirection = 0;
				}
			} else if (d.sameTotal === 2) {
				deg = space * (d.sameIndex === 1 ? 1 : d.sameIndex - 1) * -1;
				d.sameArcDirection = 0;
				if (d.from) {
					d.sameArcDirection = 1;
				}
			} else {
				deg = space * (d.sameIndex - 1) * -1;
				d.sameArcDirection = 0;
				if (d.from) {
					d.sameArcDirection = 1;
				}
			}
			deg = d.from ? -deg : deg;
		if (d.sameUneven && d.sameIndex === 1) {
			deg = 0;
			arc = 0;
		}
		let	offset = deg * (Math.PI / 180);
		let	targetx = d.target.x + targetSize * Math.cos(targetangle + offset);
		let	targety = d.target.y + targetSize * Math.sin(targetangle + offset);
		let	sourcex = d.source.x + sourceSize * Math.cos(sourceangle - offset);
		let	sourcey = d.source.y + sourceSize * Math.sin(sourceangle - offset);
		// Si un composant et lie a lui meme crash
		// cette protection envoi le lien et son texte hors de l'ecran
		// mettre en place une jolie boucle comme browser dans ce cas
		targetx = isNaN(targetx) ? -5000 : targetx;
		targety = isNaN(targety) ? -5000 : targety;
		sourcex = isNaN(sourcex) ? -5000 : sourcex;
		sourcey = isNaN(sourcey) ? -5000 : sourcey;
		if (hidden && targetangle < (90 * Math.PI/180))
			return "M " + targetx + ", " + targety + " A " + arc + ", " + arc + " 0 0, " + (d.sameArcDirection === 0 ? 1 : 0) + " " + sourcex + ", " + sourcey;
		return "M " + sourcex + ", " + sourcey + " A " + arc + ", " + arc + " 0 0, " + d.sameArcDirection + " " + targetx + ", " + targety;
		}