import { getLinkColor } from "../links/get/getLinkColor";

	export const updateMarkers = (data, markers, settings) => {
		markers.selectAll("marker")
			.data(data)
			.join(
				(enter) => {
					enter.append("marker")
					.attr("id", (d) => {
						return `arrow-${d.id}`;
					})
					.attr("viewBox", "-5 -5 10 10")
					.attr("refX", 5)
					.attr("refY", 0)
					.attr("markerWidth", 5)
					.attr("markerHeight", 5)
					.attr('markerUnits', 'strokeWidth')
					.attr("orient", "auto")
					.append("path")
					.attr("fill", (d) => getLinkColor(d.type, settings))
					.attr("d", 'M 0,0 m -5,-5 L 5,0 L -5,5 Z');
				},
				(update) => {
					update.selectAll("path")
					.attr("fill", (d) => getLinkColor(d.type, settings))
				}
			)
			
	}