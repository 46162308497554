import React from "react";
import { ReactComponent as Clone } from '../../assets/img/clone-solid.svg';
import { componentColor, relationColor } from "../../utils/color";

export const CloneButton = (props) => {
	const clickOnCloneComponent = (row) => {
		props.setPopupClone(true);
		props.setInfoUpdate(row);
		props.getPropertyListComponent(props.type);
	};

	const clickOnCloneRelation = (row) => {
		props.setPopupClone(true);
		props.setInfoUpdate(row);
		props.getPropertyListRelation(props.type);
	};

	return (
		<Clone
			className='update--logo'
			style={{fill: props.componentPage ? componentColor : relationColor}}
			alt='clone'
			onClick={() => {
				props.componentPage
					? clickOnCloneComponent(props.row.original, props.type)
					: clickOnCloneRelation(props.row.original, props.type);
			}}
			data-tip='Cloner'
		/>
	);
};
