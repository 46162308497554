import React, { useState } from "react";

import "./PopupUpdateSettings.css";
import "../../../Form/Form.css"
import ButtonStyle from "../../../../styled-components/ButtonStyle";
import { componentColor, cancelColor } from "../../../../utils/color";

function PopupUpdateSettings(props) {
    const [content, setContent] = useState(props.row.propertie);
    const [error, setError] = useState(true);
    const handleClose = (e) => {
        e.preventDefault();
        props.setShow(false);
    }

    const handleDoublon = (value) => {
        setContent(value);
        if (value && props.values.find(element => element.propertie === value) !== undefined && !error) {
            setError(true);
        } else {
            setError(false);
        }
    }

    const updateSettings = () => {
        props.updatePropertie(props.row.propertie, content);
    }

	return (
		<div className='container--popupSettings'>
            <div className='background--popupSettings' onClick={handleClose}></div>
            <form className='container--form'>
                <h2>Mettre a jour les propriete pour {props.row.propertie}</h2>
                <h3>Ce changement impactera {props.row.number} {props.row.type}</h3>
                <div>
                    <h3>Nouveau nom de la propriete</h3>
                    <div className='group--input' style={{width: '100%'}}>
                        <input
                            type='text'
                            name='propertie'
                            value={content}
                            onChange={(event) => handleDoublon(event.target.value)}
                        />
                    </div>
                    { error ? <span style={{color: 'red'}}>Propriete deja prise !</span> : null }
                </div>
                <div className='container--form--button'>
                    <ButtonStyle
                        color={cancelColor}
                        onClick={() => props.setShow(false)}>
                        ANNULER
                    </ButtonStyle>
                    <ButtonStyle disabled={error} color={componentColor} onClick={() => updateSettings()}>
                        CONFIRMER
                    </ButtonStyle>
                </div>
            </form>
		</div>
	);
}

export { PopupUpdateSettings };
