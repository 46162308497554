import * as d3 from "d3";
	
	export const createPie = (currNode, cx, cy, nodeR, slices, dParent, handleContextMenu, deleteNode, deployAll) => {
        let parentNode, fromAngle, toAngle,
        fromCoordX, fromCoordY, 
        toCoordX, toCoordY, 
        path, d, slicesBtn, 
        r;
        slicesBtn = slices * 2; 


        if (nodeR < 30)
            r = 50
        r = nodeR * 2;
        parentNode = d3.select(currNode)
        const pathBtns = parentNode
        .insert("g", ":first-child")
        .datum({xTransform: 0, yTransform: 0, parentId: parentNode.datum().id})
        .classed("path-btn path-btn__wrapper", true)
        .attr('stroke-width',2)
        for (let i = 0; i < slicesBtn; i++) {
            if(i % 2 === 0) {
                fromAngle = (i / 2) * 360 / slices;
                toAngle = ((i / 2) + 1) * 360 / slices;

                fromCoordX = cx + (r * Math.cos(fromAngle * Math.PI / 180));
                fromCoordY = cy + (r * Math.sin(fromAngle * Math.PI / 180));
                toCoordX = cx + (r * Math.cos(toAngle * Math.PI / 180));
                toCoordY = cy + (r * Math.sin(toAngle * Math.PI / 180));

                path = pathBtns.append("path")
                .classed("path-btn path-btn__slice open", true);

                
                d = 'M' + cx + ',' + cy + ' L' + fromCoordX + ',' + fromCoordY + ' A' + r + ',' + r + ' 0 0,1 ' + toCoordX + ',' + toCoordY + 'z';

                path.attr('d', d)

                //premier bouton
                if (i / 2 === 0)
                    path.on("click", handleContextMenu);
                //second bouton
                else if (i / 2 === 1)
                    path.on("click", (event, d) => {
                        dParent.fx = null;
                        dParent.fy = null;
                    });
                //troisieme bouton
                else if (i / 2 === 2)
                    path.on("click", (event, d) => {
                        deployAll([d.parentId]);
                    });
                //quatrieme bouton
                else if (i / 2 === 3)
                    path.on("click", (event, d) => {
                        deleteNode([d.parentId]);
                    });
            } 
            else {
                const buttonAngle = i * 360 / slicesBtn;
                
                const buttonX = dParent.x + ((nodeR + (r - nodeR)/2) * Math.cos(buttonAngle * Math.PI / 180));
                const buttonY = dParent.y + ((nodeR + (r - nodeR)/2) * Math.sin(buttonAngle * Math.PI / 180));

                if (i === 1) {
                    pathBtns.append("g")
                    .attr("transform-origin", `${buttonX} ${buttonY}`)
                    .attr("transform", (d) => `scale(0.02)`)
                    .append("use")
                    .attr("href", "#dot-icon")
                    .classed('button-btn_slice', true)
                    .attr("cx", buttonX) // need to find a better way to position it
                    .attr("cy", buttonY)
                    .on("click", handleContextMenu)
                }
                //second bouton
                else if (i === 3) {
                    pathBtns.append("g")
                    .attr("transform-origin", `${buttonX} ${buttonY}`)
                    .attr("transform", (d) => `scale(0.02)`)
                    .append("use")
                    .attr("href", "#lock-icon")
                    .classed('button-btn_slice', true)
                    .attr("cx", buttonX) // need to find a better way to position it
                    .attr("cy", buttonY)
                    .on("click", (event, d) => {
                        dParent.fx = null;
                        dParent.fy = null;
                    })
                }
                //troisieme bouton
                else if (i === 5) {
                    pathBtns.append("g")
                    .attr("transform-origin", `${buttonX} ${buttonY}`)
                    .attr("transform", (d) => `scale(0.02)`)
                    .append("use")
                    .attr("href", "#expand-icon")
                    .classed('button-btn_slice', true)
                    .attr("cx", buttonX) // need to find a better way to position it
                    .attr("cy", buttonY)
                    .on("click", (event, d) => {
                        deployAll([d.parentId]);
                    })
                }
                //quatrieme bouton
                else if (i === 7) {
                    pathBtns.append("g")
                    .attr("transform-origin", `${buttonX} ${buttonY}`)
                    .attr("transform", (d) => `scale(0.02)`)
                    .append("use")
                    .attr("href", "#eye-icon")
                    .classed('button-btn_slice', true)
                    .attr("cx", buttonX) // need to find a better way to position it
                    .attr("cy", buttonY)
                    .on("click", (event, d) => {
                        deleteNode([d.parentId]);
                    })
                }
            }
        
        }
    }