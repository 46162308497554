import styled from "styled-components";

const ButtonStyle = styled.button.attrs(({ disabled }) => ({
	disabled: disabled,
	type: "submit",
	value: "Submit",
}))`
	height: 50px;
	padding: 0px 10px;
	margin: 5px 10px;
	font-size: 25px;
	border-radius: 20px;
	background-color: transparent;
	cursor: pointer;
	font-family: "Betm Rounded Medium";
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: ${(props) => props.color};
	border: ${(props) => props.color};

	:hover {
		color: white;
	text-decoration: none;
    background-color: ${(props) => props.color};
`;

export default ButtonStyle;
