
import { ExportToCsv } from 'export-to-csv';

		export const downloadCSV2 = (nodes2, links2) => {
			if (nodes2.length !== 0) {
				let allkeys = [];
				const nodes = nodes2.map(element => {
					const keys = Object.keys(element).filter(prop =>
						prop !== 'id' &&
						prop !== 'index' &&
						prop !== 'name' &&
						prop !== 'Created' &&
						prop !== 'Updated' &&
						prop !== 'y' &&
						prop !== 'x' &&
						prop !== 'fx' &&
						prop !== 'fy' &&
						prop !== 'vy' &&
						prop !== 'vx' &&
						prop !== 'cx' &&
						prop !== 'cy',);
					const filtered = {};
					keys.forEach(key => {
						if (!allkeys.includes(key)) {
							allkeys.push(key);
						}
						filtered[key] = element[key];
					})
					return filtered;
				});
				nodes2.forEach(node => {
					for (const key of allkeys) {
						if (!node[key] || node[key] === undefined) {
							node[key] = '';
						}
					}
				})
				const options = { 
					fieldSeparator: ',',
					quoteStrings: '"',
					decimalSeparator: '.',
					useTextFile: false,
					useBom: true,
					useKeysAsHeaders: true,
				  };
				const csvExporter = new ExportToCsv(options);
				const value = csvExporter.generateCsv(nodes, true);
				const file = new Blob([value], {type: 'text/plain'});
				let downloadLink = document.createElement("a");
				downloadLink.href = URL.createObjectURL(file);
				downloadLink.download = "graphNodes.csv";
				document.body.appendChild(downloadLink);
				downloadLink.click();
				document.body.removeChild(downloadLink);
			}
			if (links2.length !== 0) {
				let allkeys = [];
				const links = links2.map(element => {
					const keys = Object.keys(element).filter(prop =>
						prop !== 'index' &&
						prop !== 'id' &&
						prop !== 'source' &&
						prop !== 'target' &&
						prop !== 'data' &&
						prop !== 'sameIndex' &&
						prop !== 'sameTotal' &&
						prop !== 'sameLowerHalf' &&
						prop !== 'sameMiddleLink' &&
						prop !== 'sameUneven' &&
						prop !== 'sameTotalHalf' &&
						prop !== 'sameArcDirection' &&
						prop !== 'sameIndexCorrected' &&
						prop !== 'maxSameHalf' &&
						prop !== 'from',);
					const filtered = {};
					keys.forEach(key => {
						if (!allkeys.includes(key)) {
							allkeys.push(key);
						}
						filtered[key] = element[key];
					})
					filtered.source = element.source.Nom;
					filtered.target = element.target.Nom;
					filtered.sourceType = element.source.type;
					filtered.targetType = element.target.type;
					return filtered;
				});
				links2.forEach(link => {
					for (const key of allkeys) {
						if (!link[key] || link[key] === undefined) {
							link[key] = '';
						}
					}
				})
				const options = { 
					fieldSeparator: ',',
					quoteStrings: '"',
					decimalSeparator: '.',
					useTextFile: false,
					useBom: true,
					useKeysAsHeaders: true,
				  };
				const csvExporter = new ExportToCsv(options);
				const value = csvExporter.generateCsv(links, true);
				const file = new Blob([value], {type: 'text/plain'});
				let downloadLink = document.createElement("a");
				downloadLink.href = URL.createObjectURL(file);
				downloadLink.download = "graphLinks.csv";
				document.body.appendChild(downloadLink);
				downloadLink.click();
				document.body.removeChild(downloadLink);
			}
		}