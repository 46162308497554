import { getStrokeWidth } from "./get/getStrokeWidth";
import { getStrokeDasharray } from "./get/getStrokeDasharray";
import { getLinkColor } from "./get/getLinkColor";
import { getLinkText } from "./get/getLinkText";

	export const updateLinks = (data, links, settings, handleContextMenu) => {
		links
			.selectAll("g")
			.data(data, function (d) {
				return d.id;
			})
			.join(
				(enter) => {
					enter = enter.append("g").classed("link", true);
					enter
						.append("path")
						.classed("inner", true)
						.attr("id", (d) => `link-${d.id}`)
						.attr("stroke-width", (d) => getStrokeWidth(d.type, settings))
						.attr("stroke-dasharray", (d) => getStrokeDasharray(d.type, settings))
						.attr("marker-end", (d) => {
							return `url(#arrow-${d.id})`
						})
						.attr("stroke", (d) => getLinkColor(d.type, settings))

					
					enter
						.append("path")
						.classed("hiddenPath", true)
						.attr("id", (d) => `invisible-link-${d.id}`)
						.style("visibility", "hidden")

					enter
						.append("text")
						.classed("link-text", true)
						.append("textPath")
						.classed("link-text-path", true)
						.attr("xlink:href", (d) => `#invisible-link-${d.id}`)
						.attr("text-anchor", "middle")
						.attr("baseline-shift", "5px")
						.attr("startOffset", "50%")
						.attr("fill", (d) => getLinkColor(d.type, settings))
						.attr("stroke", (d) => getLinkColor(d.type, settings))
						.attr("strokeWidth", 1)
						.text((d) => getLinkText(d, settings))
						.on("click", handleContextMenu);

						enter.append("title").text((d) => {
							const title = Object.keys(d).filter(
								(prop) => 
								prop !== 'id' &&
								prop !== 'idOrigin' &&
								prop !== 'idTarget' &&
								prop !== 'origin' &&
								prop !== 'rowOrigin' &&
								prop !== 'rowTarget' &&
								prop !== 'from' &&
								prop !== 'index' &&
								prop !== 'maxSameHalf' &&
								prop !== 'source' &&
								prop !== 'sameIndex' &&
								prop !== 'sameTotal' &&
								prop !== 'sameUneven' &&
								prop !== 'sameTotalHalf' &&
								prop !== 'sameMiddleLink' &&
								prop !== 'sameLowerHalf' &&
								prop !== 'sameArcDirection' &&
								prop !== 'sameIndexCorrected' &&
								prop !== 'target',
								).sort((a, b) => a[0].localeCompare(b[0])).map((key) => {
									return `${key === 'type' ? 'Classe' : key }: ${d[key]}`;
								});
							return title.join('\n')
						});
				},
				(update) => {
					update.select("path.inner")
					.attr("stroke-width", (d) => getStrokeWidth(d.type, settings))
					.attr("stroke-dasharray", (d) => getStrokeDasharray(d.type, settings))
					.attr("stroke", (d) => getLinkColor(d.type, settings));

					update.select("path.hiddenPath")
					.style("visibility", "hidden")

					update.selectAll("text.link-text > textPath")
					.attr("fill", (d) => getLinkColor(d.type, settings))
					.text((d) => getLinkText(d, settings))
				},
				(exit) => {
					exit.remove();
				},
			);
	}