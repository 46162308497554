export const mapColor = "#4E8FCD";
export const relationColor = "#8758A0";
export const relationColorHover = "#8758A088";
export const componentColor = "#7079B9";
export const componentColorHover = "#7079B988";
export const settingsColor = "#95348B";
export const cancelColor = "#E7362D";
export const uploadColor = "#FFC404";
export const colors = [
    "rgb(255,224,129)",
    "rgb(201,144,192)",
    "rgb(247,151,103)",
    "rgb(87,199,227)",
    "rgb(241,102,103)",
    "rgb(217,200,174)",
    "rgb(141,204,147)",
    "rgb(236,181,201)",
    "rgb(76,142,218)",
    "rgb(86,148,128)"
];
