import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getRoleList, getUserList, addRole, updateRole, addUser, updateUser, deleteRole, deleteUser } from "../../../action/settingsAction";
import ButtonStyle from "../../../styled-components/ButtonStyle";
import RoleSettings from "./RoleSettings/RoleSettings";
import UserSettings from "./UserSettings/UserSettings";
import { PopupAddRole } from "./PopupAdd/PopupAddRole";
import { PopupAddUser } from "./PopupAdd/PopupAddUser";
import { useState } from "react";
import jwt_decode from "jwt-decode";

const AdminSettingsComponent = (props) => { 
	const [showAddRole, setShowAddRole] = useState(false);
	const [showAddUser, setShowAddUser] = useState(false);

	const user = sessionStorage.getItem("cool-jwt");
	const history = useHistory();

	const propsGetUserList = props.getUserList;
	const propsGetRoleList = props.getRoleList;

	useEffect(() => {
		if (user) {
			const decode = jwt_decode(user);
			if (decode.isAdmin) {
				propsGetUserList();
				propsGetRoleList();
			} else {
				history.push("/");
				window.location.reload(true);
			}
		} else {
			history.push("/");
			window.location.reload(true);
		}
	}, [propsGetUserList, propsGetRoleList, user]);

	return (
		<>
			{ showAddRole ? <PopupAddRole roleList={props.roleList} addRole={props.addRole} setShow={setShowAddRole}></PopupAddRole> : null}
			{ showAddUser ? <PopupAddUser userList={props.userList} addUser={props.addUser} setShow={setShowAddUser}></PopupAddUser> : null}
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}> 
				<h1>Administration</h1>
				<UserSettings userList={props.userList} roleList={props.roleList} deleteUser={props.deleteUser} updateUser={props.updateUser}></UserSettings>
				<ButtonStyle onClick={() => setShowAddUser(true)}>Ajouter un utilisateur</ButtonStyle>
				<RoleSettings nodes={props.nodes} links={props.links} roleList={props.roleList} deleteRole={props.deleteRole} updateRole={props.updateRole}></RoleSettings>
				<ButtonStyle onClick={() => setShowAddRole(true)}>Ajouter un role</ButtonStyle>
			</div>
		</>
	);
}

const adminStateToProps = (state) => ({
	userList: state.settingsReducer.userList,
	roleList: state.settingsReducer.roleList,
	nodes: state.componentReducer.components,
	links: state.relationReducer.relations,
})

const adminDispatchToProps = (dispatch) => ({
	getUserList: () => dispatch(getUserList()),
	getRoleList: () => dispatch(getRoleList()),
	addUser: (userData) => dispatch(addUser(userData)),
	updateUser: (userData, oldUserData) => dispatch(updateUser(userData, oldUserData)),
	addRole: (roleData) => dispatch(addRole(roleData)),
	updateRole: (roleData, oldRoleData) => dispatch(updateRole(roleData, oldRoleData)),
	deleteRole: (roleData) => dispatch(deleteRole(roleData)),
	deleteUser: (userData) => dispatch(deleteUser(userData))
})

const AdminSettings = connect(adminStateToProps, adminDispatchToProps)(AdminSettingsComponent)

export { AdminSettings };
