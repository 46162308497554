import React, { useMemo, useRef, useState } from "react";

import "./TableData.css";
import { ReactComponent as Add } from '../../assets/img/plus-circle-solid.svg';
import { componentColor, relationColor } from "../../utils/color";

function TableData(props) {
	const [rows, setRows] = useState([]);
	const [columns, setColumns] = useState([]);
	const [newProp, setNewProp] = useState(false);
	const [newPropName, setNewPropName] = useState('');
	const [cells, setCells] = useState(() => { 
		let newRowsCells = [];
		props.page.forEach((row) => {
			props.prepareRow(row);
			let newCells = [];
			row.cells.forEach((cell) => {
				newCells.push(cell);
			});
			newRowsCells.push(newCells);
		});
		return newRowsCells;
	});
	const formInv = useRef([]);

	useMemo(() => {
		let newColumns = [];
		props.headerGroups.forEach((column) => {
			newColumns.push(column);
		});
		setColumns(newColumns);
		setNewProp(false);
		setNewPropName('');
	}, [props.headerGroups]);

	useMemo(() => {
		let newRows = [];
		let newRowsCells = [];
		props.page.forEach((row) => {
			props.prepareRow(row);
			let newCells = [];
			row.cells.forEach((cell) => {
				newCells.push(cell);
			});
			newRows.push(row);
			newRowsCells.push(newCells);
		});
		setRows(newRows);
		setCells(newRowsCells);
	}, [props.page]);

	const handleSubmit = (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const index = event.target.id;
		const original = rows[index].original;
		let noChange = 0;
		let changes = 0;
		for (let [key, value] of formData.entries()) {
			changes++;
			if (key === 'add') {
				if (value !== '' && newPropName !== '') {
					original[newPropName] = value;
				} else {
					noChange++;
				}
			} else {
				if ((value.localeCompare(original[key] ? original[key].replace(/[\n]+/g, '') : original[key]) !== 0 && value !== '')
					|| ((value === '' && original[key] !== '' && original[key] !== undefined) && key !== 'Nom' && key !== 'type')) {
					console.log('---')
					console.log(value);
					console.log('---')
					console.log(original[key] ? original[key].replace(/[\n]+/g, '') : original[key]);
					console.log('---')
					original[key] = value;
				} else {
					noChange++;
				}
			}
		}
		if (props.componentPage && noChange !== changes) {
			console.log(rows[index].original);
			original['name'] = rows[index].original.Nom;
			original['type'] = props.type;
			delete original.Nom;
			props.updateComponent(original);
		} else if (noChange !== changes) {
			props.updateRelation(original);
		}
		setNewProp(false);
		setNewPropName('');
	}

	const handleCells = (e, rowid, cellid) => {
		const newcells = [...cells];
		newcells[rowid][cellid].value = e.target.value;
		setCells(newcells);
	}

	return (
		<div
			className={
				props.componentPage
					? "tableWrap componentPageColor"
					: "tableWrap relationPageColor"
			}>
			<div
				{...props.getTableProps()}
				className='table sticky'>
				<div className='header'>
					{columns && columns.map((headerGroup) => (
						<div {...headerGroup.getHeaderGroupProps()} style={{...headerGroup.getHeaderGroupProps().style, ...{width: newProp ? parseInt(headerGroup.getHeaderGroupProps().style.width) + 61 + 'px' : headerGroup.getHeaderGroupProps().style.width}}} className='tr'>
							{headerGroup.headers.map((column) => {
								if (column.id === 'add') {
									return (
										<>
										{newProp ? 
											<div
												{...column.getHeaderProps(column.getSortByToggleProps())}
												className='th'
												style={{width: '100px'}}
												>
													<input
														autoFocus
														style={{
															border: '0', 
															width: '100%', 
															height: '100%', 
															backgroundColor: props.componentPage ? componentColor : relationColor,
															color: 'white',
															fontSize: '20px',
															fontFamily: "Betm Rounded Regular"
														}}
														value={newPropName}
														onChange={(e) => setNewPropName(e.target.value)}
													/>
											</div>
										: 
											<div
												{...column.getHeaderProps(column.getSortByToggleProps())}
												className='th table__add'
												onClick={() => setNewProp(true)}>
													<Add></Add>
											</div>}
										</>
									);
								}
								return (
									<div
										{...column.getHeaderProps(column.getSortByToggleProps())}
										className='th'>
										{column.render("Header")}
										<span>
											{column.isSorted
												? column.isSortedDesc
													? " 🔽"
													: " 🔼"
												: ""}
										</span>
									</div>
								);
						})}
						</div>
					)) }
				</div>
				<div {...props.getTableBodyProps()} className='body'>
					{rows.map((row, rowid) => {
						return (
							<form id={rowid} {...row.getRowProps()} style={{...row.getRowProps().style, ...{width: newProp ? parseInt(row.getRowProps().style.width) + 61 + 'px' : row.getRowProps().style.width}}} onSubmit={handleSubmit} className='tr'>
								{row.cells.map((cell, cellid) => {
									if ((cell.column.id === 'add' && newProp) || (cell.column.id !== 'clone' && cell.column.id !== 'delete' && cell.column.id !== 'update' && cell.column.id !== 'origin' && cell.column.id !== 'type' && cell.column.id !== 'target' && cell.column.id !== 'Nom')) {
										return (
											<div id={'div'+props.type + cell.column.id + cellid} onBlur={() => formInv.current[rowid].click()} {...cell.getCellProps()} style={{...cell.getCellProps().style, ...{width: cell.column.id === 'add' && newProp ? '100px' : cell.getCellProps().style.width}}} className='td'>
												<label id={'label'+props.type + cell.column.id + cellid} name={cell.column.id} style={{width: '100%', height: '100%'}}>
													<input 
														type="text" 
														name={cell.column.id} 
														id={props.type + cell.column.id + cellid} 
														style={{
															border: '0',
															width: '100%', 
															height: '100%',
															color: props.componentPage ? componentColor : relationColor,
															fontSize: '18px',
															fontFamily: "Betm Rounded Regular"
															}}
														onChange={(e) => handleCells(e, rowid, cellid)}
														value={cells[rowid][cellid] && cells[rowid][cellid].value ? cells[rowid][cellid].value : ''} />
												</label>
											</div>);
									} else if (cell.column.id === 'Nom') {
										return (
											<div id={'div'+props.type + cell.column.id + cellid} {...cell.getCellProps()} style={{...cell.getCellProps().style, ...{width: cell.getCellProps().style.width}}} className='td'>
												<label id={'label'+props.type + cell.column.id + cellid} name={cell.column.id} style={{width: '100%', height: '100%'}}>
													<input 
														type="text" 
														name={cell.column.id} 
														id={props.type + cell.column.id + cellid} 
														style={{
															border: '0',
															width: '100%', 
															height: '100%',
															color: props.componentPage ? componentColor : relationColor,
															fontSize: '18px',
															fontFamily: "Betm Rounded Regular"
															}}
														disabled={true}
														value={cells[rowid][cellid] && cells[rowid][cellid].value ? cells[rowid][cellid].value : ''} />
												</label>
											</div>);
									} else {
										return (
										<div {...cell.getCellProps()} className='td'>
											{cell.render('Cell')}
										</div>);
									}
								})}
								<button ref={el => (formInv.current[rowid] = el)} style={{visibility: 'hidden', display: 'none'}} type="submit"></button>
							</form>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export { TableData };
