import { GET_ROW_NAME, GET_RELATION_INSTANCE } from "./index";

export const getRowName = (rowName) => (dispatch) => {
	dispatch({
		type: GET_ROW_NAME,
		payload: rowName,
	});
};

export const getRelationInstance = (rowOrigin, rowTarget) => (dispatch) => {
	dispatch({
		type: GET_RELATION_INSTANCE,
		rowOrigin: rowOrigin,
		payload: rowTarget,
	});
};
