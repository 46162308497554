
import { svg2png } from 'svg-png-converter';

		export const downloadJPEG = () => {
			var svgHtml = document.getElementById("graph");
    		var svgData = new XMLSerializer().serializeToString(svgHtml.firstChild);
			svgData = (svgData).trim();
			svg2png({
				input: svgData,
				encoding: 'dataURL', 
				format: 'jpeg', 
			}).then((buffer) => {
				console.log(JSON.stringify(buffer).length);
				var downloadLink = document.createElement("a");
				downloadLink.href = buffer;
				downloadLink.download = "graph.jpeg";
				document.body.appendChild(downloadLink);
				downloadLink.click();
				document.body.removeChild(downloadLink);
			}, (error) => {
				console.log(error);
			})
		}