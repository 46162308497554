import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
	addComponent,
	getComponentOriginNames,
	getComponentTargetNames,
	getPropertyListComponent,
} from "../../action/componentAction";
import { addRelation, getPropertyListRelation } from "../../action/relationAction";

import { sortedFunction } from "../../utils/Function";
import { CloneComponent } from "../Form/Component/CloneComponent";
import { CloneRelation } from "../Form/Relation/CloneRelation";

import "./Popup.css";

function PopupCloneComponent(props) {
	let propsGetPropertyList;
	let relationInfo;

	if (props.componentPage) {
		propsGetPropertyList = props.getPropertyListComponent;
	} else if (props.relationPage) {
		propsGetPropertyList = props.getPropertyListRelation;
		relationInfo = props.relationInfo.find(rel => rel.id === props.infoUpdate.id)
	}

	useEffect(() => {
		propsGetPropertyList(props.type);
	}, [propsGetPropertyList, props.type]);

	return (
		<div className='container--popup'>
			<div className='background--popup'></div>
			{props.componentPage ? (
				<CloneComponent
					type={props.type}
					components={props.components}
					properties={sortedFunction(props.info)}
					setPopupClone={props.setPopupClone}
					addComponent={props.addComponent}
					infoUpdate={props.infoUpdate}
					propertiesListColumnComponent={props.propertiesListColumnComponent}
					info={props.info}
					setPopupUpdate={props.setPopupUpdate}
					updateComponent={props.updateComponent}
					updateNode={props.updateNode}
				/>
			) : (
				<CloneRelation
					type={props.type}
					nodeNames={props.nodeNames}
					infoUpdate={props.infoUpdate}
					setPopupClone={props.setPopupClone}
					addRelation={props.addRelation}
					relationInfo={relationInfo}
					propertiesListColumnRelation={props.propertiesListColumnRelation}
				/>
			)}
		</div>
	);
}

const mapStateToProps = (state) => ({
	componentOriginName: state.componentReducer.componentOriginName,
	componentTargetName: state.componentReducer.componentTargetName,
	propertiesListColumnComponent:
		state.componentReducer.propertiesListColumnComponent,
	propertiesListColumnRelation:
		state.relationReducer.propertiesListColumnRelation,
});

const mapDispatchToProps = (dispatch) => ({
	addComponent: (newComponent, inputFields, update) =>
		dispatch(addComponent(newComponent, inputFields, update)),
	addRelation: (relationType, newRelation) =>
		dispatch(addRelation(relationType, newRelation)),
	getComponentOriginNames: (componentType) =>
		dispatch(getComponentOriginNames(componentType)),
	getComponentTargetNames: (componentType) =>
		dispatch(getComponentTargetNames(componentType)),
	getPropertyListComponent: (componentType) =>
		dispatch(getPropertyListComponent(componentType)),
	getPropertyListRelation: (componentType) =>
		dispatch(getPropertyListRelation(componentType)),
});

const PopupClone = connect(
	mapStateToProps,
	mapDispatchToProps,
)(PopupCloneComponent);

export { PopupClone };
