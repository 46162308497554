import React from "react";
import { useHistory } from "react-router-dom";


const DatabaseSettings = (props) => {
	const history = useHistory();

	const handleChange = (e) => {
		props.updateDatabase(e.target.value);
		history.push("/");
		window.location.reload(true);
	}

	return (
		<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}> 
			<h1>Choisissez votre base de donnee</h1>
			<select onChange={handleChange} defaultValue={props.databases && props.databases.in_use}>
				{props.databases && props.databases.possibility.map((db, id) => {
					return <option key={id} value={db}>{db}</option>
				})}
			</select>
		</div>
	);
}

export default DatabaseSettings;
