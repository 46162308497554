import React, { useState } from "react";

import "./NodePopup.css";
import "../../../../Form/Form.css"
import ButtonStyle from "../../../../../styled-components/ButtonStyle";
import { componentColor, cancelColor, colors } from "../../../../../utils/color";

function NodePopup(props) {
    // const [shape, setShape] = useState(props.settings[props.name] && props.settings[props.name].shape ? props.settings[props.name].shape : 'circle');
    const [color, setColor] = useState(props.settings[props.name] && props.settings[props.name].color ? props.settings[props.name].color : 'rgb(86,148,128)');
    const [content, setContent] = useState(props.settings[props.name] && props.settings[props.name].content ? props.settings[props.name].content : 'Nom');
    const [size, setSize] = useState(props.settings[props.name] && props.settings[props.name].size ? props.settings[props.name].size : '50px');

    const handleClose = (e) => {
        e.preventDefault();
        props.setShow(false);
    }

    const token = sessionStorage.getItem("cool-jwt");

    const updateSettings = (e) => {
        e.preventDefault();
        const newSettings = props.settings;
        if (newSettings[props.name]) {
            newSettings[props.name].color = color;
            newSettings[props.name].shape = 'circle';
            // newSettings[props.name].shape = shape;
            newSettings[props.name].content = content;
            newSettings[props.name].size = size;
        } else {
            let newSetting = {};
            newSetting.color = color;
            newSetting.shape = 'circle';
            // newSetting.shape = shape;
            newSetting.content = content;
            newSetting.size = size;
            newSettings[props.name] = newSetting;
        }
        props.updateSettings(token, newSettings);
        props.updateGraph();
        handleClose(e);
    }

    // const generateShapes = () => {
    //     const shapes = props.shapes.map((element, i) => 
    //         <div key={i} className={element.name === shape ? 'setting--selected' : 'setting'} onClick={() => setShape(element.name)}>{element.svg}</div>
    //     )
    //     return shapes;
    // }

    const generateSizes = () => {
        const sizes = props.sizes.map((element, i) => 
            <div key={i} style={{width: element, height: element}} className={element === size ? 'setting--selected' : 'setting'} onClick={() => setSize(element)}>{props.shapes.find((shap) => shap.name === 'circle')?.svg}</div>
        )
        return sizes;
    }

    const generateColors = () => {
        const colorList = colors.map((element, i) => 
            <div key={i} className={element === color ? 'setting--selected' : 'setting'} onClick={() => setColor(element)} style={{backgroundColor: element, borderlineWidth: 3}}></div>
        )
        return colorList;
    }

    const generateContent = () => {
        const contents = props.contents.map((element, i) => 
            <span key={i} className={element === content ? 'setting--selected content' : 'setting content'} onClick={() => setContent(element)}>{element}</span>
        )
        return contents;
    }

	return (
		<div className='container--popupSettings'>
            <div className='background--popupSettings' onClick={handleClose}></div>
            <div className='container--form'>
                <h2>Mettre a jour les parametres pour {props.name}</h2>
                {/* <div>
                    <h3>Forme</h3>
                    <div className='settings'>
                    {generateShapes()}
                    </div>
                </div> */}
                <div>
                    <h3>Taille</h3>
                    <div className='settings' style={{height: '100px', alignItems: 'center'}}>
                    {generateSizes()}
                    </div>
                </div>
                <div>
                    <h3>Couleur</h3>
                    <div className='settings'>
                    {generateColors()}
                    </div>
                </div>
                <div>
                    <h3>Contenu affiche</h3>
                    <div>
                    {generateContent()}
                    </div>
                </div>
                <div className='container--form--button'>
                    <ButtonStyle
                        color={cancelColor}
                        onClick={() => props.setShow(false)}>
                        ANNULER
                    </ButtonStyle>
                    <ButtonStyle color={componentColor} onClick={(e) => updateSettings(e)}>
                        CONFIRMER
                    </ButtonStyle>
                </div>
            </div>
		</div>
	);
}

export { NodePopup };
