

	
	export const updateLinkPossibility = (types, links) => {
		let possibility = [];
		types.forEach(type => {
			let poss = [];
			links.forEach(link => {
				if (link.type === type) {
					poss = poss.concat(Object.keys(link));
				}
			})
			possibility[type] = [...new Set(poss.filter(
				(prop) => 
					prop !== 'index' &&
					prop !== 'source' &&
					prop !== 'target' &&
					prop !== 'data' &&
					prop !== 'sameIndex' &&
					prop !== 'sameTotal' &&
					prop !== 'sameLowerHalf' &&
					prop !== 'sameMiddleLink' &&
					prop !== 'sameUneven' &&
					prop !== 'sameTotalHalf' &&
					prop !== 'sameArcDirection' &&
					prop !== 'sameIndexCorrected' &&
					prop !== 'maxSameHalf' &&
					prop !== 'from',
				))];
		});
		return possibility; // this.setState({linkPossibility: possibility});
	}