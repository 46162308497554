import React, { useState, Fragment } from "react";

import { AddRemoveInput } from "..";
import { componentColor, relationColor, cancelColor } from "../../utils/color";
//import { capitlizeString } from "../utils/Function";

import "./Form.css";

function InputAddProps(props) {
	const [formNumber, setFormNumber] = useState(1);
	const [customPropertie, setCustomPropertie] = useState([]);
	const [errors, setErrors] = useState([]);

	const handleInputChange = (index, event) => {
		const values = [...props.inputFields];
		if (event.target.name === "property") {
			const newError = [...errors];
			if (event.target.value !== '' && (values.find(el => el.property === event.target.value) || props.properties.find(el => el === event.target.value))) {
				newError[index] = 'Cette propriete existe deja';
				props.setDouble(true);
			} else {
				newError[index] = '';
			}
			if (!newError.find(el => el !== '')) {
				props.setDouble(false);
			}
			setErrors(newError);
			values[index].property = event.target.value;
			customPropertie[index] = '';
		} else {
			if (values[index].property === '')
				values[index].property = customPropertie[index];
			values[index].content = event.target.value;
		}

		props.setInputFields(values);
	};

	const handleChange = (index, event) => {
		const values = [...customPropertie];
		values[index] = event.target.value;
		setCustomPropertie(values);
	}

	return (
		<div className='form--row'>
			{props.inputFields && props.inputFields.map((inputField, index) => (
				<Fragment key={`${inputField}~${index}`}>
					<div className='fragment'>
						<div className='group--input'>
							{customPropertie[index] !== '' ?
							<input
								type='text'
								name='property'
								list='property'
								value={customPropertie[index]}
								onChange={(event) => handleChange(index, event)}
								style={{
									color: props.relation ? relationColor : componentColor,
									borderBottomColor: props.relation ? (errors[index] && errors[index] !== '' ? cancelColor : relationColor) : (errors[index] && errors[index] !== '' ? cancelColor: componentColor),
								}}
								required={formNumber > 1 ? true : false}
							/> : null }
							<select
								name='property'
								onChange={(event) => handleInputChange(index, event)}
								value={inputField.property}
								style={{ color: componentColor, width: customPropertie[index] !== '' ? '15px' : 'auto',
									borderBottomColor: props.relation ? (errors[index] && errors[index] !== '' ? cancelColor : relationColor) : (errors[index] && errors[index] !== '' ? cancelColor: componentColor),
								}}
								required={customPropertie === ''}>
								<option value=''></option>
								{ props.propertiesList.map((propertie, i) => {
									return (
										<option key={i} value={propertie}>
											{propertie}
										</option>
									);
								})}
							</select>
							<span className="floating--label--input--error"
							style={{ color: cancelColor }}>{errors[index]}</span>
							<span
								className={"floating--label--select required"}
								style={{ color: props.relation ? relationColor : componentColor }}>
								Nouvelle propriété
							</span>
						</div>
						<div className='group--input'>
							<input
								type='text'
								name='content'
								value={inputField.content}
								onChange={(event) => handleInputChange(index, event)}
								style={{
									color: props.relation ? relationColor : componentColor,
									borderBottomColor: props.relation ? relationColor : componentColor,
								}}
								required={formNumber > 1 ? true : false}
							/>
							<span
								className={
									formNumber > 1
										? "floating--label--input required"
										: "floating--label--input"
								}
								style={{ color: props.relation ? relationColor : componentColor }}>
								Contenu
							</span>
						</div>
					</div>
					<AddRemoveInput
						formNumber={formNumber}
						index={index}
						setFormNumber={setFormNumber}
						inputFields={props.inputFields}
						setInputFields={props.setInputFields}
						customPropertie={customPropertie}
						setCustomPropertie={setCustomPropertie}
					/>
				</Fragment>
			))}
		</div>
	);
}

export { InputAddProps };

// <datalist id='property'>
// 								{inputFieldsRemaining.map((property, i) => {
// 									return (
// 										<option key={i} value={property}>
// 											{property}
// 										</option>
// 									);
// 								})}
// 							</datalist>
