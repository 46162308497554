import React from "react";
import logosvg from "../assets/img/Graph-IT.svg";

const Informations = (props) => {
	return (
		<div className='big--container'>
			<div className='container--component--page'>
                <div style={{display: "flex", flexDirection: "column", overflow: 'auto', width: 'calc(100% - 80px - 2rem', fontSize: 15}}>
                    <div style={{width: "100%", textAlign: "center"}}>
                        <img
                            alt=""
				            src={logosvg}
				            style={{width: "300px"}}
			            />
                        <div style={{textAlign: "center", fontWeight: "bold", fontSize: 20, lineHeight: 2}}>Version 1.0</div>
                    </div>
                    <div style={{width: "50%"}}>
                        <div style={{textAlign: "center", fontWeight: "bold", fontSize: 30, lineHeight: 2}}>Qu’est-ce que Graph-IT ?</div>
                        <div>
                            <text style={{fontWeight: "bold"}}>Graph-IT</text>
                            <text style={{fontWeight: "bold", fontSize: 12}}>{'\u00AE'}&nbsp;</text>
                            <text>est une application web de modélisation de systèmes entités / relations permettant une représentation et une exploration visuelle de ces systèmes.</text>
                        </div>
                        <div>
                            L’application&nbsp;
                            <text style={{fontWeight: "bold"}}>Graph-IT</text>
                            <text style={{fontSize: 12}}>{'\u00AE'}&nbsp;</text>
                            est conçue pour permettre aux Directions des Systèmes d’Information (DSI) d’inventorier les différentes catégories d’entités 
                            qui constituent un Système d’Information comme les serveurs, les logiciels, les contrats, les équipements réseau…
                        </div>
                        <div>
                            L’application&nbsp;
                            <text style={{fontWeight: "bold"}}>Graph-IT</text>
                            <text style={{fontSize: 12}}>{'\u00AE'}&nbsp;</text>
                            est livrée avec plusieurs catégories d’entités pré-configurées mais il est possible d’en créer autant que nécessaire 
                            et d’y associer tout type de propriétés ou de relations.
                        </div>
                    </div>

                    <div style={{width: "50%", marginLeft: 'auto'}}>
                    <div style={{textAlign: "center", fontWeight: "bold", fontSize: 30, lineHeight: 2}}>Comment ça marche ?</div>
                    <div>
                        Commencez par créer vos entités, appelées “Composants” et leur associer les propriétés utiles pour votre activité. 
                        Ces composants sont gérés grâce au menu "Composant".
                        Vous pouvez créer un nouveau composant en cliquant sur le picto “+” situé à côté du titre.
                    </div>
                    <div>
                        Une fois vos composants créés, vous pouvez créer des relations entre ces composants, quel que soit le type de composant. 
                        Les relations sont accessibles dans le menu “Relations”. 
                        Pour créer une nouvelle relation, cliquez sur le picto “+” situé à côté du titre.
                    </div>
                    <div>
                        Enfin, vous pouvez visualiser la représentation graphique de vos composants et de leurs relations en utilisant le menu “Cartographie”. 
                        La première chose à faire dans la cartographie est de choisir un composant de départ en faisant une recherche textuelle 
                        dans la zone de saisie prévue à cet effet. Dès la deuxième lettre saisie, la liste des composants qui correspondent à votre saisie s’affiche. 
                        Cliquez sur le composant voulu et il sera affiché. 
                        Rendez-vous sur le composant sur lequel vous pouvez cliquer
                    </div>

                    </div>
                    <div style={{width: "50%"}}>
                        <div style={{textAlign: "center", fontWeight: "bold", fontSize: 30, lineHeight: 2}}>Informations légales et support</div>
                        <div style={{display: "flex"}}>
                            <text style={{fontWeight: "bold"}}>Graph-IT</text>
                            <text style={{fontSize: 12}}>{'\u00AE'}&nbsp;</text>
                            est une marque déposée par la société OPB Consulting.
                        </div>
                        <div>
                            Le code source est la propriété exclusive de la société OPB Consulting.
                            Aucune partie du code ne peut être copiée ou modifiée sans l’accord express de la société OPB Consulting. 
                        </div>
                        <div>
                            En cas de problème technique, pour obtenir plus d’informations ou pour suggérer des évolutions, vous pouvez nous contacter par email à l’adresse : contact@graph-it.fr
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
}

export { Informations };
