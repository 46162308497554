import React, { useEffect, useState, useMemo } from "react";

import { cancelColor, relationColor } from "../../../utils/color";
import { InputAddProps } from "../..";

import ButtonStyle from "../../../styled-components/ButtonStyle";
import { sortedFunction } from "../../../utils/Function";

import "../Form.css";
import { getNodeNames } from "../../../action/componentAction";

function CloneRelation(props) {
	const [dataToUpdate, setDataToUpdate] = useState({
		id: props.infoUpdate.id,
		origin: props.infoUpdate.origin,
		target: props.infoUpdate.target,
		idOrigin: props.infoUpdate.idOrigin,
		idTarget: props.infoUpdate.idTarget,
		type: props.type,
	});
	const [double, setDouble] = useState(false);
	const [inputFields, setInputFields] = useState([
		{ property: "", content: "" },
	]);

	useEffect(() => {
		getNodeNames();
	})

	const handleChange = (event) => {
		setDataToUpdate({
			...dataToUpdate,
			[event.target.name]: event.target.value,
		});
	};

	const formatInput = (event) => {
		setDataToUpdate({
			...dataToUpdate,
			[event.target.name]: event.target.value.trim(),
		});
	};

	const formatNewInput = () => {
		let inputs = [];
		for (const input of inputFields) {
            if (input.property !== '' && input.content !== '')
                inputs = {
                    ...inputs,
                    [input.property]: input.content,
                }
		}
		return inputs;
	}

	let info = Object.entries(props.infoUpdate && props.infoUpdate);

	const infoFiltered = info && info.filter(
		(col) => col &&
			col[0] !== "id" &&
			col[0] !== "origin" &&
			col[0] !== "target" &&
			col[0] !== "idOrigin" &&
			col[0] !== "idTarget" &&
			col[0] !== "rowTarget" &&
			col[0] !== "rowOrigin" &&
			col[0] !== "Updated" &&
			col[0] !== "Created" &&
			col[0] !== "UpdatedBy" &&
			col[0] !== "CreatedBy"&&
			col[0] !== "type" &&
			col[0] !== "Created_year_low" &&
			col[0] !== "Created_month_low" &&
			col[0] !== "Created_day_low" &&
			col[0] !== "Created_hour_low" &&
			col[0] !== "Created_minute_low" &&
			col[0] !== "Created_second_low" &&
			col[0] !== "Created_nanosecond_low" &&
			col[0] !== "Updated_year_low" &&
			col[0] !== "Updated_month_low" &&
			col[0] !== "Updated_day_low" &&
			col[0] !== "Updated_hour_low" &&
			col[0] !== "Updated_minute_low" &&
			col[0] !== "Updated_second_low" &&
			col[0] !== "Updated_nanosecond_low" &&
			col[0] !== null &&
			col[0] !== "",
	).sort((a, b) => a[0].localeCompare(b[0]));;

	useMemo(() => {
		let properties = dataToUpdate;
		infoFiltered.forEach((info) => {
			properties = {
				...properties,
				[info[0]]: info[1],
			}
		});
		setDataToUpdate(properties);
	}, [props.info]);

	const renderInputRelationToUpdate = () => {
		return infoFiltered.map((x, i) => {
			return (
				<div className='group--input'>
					<input
						key={i}
						type='text'
						name={x[0]}
						value={dataToUpdate[x[0]]}
						onChange={handleChange}
						onBlur={formatInput}
						style={{ color: relationColor }}
					/>
					<span
						className='floating--label--input--update'
						style={{ color: relationColor }}>
						{x[0]}
					</span>
				</div>
			);
		});
	};

	const renderNodes = () => {
		const nodeSorted = props.nodeNames.sort((a, b) => {
			if (a.name && b.name && a.type && b.type) {
				return (a.type + ': ' + a.name).localeCompare(b.type + ': ' + b.name);
			}
			return 0;
		});
		return nodeSorted.map((x, i) => {
			return (
				<option
					key={i + '1'}
					value={x.id}>{x.type + ': ' + x.name}</option>
			)
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault();
        console.log(formatNewInput());
		const originNode = props.nodeNames.find(node => node.id === parseInt(dataToUpdate.idOrigin))
		const targetNode = props.nodeNames.find(node => node.id === parseInt(dataToUpdate.idTarget))
        delete dataToUpdate.id;
        delete dataToUpdate.idOrigin;
        delete dataToUpdate.idTarget;
        delete dataToUpdate.origin;
        delete dataToUpdate.target;
        delete dataToUpdate.type;
        const newRel = {
            componentOriginType: originNode.type,
            componentOriginName: originNode.name,
            componentTargetType: targetNode.type,
            componentTargetName: targetNode.name,
            data: {...formatNewInput(), ...dataToUpdate},
        };
		props.addRelation({type: props.type}, newRel);
		props.setPopupClone(false);
	};

	return (
		<form onSubmit={handleSubmit} className='container--form'>
			<h2 style={{ color: relationColor }}>
				CLONER UNE RELATION
			</h2>
			<div className='container--cartridge'>
				<select
					className='cartridgeName'
					name='idOrigin'
					style={{ backgroundColor: relationColor, maxWidth: 200 }}
					defaultValue={dataToUpdate.idOrigin}
					onChange={handleChange}
					>
					{props.nodeNames && renderNodes()}
				</select>{" "}
				<div
					className='cartridgeName'
					style={{ backgroundColor: relationColor, maxWidth: 200 }}>
						{props.type}
				</div>
				<select
					className='cartridgeName'
					name='idTarget'
					style={{ backgroundColor: relationColor, maxWidth: 200 }}
					defaultValue={dataToUpdate.idTarget}
					onChange={handleChange}
					>
					{props.nodeNames && renderNodes()}
				</select>
			</div>
			<div className='container--tuple--update'>
				{renderInputRelationToUpdate()}
			</div>
			{ props.propertiesListColumnRelation ?
			<InputAddProps
				type={props.type}
				components={props.components}
				inputFields={inputFields}
				setInputFields={setInputFields}
				properties={Object.keys(props.infoUpdate) || sortedFunction(props.info)}
				double={double}
				setDouble={setDouble}
				relation={true}
				propertiesList={props.propertiesListColumnRelation ? props.propertiesListColumnRelation.filter(property => 
					property !== 'Created'
					&& property !== 'Updated'
					&& property !== 'UpdatedBy'
					&& property !== 'CreatedBy') : []}
			/> : null }
			<div className='container--form--button'>
				<ButtonStyle
					color={cancelColor}
					onClick={() => props.setPopupClone(false)}>
					Annuler
				</ButtonStyle>
				{double ? (
					<span className='duplicate--property'>Propriété déjà existante</span>
				) : (
					<ButtonStyle color={relationColor} type='submit'>
						CONFIRMER
					</ButtonStyle>
				)}
			</div>
		</form>
	);
}

export { CloneRelation };
