import React from "react";

import add from "../../assets/table/add.svg";
import remove from "../../assets/table/minus.svg";

function AddRemoveInput(props) {
	const style = { display: "flex", flexDirection: "row", width: "100px" };
	const style2 = { display: "flex", flexDirection: "row", width: "50px" };

	const handleAddFields = () => {
		const values = [...props.inputFields];
		values.push({ property: "", content: "" });
		props.setInputFields(values);
		props.setFormNumber((prevFormNumber) => prevFormNumber + 1);
	};

	const handleRemoveFields = (index) => {
		const values = [...props.inputFields];
		values.splice(index, 1);
		props.setInputFields(values);
		props.setFormNumber((prevFormNumber) => prevFormNumber - 1);
		if (props.customPropertie) {
			const values2 = [...props.customPropertie];
			values2.splice(index, 1);
			props.setCustomPropertie(values2);
		}
	};

	return (
		<div
			className='container--add--remove'
			style={props.formNumber > 1 || props.formNumber < 5 ? style : style2}>
			{props.formNumber > 1 && (
				<button
					className='btn handle--tuple'
					type='button'
					onClick={() => handleRemoveFields(props.index)}>
					<img src={remove} alt='remove input' style={{ width: "60%" }} />
				</button>
			)}
			{props.formNumber < 5 && (
				<button
					className='btn handle--tuple'
					type='button'
					data-tip='Ajouter une propriété'
					onClick={() => handleAddFields()}>
					<img src={add} alt='add input' style={{ width: "60%" }} />
				</button>
			)}
		</div>
	);
}

export { AddRemoveInput };
