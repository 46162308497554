import React, { useState } from "react";

import "./LinkPopup.css";
import "../../../../Form/Form.css"
import ButtonStyle from "../../../../../styled-components/ButtonStyle";
import { componentColor, cancelColor, colors } from "../../../../../utils/color";

function LinkPopup(props) {
    const [shape, setShape] = useState(props.settings[props.name] ? props.settings[props.name].shape : 'circle');
    const [color, setColor] = useState(props.settings[props.name] ? props.settings[props.name].color : 'rgb(214, 39, 40)');
    const [content, setContent] = useState(props.settings[props.name] ? props.settings[props.name].content : 'Nom');
    const [line, setLine] = useState(props.settings[props.name] ? props.settings[props.name].line : 'solid');
    const [lineWidth, setLineWidth] = useState(props.settings[props.name] ? props.settings[props.name].lineWidth : '10px');

    const handleClose = (e) => {
        e.preventDefault();
        props.setShow(false);
    }

    const token = sessionStorage.getItem("cool-jwt");

    const updateSettings = (e) => {
        e.preventDefault();
        const newSettings = props.settings;
        if (newSettings[props.name]) {
            newSettings[props.name].color = color;
            newSettings[props.name].shape = shape;
            newSettings[props.name].content = content;
            newSettings[props.name].line = line;
            newSettings[props.name].lineWidth = lineWidth;
        } else {
            let newSetting = {};
            newSetting.color = color;
            newSetting.shape = shape;
            newSetting.content = content;
            newSetting.line = line;
            newSetting.lineWidth = lineWidth;
            newSettings[props.name] = newSetting;
        }
        props.updateSettings(token, newSettings);
        props.updateGraph();
        handleClose(e);
    }

    const generateShapes = () => {
        const shapes = props.shapes.map((element, i) => 
            <div key={i} className={element.name === shape ? 'setting--selected' : 'setting'} onClick={() => setShape(element.name)}>{element.svg}</div>
        )
        return shapes;
    }

    const generateColors = () => {
        const colorList = colors.map((element, i) => 
            <div key={i} className={element === color ? 'setting--selected' : 'setting'} onClick={() => setColor(element)} style={{backgroundColor: element, borderlineWidth: 3}}></div>
        )
        return colorList;
    }

    const generateContent = () => {
        const contents = props.contents.map((element, i) => 
            <span key={i} className={element === content ? 'content setting--selected' : 'content setting'} onClick={() => setContent(element)}>{element}</span>
        )
        return contents.length !== 0 ? contents : <span className='content setting--selected' onClick={() => setContent('Nom')}>Nom</span>;
    }

    const generateLines = () => {
        const lines = props.lineType.map((element, i) => 
            <div key={i} className={element === line ? 'content setting--selected' : 'content setting'} onClick={() => setLine(element)}><div style={{borderBottom: lineWidth + ' ' + element + ' ' + color, width: '100px'}}/></div>
        )
        return lines
    }

    const generateLinesWidth = () => {
        const linesWidth = props.linesWidth.map((element, i) =>
            <option key={i} value={element}>{element}</option>
        );
        return (<select defaultValue={lineWidth} onChange={(event) => setLineWidth(event.target.value)}>{linesWidth}</select>);
    }

	return (
		<div className='container--popupSettings'>
            <div className='background--popupSettings' onClick={handleClose}></div>
            <div className='container--form'>
                <h2>Mettre a jour les paramètres pour {props.name}</h2>
                <div>
                    <h3>Flèche</h3>
                    <div className='settings'>
                        {generateShapes()}
                    </div>
                </div>
                <div>
                    <h3>Trait</h3>
                    <div className='settings'>
                        {generateLines()}
                    </div>
                </div>
                <div>
                    <h3>Epaisseur</h3>
                    <div className='settings'>
                        {generateLinesWidth()}
                    </div>
                </div>
                <div>
                    <h3>Couleur</h3>
                    <div className='settings'>
                        {generateColors()}
                    </div>
                </div>
                <div>
                    <h3>Contenu affiché</h3>
                    <div className='settings'>
                        {generateContent()}
                    </div>
                </div>
                <div className='container--form--button'>
                    <ButtonStyle
                        color={cancelColor}
                        onClick={() => props.setShow(false)}>
                        ANNULER
                    </ButtonStyle>
                    <ButtonStyle color={componentColor} onClick={(e) => updateSettings(e)}>
                        CONFIRMER
                    </ButtonStyle>
                </div>
            </div>
		</div>
	);
}

export { LinkPopup };
