import {
	GET_MAP_NODE,
	GET_MAP_ALL_RELATIONS,
	GET_MAP_COMPONENT_RELATIONS,
	RESET_MAP,
	UPDATE_RELATION,
	GET_MAP_COMPONENT_RELATIONS_TYPES,
	GET_MAP_CARTO,
	RESET_NODE,
	DELETE_MAP_CARTO
} from "../action";
import { removeDuplicateObjectFromArray } from "../utils/Function";

export const mapReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_MAP_ALL_RELATIONS:
			return { ...state, allRelations: action.payload };

		case GET_MAP_COMPONENT_RELATIONS:
			let nodes = [];
			let links = [];
			var array = action.payload;
			for (let i = 0; i < array.length; i++) {
				nodes.push(array[i].nodes);
				links.push(array[i].links);
			}
			nodes = removeDuplicateObjectFromArray(nodes.flat(), "id");
			links = removeDuplicateObjectFromArray(links.flat(), "id");
			const newObj = { nodes: nodes, links: links };

			return { ...state, relationsNode: newObj };

		case GET_MAP_NODE:
			let noddes = [];
			let linkks = [];
			array = action.payload;
			if (array.length === 1) {
				return {...state, node: array}
			}
			for (let i = 0; i < array.length; i++) {
				noddes.push(array[i].nodes);
				if (array[i].links) {
					linkks.push(array[i].links);
				}
			}
			noddes = removeDuplicateObjectFromArray(noddes.flat(), "id");
			if (linkks.length !== 0) {
				linkks = removeDuplicateObjectFromArray(linkks.flat(), "id");
			}
			const newObjj = { nodes: noddes, links: linkks };
			return { ...state, relationsNode: newObjj };

		case GET_MAP_COMPONENT_RELATIONS_TYPES:
			return { ...state, relationTypes: action.payload };

		case RESET_MAP:
			return { ...state, allRelations: undefined, relationsNode: undefined, node: undefined}

		
		case RESET_NODE:
			return { ...state, node: undefined}

		case UPDATE_RELATION:
			const map = state.relationsNode;
			if (map) {
				const oldrel = map.links.find((element) => element.id === action.payload.oldId);
				if (oldrel) {
					oldrel.source.id = action.payload.idOrigin;
					oldrel.target.id = action.payload.idTarget;
					oldrel.id = action.payload.id;
					for (const key of Object.keys(action.payload.data)) {
						oldrel[key] = action.payload.data[key];
					}
					let newObj = [oldrel];
					let final = {nodes: map.nodes, links: map.links.map((obj) => newObj.find((o) => action.payload.oldId === obj.id) || obj,)}
					return { ...state, relationsNode: final };
				}
			}
			return { ...state, relationsNode: map };

		case GET_MAP_CARTO:
			const graph = action.payload;
			return { ...state, savedCartos: graph.graphs}
		case DELETE_MAP_CARTO:
			const graphId = action.payload;
			return { ...state, savedCartos: state.savedCartos.filter(cart => cart.id !== graphId)}
		default:
			return state;
	}
};
