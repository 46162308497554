import React, { useState } from "react";

import { componentColor, relationColor, cancelColor } from "../../utils/color";

import ButtonStyle from "../../styled-components/ButtonStyle";

import "./Popup.css";

function PopupDelete(props) {
	const [displayList, setDisplayList] = useState(false);

	const componentRelationList =
		props.componentRelationList && props.componentRelationList;

	const handleClickComponent = (componentType, componentId) => {
		if (props.removeNode) {
			props.removeNode([componentId]);
		}
		props.deleteComponent(componentType, componentId);
		props.setPopupDelete(false);
	};

	const handleClickRelation = (relationId) => {
		console.log(props.removeLink)
		if (props.removeLink) {
			props.removeLink(relationId);
		}
		props.deleteRelation(relationId);
		props.setPopupDelete(false);
	};

	const handleListRelation = (componentId) => {
		props.seeRelations(componentId);
		setDisplayList(!displayList);
	};

	const renderListRelation = () => {
		return (
			componentRelationList &&
			componentRelationList.map((relation, i) => {
				return (
					<div className='container--listRelation'>
						<hr className='lign--separator' />
						<div key={i} className='listRelation'>
							<p>{relation.origin}</p>
							<p style={{ color: relationColor }}>{relation.relation}</p>
							<p>{relation.target}</p>
						</div>
					</div>
				);
			})
		);
	};

	return (
		<div
			className={
				displayList ? "container--popup--listRelation" : "container--popup"
			}>
			<div className='background--popup'></div>
			{props.instance ? (
				<div className='foreground--popup popRelation'>
					<h1>ATTENTION</h1>
					<p>Vous vous apprêtez à supprimer la relation entre </p>
					<div className='container--cartridge'>
						<div
							className='cartridgeName'
							style={{ backgroundColor: relationColor }}>
							{props.instance.rowOrigin}
						</div>{" "}
						<p>et</p>
						<div
							className='cartridgeName'
							style={{ backgroundColor: relationColor }}>
							{props.instance.rowTarget}
						</div>
					</div>
					<p>Êtes-vous certain de vouloir la supprimer</p>
					<div className='container--button--popup'>
						<ButtonStyle
							color={relationColor}
							onClick={() => props.setPopupDelete(false)}>
							ANNULER
						</ButtonStyle>
						<ButtonStyle
							color={cancelColor}
							onClick={() => handleClickRelation(props.relationId)}>
							SUPPRIMER
						</ButtonStyle>
					</div>
				</div>
			) : (
				<div className='foreground--popup popComponent'>
					<h1>ATTENTION</h1>
					<p>Vous vous apprêtez à supprimer le composant</p>
					<div
						className='cartridgeName'
						style={{ backgroundColor: componentColor }}>
						{props.name}
					</div>
					{props.relationNumber === 0 ? (
						<p>Ce composant ne possède aucune relation</p>
					) : (
						<p>
							Ce composant possède{" "}
							{props.relationNumber === 1 ? (
								<b style={{ color: "#E7362D" }}>
									{props.relationNumber} relation
								</b>
							) : (
								<b style={{ color: "#E7362D" }}>
									{props.relationNumber} relations
								</b>
							)}{" "}
							{props.relationNumber === 1
								? " qui sera supprimée. Êtes-vous certain de vouloir le supprimer ?"
								: " qui seront supprimées. Êtes-vous certain de vouloir le supprimer ?"}
						</p>
					)}
					<div>
						{props.relationNumber === 0 ? null : (
							<ButtonStyle
								color={componentColor}
								onClick={() => handleListRelation(props.componentId)}>
								{displayList ? "CACHER" : "VOIR"} LES RELATIONS
							</ButtonStyle>
						)}
					</div>
					<div className='container--renderList'>
						{displayList && renderListRelation()}
					</div>
					<div className='container--button--popup'>
						<ButtonStyle
							color={componentColor}
							onClick={() => props.setPopupDelete(false)}>
							ANNULER
						</ButtonStyle>
						<ButtonStyle
							color={cancelColor}
							onClick={() =>
								handleClickComponent(props.componentType, props.componentId)
							}>
							SUPPRIMER
						</ButtonStyle>
					</div>
				</div>
			)}
		</div>
	);
}

export { PopupDelete };
