import { useEffect } from "react";

export function removeDuplicateObjectFromArray(array, key) {
	var check = {};
	var res = [];
	array.forEach((element) => {
		if (!check[element[key]]) {
			check[element[key]] = true;
			res.push(element);
		}
	});
	return res;
}

export const sortedFunction = (elements) => {
	const propertiesArr =
		elements &&
		elements.map((propertie) => {
			return Object.keys(propertie);
		});

	const properties = [].concat.apply([], propertiesArr);

	var uniq = properties
		.map((property) => {
			return { count: 1, property: property };
		})
		.reduce((a, b) => {
			a[b.property] = (a[b.property] || 0) + b.count;
			return a;
		}, {});

	var sortedProperties = Object.keys(uniq).sort((a, b) => uniq[a] < uniq[b]).sort();

	return sortedProperties;
};

// ====================================================================================

export function flattenObj(obj, parent, res = {}) {
	for (let key in obj) {
		let propName = parent ? parent + "_" + key : key;
		if (typeof obj[key] == "object") {
			flattenObj(obj[key], propName, res);
		} else {
			res[propName] = obj[key];
		}
	}
	return res;
}

// ====================================================================================

export const formatDate = (object) => {
	let date;
	// eslint-disable-next-line no-unused-vars
	const zero = (number) => {
		if (number < 10) {
			return "0" + number;
		} else {
			return number;
		}
	};

	for (var key in object) {
		if (object.hasOwnProperty(key)) {
			if (key.endsWith("low")) {
				console.log(object);
				// return {
				// 	Depuis: (date =
				// 		zero(object.Depuis_day_low) +
				// 		"/" +
				// 		zero(object.Depuis_month_low) +
				// 		"/" +
				// 		object.Depuis_year_low),
				// };
			} else {
				return { key: object[key] };
			}
		} else {
			return { key: object[key] };
		}
	}

	return date;
};

// ====================================================================================

export const strUcFirst = (aString) => {
	return aString.charAt(0).toUpperCase() + aString.substr(1);
};

// ====================================================================================

let type;
export const getInfoInputList = (nodeType) => {
	if (
		nodeType === "Logiciel" ||
		nodeType === "Organisation" ||
		nodeType === "Personne" ||
		nodeType === "Société" ||
		nodeType === "Contrat" ||
		nodeType === "Serveur" ||
		nodeType === "BaseDeDonnées" ||
		nodeType === "Instance" ||
		nodeType === "Domaine" ||
		nodeType === "Stockage" ||
		nodeType === "EquipReseau" ||
		nodeType === "Données" ||
		nodeType === "Etablissement"
	) {
		type = { type: nodeType, color: "#008aadaa" };
		return type;
	} else if (
		nodeType === "COUVRE" ||
		nodeType === "AUTEUR_DE" ||
		nodeType === "GERE" ||
		nodeType === "UTILISE" ||
		nodeType === "TRANSFERE_A" ||
		nodeType === "TRANSMET_A" ||
		nodeType === "CONCERNE" ||
		nodeType === "REPORTE_A" ||
		nodeType === "MEMBRE_DE" ||
		nodeType === "DIRIGE_PAR" ||
		nodeType === "LIEN_CONTEXTUEL_VERS" ||
		nodeType === "INTERROGE" ||
		nodeType === "HEBERGE" ||
		nodeType === "RATTACHE_A" ||
		nodeType === "FONCTIONNE_AVEC" ||
		nodeType === "ECHANGE" ||
		nodeType === "RELIE_A" ||
		nodeType === "TRAVAILLE_POUR"
	) {
		type = { type: nodeType, color: "#fd2f2faa" };
		return type;
	} else {
		type = { type: "node", color: "#00a224aa" };
		return type;
	}
};

// ====================================================================================

export const compareInput = (query) => {
	let queryCap = query.toUpperCase();
	if (
		queryCap === "COUVRE" ||
		queryCap === "AUTEUR_DE" ||
		queryCap === "GERE" ||
		queryCap === "UTILISE" ||
		queryCap === "TRANSFERE_A" ||
		queryCap === "TRANSMET_A" ||
		queryCap === "CONCERNE" ||
		queryCap === "REPORTE_A" ||
		queryCap === "MEMBRE_DE" ||
		queryCap === "DIRIGE_PAR" ||
		queryCap === "LIEN_CONTEXTUEL_VERS" ||
		queryCap === "INTERROGE" ||
		queryCap === "HEBERGE" ||
		queryCap === "RATTACHE_A" ||
		queryCap === "FONCTIONNE_AVEC" ||
		queryCap === "ECHANGE" ||
		queryCap === "RELIE_A" ||
		queryCap === "TRAVAILLE_POUR"
	) {
		return queryCap;
	} else {
		return strUcFirst(query);
	}
};

// ====================================================================================

export const useOnClickOutside = (ref, handler) => {
	useEffect(() => {
		const listener = (event) => {
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}
			handler(event);
		};
		document.addEventListener("mousedown", listener);
		return () => {
			document.removeEventListener("mousedown", listener);
		};
	}, [ref, handler]);
};

// ================================================================================

export const capitalizeString = (str) => {
	const words = str.trimStart().split(" ");
	words.forEach((word, index) => {
		if (word && word[0]) {
			words[index] = word[0].toUpperCase() + word.slice(1).toLowerCase();
		}
	})
	return words.length > 1 ? words.join(" ") : words[0];
}

export const capitalizeWord = (str) => {
	const trimmed = str.trimStart();
	if (trimmed[0])
		return trimmed[0].toUpperCase() + trimmed.slice(1).toLowerCase();
	return str;
}
