import React, { Component } from "react";
import "./GraphFilter.css";
import { ReactComponent as EyeSolid } from '../../../../assets/img/eye-solid.svg';
import { ReactComponent as EyeSlashSolid } from '../../../../assets/img/eye-slash-solid.svg';
import { ReactComponent as Expand } from '../../../../assets/img/expand-svgrepo-com.svg';
import { ReactComponent as Gear } from '../../../../assets/img/gear-solid.svg';
import { componentColor } from "../../../../utils/color";
import Filter from "./Filter/Filter";

class GraphFilter extends Component {
    constructor(props) {
        super(props)
		this.state = {
			open: false
		}
		this.resetFilter = 		this.resetFilter.bind(this);
    }

	componentDidUpdate() {
		if (this.props.filters.length === 0 && this.state.open) {
			this.setState(({open: false}))
		}
	}

	generateFilters() {
		return (
			this.props.filters && this.props.filters.map((filter, id) => {
				if ((filter.type === 'node' && filter.node === this.props.name) ||
				(filter.type === 'link' && filter.link === this.props.name))
					return(
						<Filter
							key={id}
							id={id}
							name={this.props.name}
							filterData={filter}
							setFilterData={this.props.setFilter}
							removeFilter={this.props.removeFilter}
							nodesType={this.props.nodesType}
							linksType={this.props.linksType}
							nodePossibility={this.props.nodePossibility}
							linkPossibility={this.props.linkPossibility}
							nodes={this.props.nodes}
							links={this.props.links}
							settings={this.props.settings}
						/>
					);
				return null;
			})
		);
	}

	resetFilter() {
		let filterList = this.state.filters;
		filterList.forEach(element => {
			element.hide = false;
			element.apply = false;
		});
		this.props.hideFilter(filterList);
		this.setState({filters: []});
	}

	open() {
		this.setState({open: !this.state.open});
		if (this.props.filters.filter(el => (el.type === 'node' && el.node === this.props.name) ||
		(el.type === 'link' && el.link === this.props.name)).length === 0)
			this.props.addFilter(this.props.type, this.props.name);
	}

	hide() {
		const index = this.props.filters.findIndex(fil => 
			fil.type === this.props.type &&
			fil[this.props.type] === this.props.name &&
			!fil.hide &&
			fil.propertie === 'undefined');
		if (index === -1)
			this.props.addFilter(this.props.type, this.props.name, true);
		else
			this.props.setFilter(index, 'hide', true);
	}

	show() {
		this.props.setFilter(
			this.props.filters.findIndex(fil => 
				fil.type === this.props.type &&
				fil[this.props.type] === this.props.name &&
				fil.hide &&
				fil.propertie === 'undefined'),
			'hide',
			false
		);
	}

	render() {
	return (
		<>
				<div className="graph__filter__content">
					<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
						<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
							{this.state.open ? '▼' : '▶'}
							<div className="graph__settings__content__cartridge__info" onClick={() => this.open()}
							style={
								this.props.type === 'node' ? {
								backgroundColor: 
									this.props.settings[this.props.name] ?
									this.props.settings[this.props.name].color :
									componentColor,
								borderBottomLeftRadius: this.state.open ? '0px' : '20px'
									} :
									{
								border: 
								this.props.settings[this.props.name] ?
								`${this.props.settings[this.props.name].lineWidth} ${this.props.settings[this.props.name].line} ${this.props.settings[this.props.name].color}` :
								`2px solid ${componentColor}`,
								borderBottomLeftRadius: this.state.open ? '0px' : '20px'
									}}>
								<span>
									{this.props.name + ' (' +
									(this.props.type === 'node' ?
									this.props.nodes.filter((node) => node.type === this.props.name).length :
									this.props.links.filter((link) => link.type === this.props.name).length)
									+ ')'}
								</span>
							</div>
							{ this.props.filters.filter(el => (el.type === 'node' && el.node === this.props.name) ||
								(el.type === 'link' && el.link === this.props.name)).find(fil => fil.hide && fil.propertie === 'undefined') ? 
							<div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', backgroundColor: 'lightgrey', borderRadius: '10px', width: '50px'}} onClick={() => {this.show(this.props.type, this.props.name)}}>
								<div style={{width: 15, height: 15, backgroundColor: 'red', borderRadius: '50%'}}></div>
								<EyeSlashSolid style={{width: 20, height: 20}}/>
							</div> :
							<div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', backgroundColor: 'lightgrey', borderRadius: '10px', width: '50px'}} onClick={() => {this.hide(this.props.type, this.props.name)}}>
								<EyeSolid style={{width: 20, height: 20}}/>
								<div style={{width: 15, height: 15, backgroundColor: 'green', borderRadius: '50%'}}></div>
							</div> }
						</div>
						{
							this.props.type === 'node' ?
							<div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '70px'}}>
								<Expand onClick={() => this.props.mapAllNodesByType(this.props.name, this.props.nodes.map((el) => {return el.id}))} style={{width: 25, height: 25}}></Expand>
								<Gear onClick={() => this.props.handlePopup(this.props.name)} style={{width: 25, height: 25}}/>
							</div>
							:
							<div style={{display: "flex", flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '70px'}}>
								<Expand onClick={() => this.props.mapAllRelationsByType(this.props.name)} style={{width: 25, height: 25}}></Expand>
								<Gear onClick={() => this.props.handlePopup(this.props.name)} style={{width: 25, height: 25}}/>
							</div>
						}
					</div>
					{ this.state.open ?
					<div className="graph__filter__list" 
					style={ this.props.type === 'node' ? {
							borderLeft: '5px solid',
							borderColor: this.props.settings[this.props.name] ?
							this.props.settings[this.props.name].color :
							componentColor
						} : 
						{
							borderLeft: 
							this.props.settings[this.props.name] ?
							`${this.props.settings[this.props.name].lineWidth} ${this.props.settings[this.props.name].line}` :
							'2px solid',
							borderColor: 
								this.props.settings[this.props.name] ?
								this.props.settings[this.props.name].color :
								componentColor
								}
						}>
						{this.generateFilters()}
					</div> : null }
				</div>
		</>
	);}
}

export default GraphFilter;
