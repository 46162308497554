import {
	GET_RELATIONS,
	GET_RELATION_INFO,
	GET_PROPERTY_LIST_RELATION,
	DELETE_RELATION,
	ADD_RELATION,
	UPDATE_RELATION,
	RESET_MAP,
} from "../action";

export const relationReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_RELATIONS:
			return { ...state, relations: action.payload };

		case GET_RELATION_INFO:
			return { ...state, relationInfo: action.payload };

		case GET_PROPERTY_LIST_RELATION:
			return { ...state, propertiesListColumnRelation: action.payload };

		case DELETE_RELATION:
			let relationInfo = state.relationInfo;
			let relations = state.relations;
			let res = relationInfo.filter((relation) => relation.id !== action.payload);
			const toDel = relationInfo.find(rel => rel.id === action.payload);
			if (toDel) {
				const rel = relations.findIndex(relation => relation.name === toDel.type);
				if (rel !== -1 && relations[rel].number === 1) {
					relations.splice(rel, 1);
				} else if (rel !== -1) {
					relations[rel].number -= 1;
				}
			}
			return { ...state, relationInfo: res, relations };

		case ADD_RELATION:
			let newRelMap = {};
			newRelMap.id = action.payload.id;
			newRelMap.source = action.payload.idOrigin;
			newRelMap.target = action.payload.idTarget;
			newRelMap.type = action.payload.type;
			newRelMap = {...newRelMap,...action.payload.data};
			let newRelation = [];
			if (!state.relations.find(el => el.name === action.payload.type)) {
				newRelation[0] = {
					name: action.payload.type,
					number: 1,
					content: Object.keys(action.payload).filter(el =>
						el !== 'id' &&
						el !== 'idOrigin' &&
						el !== 'idTarget' &&
						el !== 'labelOrigin' &&
						el !== 'labelTarget' &&
						el !== 'origin' &&
						el !== 'target' &&
						el !== 'type'
					)
				};
			} else if (state.relations.find(el => el.name === action.payload.type)) {
				state.relations.find(el => el.name === action.payload.type).number += 1;
			}
			return { ...state,
				relationInfo: state.relationInfo.concat(action.payload),
				lastRelations: (state.lastRelations ? state.lastRelations.concat([newRelMap]) : [newRelMap]),
				relations: state.relations.concat(newRelation)
			};

		case RESET_MAP:
			return { ...state, lastRelations: [] };

		case UPDATE_RELATION:
			const oldrel = state.relationInfo.find((element) => element.id === action.payload.oldId);
			if (oldrel) {
				oldrel.data = action.payload.data;
				oldrel.id = action.payload.id;
				let newObj = [oldrel];
				let final = state.relationInfo.map((obj) => newObj.find((o) => action.payload.oldId === obj.id) || obj,)
				return { ...state, relationInfo: final };
			}
			return state;

		default:
			return state;
	}
};
