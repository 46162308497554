import React, { useState } from "react";

import "../../../Form/Form.css"
import ButtonStyle from "../../../../styled-components/ButtonStyle";
import { settingsColor, cancelColor } from "../../../../utils/color";

function PopupUpdateUser(props) {
    const [userData, setUserData] = useState({
        username: props.userData.username,
        firstname: props.userData.firstname,
        lastname: props.userData.lastname,
        function: props.userData.function,
        password: '',
        role: props.userData.role,
    });
    const [error, setError] = useState('');

    const handleClose = (e) => {
        e.preventDefault();
        props.setShow(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.updateUser(userData, props.userData);
        props.setShow(false);
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        if (name === 'role') {
            if (userData.role.find(role => role.name === value)) {
                value = userData.role.filter(role => role.name !== value);
            } else {
                value = userData.role.concat([props.roleList.find(role => role.name === value)])
            }
        }
        if (name === 'username' && props.userList.find(el => el.username === value)) {
            setError('Cet identifiant est deja utilise');
        } else if (error !== '' && name === 'username') {
            setError('');
        }
        setUserData({ ...userData, [name]: value });
    }

    const generateRoles = () => {
        const checkbox = props.roleList.map(role => {
            return (
                <>
                    <input 
                        name="role" 
                        onChange={handleChange} 
                        checked={userData.role.find(el => el.name === role.name) ? true : false} 
                        disabled={role.name === 'default' && userData.role.length === 1}
                        value={role.name} 
                        type="checkbox">
                    </input>
                    <label>{role.name}</label>
                </>
            )
        })
        return (<div>{checkbox}</div>)
    }

	return (
		<div className='container--popupSettings'>
            <div className='background--popupSettings' onClick={handleClose}></div>
            <form className='container--form' onSubmit={handleSubmit}>
                <h2>Mettre a jour {props.userData.username}</h2>
                <div>
                    <h3>Identifiant</h3>
                    <input required name="username" value={userData.username} onChange={handleChange} type="text"></input>
                </div>
                <div>
                    <h3>Nom</h3>
                    <input required name="lastname" value={userData.lastname} onChange={handleChange} type="text"></input>
                </div>
                <div>
                    <h3>Prenom</h3>
                    <input required name="firstname" value={userData.firstname} onChange={handleChange} type="text"></input>
                </div>
                <div>
                    <h3>Description</h3>
                    <input required name="function" value={userData.function} onChange={handleChange} type="text"></input>
                </div>
                <div>
                    <h3>Role</h3>
                    {generateRoles()}
                </div>
                <div>
                    <h3>Mot de passe</h3>
                    <input name="password" value={userData.password} onChange={handleChange} type="text"></input>
                </div>
                <div className='container--form--button'>
                    <ButtonStyle
                        color={cancelColor}
                        onClick={() => props.setShow(false)}>
                        ANNULER
                    </ButtonStyle>
                    {error !== '' ? (
					<span className='duplicate--property'>{error}</span>
				) : (
                    <ButtonStyle color={settingsColor} type='submit'>
                        CONFIRMER
                    </ButtonStyle>
                )}
                </div>
            </form>
		</div>
	);
}

export { PopupUpdateUser };
