import { nodeClick } from "./event/nodeClick";
import { nodeDblClick } from "./event/nodeDblClick";
import { getNodeText } from "./get/getNodeText";
import { getNodeRadius } from "./get/getNodeRadius";
import { drag } from "../drag";
import { wrap } from "./wrap";
import { getNodeColor } from "./get/getNodeColor";
import { isValidHttpUrl } from "../../isHttpUrl";

	export const updateNodes = (data, nodes, settings, simulation, handleContextMenu, addSelectedElement, removeSelectedElement, deleteNode, deployAll) => {
		nodes
			.selectAll("g")
			.data(data, function (d) {
				return d?.id;
			})
			.join(
				(enter) => {
					enter = enter.append("g")
						.classed("node", true)


					//append selection circle (dashed circle)
					enter
						.append("circle")
						.attr("r", (d) => parseInt(getNodeRadius(d?.type, settings)) + 4)
						.classed("outer", true);

					enter
						.append("circle")
						.attr("r", (d) => getNodeRadius(d.type, settings))
						.attr("fill", (d) => getNodeColor(d.type, settings))
						.classed("inner", true)
						.call(drag(simulation));
					
					enter
						.on("click", nodeClick(nodes, settings, handleContextMenu, addSelectedElement, removeSelectedElement, deleteNode, deployAll))
						.on("dblclick", nodeDblClick(addSelectedElement));

					enter.filter((d) => parseInt(getNodeRadius(d.type, settings)) > 20 && !isValidHttpUrl(getNodeText(d, settings)))
						.append("text")
						.classed("wrap", true)
						.attr("text-anchor", "middle")
						.attr("text-length", (d) => parseInt(getNodeRadius(d.type, settings)) - 2)
						.attr("x", 0)
						.attr("y", 0)
						.attr("fill", "black")
						.text((d) => getNodeText(d, settings))
						.each(wrap(settings))
						.call(drag(simulation));
					
					enter.filter((d) => parseInt(getNodeRadius(d.type, settings)) > 20 && isValidHttpUrl(getNodeText(d, settings)))
						.append("a")
						.attr("href", (d) => getNodeText(d, settings))
						.attr("target", "_blank")
						.attr("rel", "noopener noreferrer")
						.append("text")
						.classed("wrap", true)
						.attr("text-anchor", "middle")
						.attr("text-length", (d) => parseInt(getNodeRadius(d.type, settings)) - 2)
						.attr("fill", "black")
						.attr("x", (d) => d.x)
						.attr("y", (d) => d.y)
						.text((d) => getNodeText(d, settings))
						.each(wrap(settings))
						.call(drag(simulation));

					enter.append("title").text((d) => {
						const title = Object.keys(d).filter(
							(prop) => 
								prop !== 'id' &&
								prop !== 'index' &&
								prop !== 'name' &&
								prop !== 'description' &&
								prop !== 'y' &&
								prop !== 'x' &&
								prop !== 'fx' &&
								prop !== 'fy' &&
								prop !== 'vy' &&
								prop !== 'vx' &&
								prop !== 'cx' &&
								prop !== 'cy',
							).sort((a, b) => a[0].localeCompare(b[0])).map((key) => {
								return `${key === 'type' ? 'Classe' : key }: ${d[key]}`;
							});
						return title.join('\n')
					});
				},
				(update) => {
					update.select("circle.inner")
					.attr('r', (d) => getNodeRadius(d.type, settings))
					.attr("fill", (d) => getNodeColor(d.type, settings))

					update.select("circle.outer")
					.attr('r', (d) => getNodeRadius(d.type, settings) + 4)

					update.selectAll("text")
					.remove()
					
					update.filter((d) => parseInt(getNodeRadius(d.type, settings)) > 20 && !isValidHttpUrl(getNodeText(d, settings)))
					.append("text")
						.classed("wrap", true)
						.attr("text-anchor", "middle")
						.attr("text-length", (d) => parseInt(getNodeRadius(d.type, settings)) - 2)
						.attr("fill", "black")
						.attr("x", (d) => d.x)
						.attr("y", (d) => d.y)
						.text((d) => getNodeText(d, settings))
						.each(wrap(settings));
					
					update.filter((d) => parseInt(getNodeRadius(d.type, settings)) > 20 && isValidHttpUrl(getNodeText(d, settings)))
						.append("a")
						.attr("href", (d) => getNodeText(d, settings))
						.attr("target", "_blank")
						.attr("rel", "noopener noreferrer")
						.append("text")
						.classed("wrap", true)
						.attr("text-anchor", "middle")
						.attr("text-length", (d) => parseInt(getNodeRadius(d.type, settings)) - 2)
						.attr("fill", "black")
						.attr("x", (d) => d.x)
						.attr("y", (d) => d.y)
						.text((d) => getNodeText(d, settings))
						.each(wrap(settings));
				},
				(exit) => {
					exit.remove();
				},
			);
	}