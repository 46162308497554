import {
	GET_SETTINGS,
	GET_ROLE_LIST,
	GET_USER_LIST,
	GET_DATABASE,
	UPDATE_DATABASE,
	UPDATE_SETTINGS,
	GET_ALL_PROPERTIES,
	UPDATE_PROPERTIE,
	ADD_USER,
	UPDATE_USER,
	ADD_ROLE,
	UPDATE_ROLE,
	DELETE_ROLE,
	DELETE_USER,
	GET_USER
} from ".";
import axios from "axios";

const REACT_APP_GET_USER_SETTINGS="/graph/settings/get/#userId#";
const REACT_APP_GET_USER="/graph/settings/user/get/#userId#";
const REACT_APP_UPDATE_USER_SETTINGS="/graph/settings/put/#userId#";
const REACT_APP_UPDATE_USER_PASSWORD="/graph/settings/put/user/password#userId#";
const REACT_APP_GET_ALL_PROPERTIES="/graph/settings/properties";
const REACT_APP_UPDATE_PROPERTIE="/graph/settings/properties";
const REACT_APP_UPDATE_DATABASE="/graph/settings/database";
const REACT_APP_GET_USER_LIST="/graph/settings/user/list";
const REACT_APP_ADD_USER="/graph/settings/user/add";
const REACT_APP_DELETE_USER="/graph/settings/user/delete";
const REACT_APP_UPDATE_USER="/graph/settings/user/update";
const REACT_APP_GET_ROLE_LIST="/graph/settings/role/list";
const REACT_APP_ADD_ROLE="/graph/settings/role/add";
const REACT_APP_DELETE_ROLE="/graph/settings/role/delete";
const REACT_APP_UPDATE_ROLE="/graph/settings/role/update";

export const getSettings = (user) => (dispatch) => {
	const url = REACT_APP_GET_USER_SETTINGS;
	axios
		.get(url.replace("#userId#", encodeURIComponent(user)))
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_SETTINGS,
				payload: data,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const getUserList = () => (dispatch) => {
	const url = REACT_APP_GET_USER_LIST;
	axios
		.get(url)
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_USER_LIST,
				payload: data,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const getUser = (user) => (dispatch) => {
	const url = REACT_APP_GET_USER;
	axios
		.get(url.replace("#userId#", encodeURIComponent(user)))
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_USER,
				payload: data,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const updatePassword = (user, oldPass, newPass) => (dispatch) => {
	const url = REACT_APP_UPDATE_USER_PASSWORD;
	axios
		.put(url.replace("#userId#", encodeURIComponent(user)), {oldPass, newPass})
		.then((res) => res.data)
		.then(data => {
			alert("Nouveau mot de passe pris en compte")
		})
		.catch(function (err) {
			alert("Mot de passe incorrect")
		});
};

export const addUser = (userData) => (dispatch) => {
	const url = REACT_APP_ADD_USER;
	axios
		.put(url, {userData: userData})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: ADD_USER,
				payload: userData,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const deleteUser = (userData) => (dispatch) => {
	const url = REACT_APP_DELETE_USER;
	axios
		.put(url, {userData: userData})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: DELETE_USER,
				payload: userData,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const updateUser = (userData, oldUserData) => (dispatch) => {
	const url = REACT_APP_UPDATE_USER;
	axios
		.put(url, {userData: userData, oldUserData: oldUserData})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: UPDATE_USER,
				payload: {userData: userData, oldUserData: oldUserData},
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const getRoleList = () => (dispatch) => {
	const url = REACT_APP_GET_ROLE_LIST;
	axios
		.get(url)
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_ROLE_LIST,
				payload: data,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const addRole = (roleData) => (dispatch) => {
	const url = REACT_APP_ADD_ROLE;
	axios
		.put(url, {roleData: roleData})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: ADD_ROLE,
				payload: roleData,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const deleteRole = (roleData) => (dispatch) => {
	const url = REACT_APP_DELETE_ROLE;
	axios
		.put(url, {roleData: roleData})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: DELETE_ROLE,
				payload: roleData,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const updateRole = (roleData, oldRoleData) => (dispatch) => {
	const url = REACT_APP_UPDATE_ROLE;
	axios
		.put(url, {roleData: roleData, oldRoleData: oldRoleData})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: UPDATE_ROLE,
				payload: {roleData: roleData, oldRoleData: oldRoleData},
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const getDatabase = () => (dispatch) => {
	const url = REACT_APP_UPDATE_DATABASE;
	axios
		.get(url)
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_DATABASE,
				payload: data,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};


export const updateDatabase = (database) => (dispatch) => {
	const url = REACT_APP_UPDATE_DATABASE;
	axios
		.put(url, {database: database})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: UPDATE_DATABASE,
				payload: database,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};


export const updateSettings = (user, settings) => (dispatch) => {
	const url = REACT_APP_UPDATE_USER_SETTINGS;
	axios
		.put(url.replace("#userId#", encodeURIComponent(user)), {settings: settings})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: UPDATE_SETTINGS,
				payload: settings,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const getAllProperties = () => (dispatch) => {
	const url = REACT_APP_GET_ALL_PROPERTIES;
	axios
		.get(url)
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_ALL_PROPERTIES,
				payload: data,
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};

export const updatePropertie = (oldPropertie, newPropertie) => (dispatch) => {
	const url = REACT_APP_UPDATE_PROPERTIE;
	axios
		.put(url, {oldPropertie: oldPropertie, newPropertie: newPropertie})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: UPDATE_PROPERTIE,
				payload: {old: oldPropertie, new: newPropertie},
			});
		})
		.catch(function (err) {
			console.log("Request failed", err);
		});
};