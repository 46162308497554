import * as d3 from "d3";

	export const nodeDblClick = (addSelectedElement) => (event, d) => {
		let selectedGroup = d.type;
		d3.selectAll("g.selected").classed("selected", false);

		d3.selectAll("g.node")
			.filter((d) => d.type === selectedGroup)
			.each(function (d) {
				d3.select(this).classed("selected", true);
				addSelectedElement(d)
			});
	}