const GET_MAP_NODE = "GET_MAP_NODE";
const GET_MAP_ALL_RELATIONS = "GET_MAP_ALL_RELATIONS";
const GET_MAP_COMPONENT_RELATIONS = "GET_MAP_COMPONENT_RELATIONS";
const RESET_MAP = "RESET_MAP";
const RESET_NODE = "RESET_NODE";
const GET_MAP_COMPONENT_RELATION_BY_TYPE = "GET_MAP_COMPONENT_RELATION_BY_TYPE";
const GET_MAP_COMPONENT_RELATIONS_TYPES = "GET_MAP_COMPONENT_RELATIONS_TYPES";
const GET_MAP_ALL_NODES_BY_TYPES = "GET_MAP_ALL_NODES_BY_TYPES";
const GET_MAP_ALL_RELATIONS_BY_TYPE = "GET_MAP_ALL_RELATIONS_BY_TYPES";
const SAVE_MAP_CARTO = "SAVE_MAP_CARTO";
const GET_MAP_CARTO = "GET_MAP_CARTO";
const UPDATE_MAP_CARTO = "UPDATE_MAP_CARTO";
const DELETE_MAP_CARTO = "DELETE_MAP_CARTO";

export { GET_MAP_NODE, UPDATE_MAP_CARTO, GET_MAP_CARTO, SAVE_MAP_CARTO, DELETE_MAP_CARTO, GET_MAP_ALL_RELATIONS, GET_MAP_COMPONENT_RELATIONS, RESET_NODE, RESET_MAP, GET_MAP_COMPONENT_RELATIONS_TYPES, GET_MAP_COMPONENT_RELATION_BY_TYPE, GET_MAP_ALL_NODES_BY_TYPES, GET_MAP_ALL_RELATIONS_BY_TYPE };
