
import * as d3 from "d3";
	// DRAG
	const dragstarted = (simulation) => (event, d) => {
		if (!event.active) simulation.alphaTarget(0.3).restart();
		d.fx = event.x;
		d.fy = event.y;
	}

	const dragged = (event, d) => {
		const pathBtns = d3.selectAll("g.path-btn")
		if (pathBtns.size() && d.id === pathBtns.datum().parentId) {
		//if the dragged node has a round menu
			let prevXTransform = pathBtns.datum().xTransform,
			prevYTransform = pathBtns.datum().yTransform,
			prevParentId = pathBtns.datum().parentId
			
			pathBtns.datum({xTransform: prevXTransform + event.dx, yTransform: prevYTransform + event.dy, parentId: prevParentId});
			pathBtns.attr("transform", "translate(" + pathBtns.datum().xTransform + "," + pathBtns.datum().yTransform + ")");			
 		}
		 d.fx += event.dx;
		 d.fy += event.dy;
	}

	const dragended = (simulation) => (event, d) => {
		if (!event.active) simulation.alphaTarget(0);
	}

	export const drag = (simulation) => {
		return d3.drag()
			.on("start", dragstarted(simulation))
			.on("drag", dragged)
			.on("end", dragended(simulation));
	}