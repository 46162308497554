import React, { useState } from "react";

import "../../../Form/Form.css"
import ButtonStyle from "../../../../styled-components/ButtonStyle";
import { settingsColor, cancelColor } from "../../../../utils/color";

function PopupUpdateRole(props) {
    const [roleData, setRoleData] = useState({
        name: props.role.name,
        rules: props.role.rules
    })
    const [linkRules, setlinkRules] = useState(props.role.rules.links);
    const [nodeRules, setNodeRules] = useState(props.role.rules.nodes);
    const [error, setError] = useState('');

    const handleClose = (e) => {
        e.preventDefault();
        props.setShow(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.updateRole({name: roleData.name, rules: {nodes: nodeRules, links: linkRules}}, props.role);
        props.setShow(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (props.roleList.find(el => el.name === value)) {
            setError('Ce nom de regle est deja utilise');
        } else if (error !== '') {
            setError('');
        }
        setRoleData({ ...roleData, [name]: value });
    }

    const handleLinkChange = (e, id) => {
        const newValue = linkRules[e.target.name];
        newValue[id] = e.target.value;
        setlinkRules({...linkRules, [e.target.name]: newValue});
    }

    const generateLinkRules = () => {
        const options = props.links.map((link, id) => {
            return (
                <option key={id} value={link.name}>{link.name}</option>
            )
        })
        const cantEdit = linkRules.cantEdit;
        const cantView = linkRules.cantView;
        let lines;
        if (cantEdit.length > cantView.length) {
            lines = cantEdit.map((el, id) => {
                return (
                    <tr>
                        <td>
                            {cantView[id] || cantView[id] === '' ?
                                <select name="cantView" value={cantView[id]} onChange={(e) => handleLinkChange(e, id)}>
                                    <option value={''}></option>
                                    {options}
                                </select>
                            : null}
                        </td>
                        <td>
                            <select name="cantEdit" value={el} onChange={(e) => handleLinkChange(e, id)}>
                                <option value={''}></option>
                                {options}
                            </select>
                        </td>
                    </tr>
                )
            })
        } else {
            lines = cantView.map((el, id) => {
                return (
                    <tr>
                        <td>
                            <select name="cantView" value={el} onChange={(e) => handleLinkChange(e, id)}>
                                <option value={''}></option>
                                {options}
                            </select>
                        </td>
                        <td>
                            {cantEdit[id] || cantEdit[id] === '' ?
                                <select name="cantEdit" value={cantEdit[id]} onChange={(e) => handleLinkChange(e, id)}>
                                    <option value={''}></option>
                                    {options}
                                </select>
                            : null}
                        </td>
                    </tr>
                )
            })
        }
        return (
            <table>
                <thead>
                    <tr>
                        <th>
                            Ne peut voir
                        </th>
                        <th>
                            Ne peut modifier
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {lines}
                    <tr>
                        <td onClick={() => setlinkRules({...linkRules, cantView: linkRules.cantView.concat([''])})}>+</td>
                        <td onClick={() => setlinkRules({...linkRules, cantEdit: linkRules.cantEdit.concat([''])})}>+</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    const handleNodeChange = (e, id) => {
        const newValue = nodeRules[e.target.name];
        newValue[id] = e.target.value;
        setNodeRules({...nodeRules, [e.target.name]: newValue});
    }

    const generateNodeRules = () => {
        const options = props.nodes.map((node, id) => {
            return (
                <option key={id} value={node.name}>{node.name}</option>
            )
        })
        const cantEdit = nodeRules.cantEdit;
        const cantView = nodeRules.cantView;
        let lines;
        if (cantEdit.length > cantView.length) {
            lines = cantEdit.map((el, id) => {
                return (
                    <tr>
                        <td>
                            {cantView[id] || cantView[id] === '' ?
                                <select name="cantView" value={cantView[id]} onChange={(e) => handleNodeChange(e, id)}>
                                    <option value={''}></option>
                                    {options}
                                </select>
                            : null}
                        </td>
                        <td>
                            <select name="cantEdit" value={el} onChange={(e) => handleNodeChange(e, id)}>
                                <option value={''}></option>
                                {options}
                            </select>
                        </td>
                    </tr>
                )
            })
        } else {
            lines = cantView.map((el, id) => {
                return (
                    <tr>
                        <td>
                            <select name="cantView" value={el} onChange={(e) => handleNodeChange(e, id)}>
                                <option value={''}></option>
                                {options}
                            </select>
                        </td>
                        <td>
                            {cantEdit[id] || cantEdit[id] === '' ?
                                <select name="cantEdit" value={cantEdit[id]} onChange={(e) => handleNodeChange(e, id)}>
                                    <option value={''}></option>
                                    {options}
                                </select>
                            : null}
                        </td>
                    </tr>
                )
            })
        }
        return (
            <table>
                <thead>
                    <tr>
                        <th>
                            Ne peut voir
                        </th>
                        <th>
                            Ne peut modifier
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {lines}
                    <tr>
                        <td onClick={() => setNodeRules({...nodeRules, cantView: nodeRules.cantView.concat([''])})}>+</td>
                        <td onClick={() => setNodeRules({...nodeRules, cantEdit: nodeRules.cantEdit.concat([''])})}>+</td>
                    </tr>
                </tbody>
            </table>
        )
    }

	return (
		<div className='container--popupSettings'>
            <div className='background--popupSettings' onClick={handleClose}></div>
            <form className='container--form' onSubmit={handleSubmit}>
                <h2>Ajouter un nouveau Role</h2>
                <div>
                    <h3>Nom du role</h3>
                    <input required name="name" value={roleData.name} onChange={handleChange} type="text"></input>
                </div>
                <div>
                    <h3>Interdire un composant</h3>
                    {generateNodeRules()}
                </div>
                <div>
                    <h3>Interdire une relation</h3>
                    {generateLinkRules()}
                </div>
                <div className='container--form--button'>
                    <ButtonStyle
                        color={cancelColor}
                        onClick={() => props.setShow(false)}>
                        ANNULER
                    </ButtonStyle>
                    {error !== '' ? (
					<span className='duplicate--property'>{error}</span>
				) : (
                    <ButtonStyle color={settingsColor} type='submit'>
                        CONFIRMER
                    </ButtonStyle>
                )}
                </div>
            </form>
		</div>
	);
}

export { PopupUpdateRole };
