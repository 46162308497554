import React from "react";

import loading from "../assets/Loader.gif";

function Loader() {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
			}}>
			<img src={loading} alt='Loading' />
		</div>
	);
}

export { Loader };
