import React, { useState } from "react";

import "../../../Form/Form.css"
import ButtonStyle from "../../../../styled-components/ButtonStyle";
import { settingsColor, cancelColor } from "../../../../utils/color";

function PopupAddRole(props) {
    const [roleData, setRoleData] = useState({
        name: '',
        rules: {
            nodes: {
                cantView: [],
                cantEdit: []
            },
            links: {
                cantView: [],
                cantEdit: []
            }
        }
    })
    const [error, setError] = useState('');

    const handleClose = (e) => {
        e.preventDefault();
        props.setShow(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.addRole(roleData);
        props.setShow(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (props.roleList.find(el => el.name === value)) {
            setError('Cet identifiant est deja utilise');
        } else if (error !== '') {
            setError('');
        }
        setRoleData({ ...roleData, [name]: value });
    }

	return (
		<div className='container--popupSettings'>
            <div className='background--popupSettings' onClick={handleClose}></div>
            <form className='container--form' onSubmit={handleSubmit}>
                <h2>Ajouter un nouveau Role</h2>
                <div>
                    <h3>Nom du role</h3>
                    <input required name="name" value={roleData.name} onChange={handleChange} type="text"></input>
                </div>
                <div className='container--form--button'>
                    <ButtonStyle
                        color={cancelColor}
                        onClick={() => props.setShow(false)}>
                        ANNULER
                    </ButtonStyle>
                    {error !== '' ? (
					<span className='duplicate--property'>{error}</span>
				) : (
                    <ButtonStyle color={settingsColor} type='submit'>
                        CONFIRMER
                    </ButtonStyle>
                )}
                </div>
            </form>
		</div>
	);
}

export { PopupAddRole };
