import { UPLOAD_CSV, GET_NODE_CHANGE_CSV, GET_REL_CHANGE_CSV } from './index';
import axios from "axios";

const REACT_APP_UPLOAD_CSV="/graph/upload/csv";
const REACT_APP_GET_NODE_CHANGE_CSV="/graph/upload/nodeChanges";
const REACT_APP_GET_REL_CHANGE_CSV="/graph/upload/relChanges";

export const uploadCsv = (csv, types, fields) => (dispatch) => {
	const url = REACT_APP_UPLOAD_CSV;

	csv.append('types', JSON.stringify(types));
	csv.append('fields', JSON.stringify(fields));

	axios
		.put(url, csv)
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: UPLOAD_CSV,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Add failed", err);
		});
};

export const getNodeChangeCsv = (csv, node) => (dispatch) => {
	console.log(csv);
	const url = REACT_APP_GET_NODE_CHANGE_CSV;

	axios
		.put(url, {data: csv, type: node})
		.then((res) => res.data)
		.then((data) => {
			dispatch({
				type: GET_NODE_CHANGE_CSV,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Add failed", err);
		});
}

export const getRelChangeCsv = (csv, relation) => (dispatch) => {
	console.log(csv);
	const url = REACT_APP_GET_REL_CHANGE_CSV;

	axios
		.put(url, {data: csv, type: relation})
		.then((res) => res.data)
		.then((data) => {
			console.log(data);
			dispatch({
				type: GET_REL_CHANGE_CSV,
				payload: data,
			});
		})
		.catch((err) => {
			console.log("Add failed", err);
		});
}