import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
// import ReactTooltip from "react-tooltip";

import {
	Home,
	MapZone,
	Components,
	Relations,
	Settings,
	Informations,
} from "./routes";
import { MenuHeader, MenuAction } from "./components";

import { getComponents, getNodeNames } from "./action/componentAction";
import { getRelations } from "./action/relationAction";
import { mapAllRelations } from "./action/mapAction";
import { changePage } from "./action/routingAction";
import { getSettings, updateSettings, getAllProperties, updatePropertie, getDatabase, updateDatabase } from "./action/settingsAction";

import "./App.css";
import { PopupImport } from "./components/Import/PopupImport";
import { login, resetError } from "./action/userAtion";
import { PopupError } from "./components/Popup/PopupError";

function App(props) {
	const [popupDelete, setPopupDelete] = useState(false);
	const [popupClone, setPopupClone] = useState(false);
	const [popupCreate, setPopupCreate] = useState(false);
	const [popupUpdate, setPopupUpdate] = useState(false);
	const [popupImport, setPopupImport] = useState(false);
	const [popupError, setPopupError] = useState(false);
	const [importCsv, setImportcsv] = useState();

	const propsGetNodeNames = props.getNodeNames;
	const propsGetRelations = props.getRelations;
	const propsGetComponents = props.getComponents;
	const propsGetSettings = props.getSettings;
	const propsMapAllRelations = props.mapAllRelations;
	const propsGetAllProperties = props.getAllProperties;
	const propsGetDatabase = props.getDatabase;
	const resetError = props.resetError;

	
	useEffect(() => {
		document.title = 'Graph-it ' + (props.title ? props.title : '');
	}, [props.title]) 
	
	const isLogged = sessionStorage.getItem("cool-jwt") ? true : false;
	const token = useMemo(() => {
		if (isLogged) {
			return sessionStorage.getItem("cool-jwt");
		}
	}, [isLogged])

	const error = props.user && props.user.error;
	useMemo(() => {
		if (error && error !== '') {
			setPopupError(true)
			setTimeout(() => {
				resetError();
				setPopupError(false);
			}, 5000);
		}
	}, [error, resetError])
	
	useEffect(() => {
		if (isLogged) {
			propsGetComponents();
			propsGetRelations();
			propsGetNodeNames();
			propsGetAllProperties();
			propsGetDatabase();
			propsMapAllRelations();
			propsGetSettings(token);
		}
	}, [propsGetComponents, propsGetRelations, propsGetNodeNames, propsGetSettings, propsGetAllProperties, propsGetDatabase, isLogged, propsMapAllRelations, token]);

	return (
		<BrowserRouter>
			<PopupError show={popupError} error={error}></PopupError>
			<MenuHeader 
				changePage={props.changePage}
				setPopupImport={setPopupImport}
				setImportcsv={setImportcsv}/>
			<MenuAction 
				changePage={props.changePage}
				setPopupImport={setPopupImport}
				setImportcsv={setImportcsv}
				login={props.login}
				isLogged={isLogged}/>
			{popupImport ? 
			<PopupImport 
				setPopupImport={setPopupImport} 
				importCsv={importCsv}
				relations={props.relations}
				components={props.components}
				></PopupImport> : null}
			<Switch>
				<Route exact path='/' render={() => <Home />} />
				<Route
					path='/mapzone'
					render={() => ( isLogged ?
						<MapZone 
							relations={props.relations}
							components={props.components}
							nodeNames={props.nodeNames}
							data={props.allRelations}
							popupCreate={popupCreate}
							setPopupCreate={setPopupCreate}
							popupDelete={popupDelete}
							setPopupDelete={setPopupDelete}
							setPopupUpdate={setPopupUpdate}
							popupUpdate={popupUpdate}
							settings={props.settings}
							updateSettings={props.updateSettings}
						/>
						: <Home/>
					)}
				/>
				<Route
					path='/components'
					render={() => ( isLogged ?
						<Components
							components={props.components}
							popupCreate={popupCreate}
							setPopupCreate={setPopupCreate}
							popupDelete={popupDelete}
							setPopupDelete={setPopupDelete}
							setPopupUpdate={setPopupUpdate}
							popupUpdate={popupUpdate}
							popupClone={popupClone}
							setPopupClone={setPopupClone}
						/>
						: <Home />
					)}
				/>
				<Route
					path='/relations'
					render={() => ( isLogged ?
						<Relations
							relations={props.relations}
							components={props.components}
							nodeNames={props.nodeNames}
							popupClone={popupClone}
							setPopupClone={setPopupClone}
						/>
						: <Home />
					)}
				/>
				<Route 
					path='/settings' 
					render={() => ( isLogged ?
						<Settings 
							relations={props.relations}
							components={props.components}
							settings={props.settings}
							properties={props.properties}
							databases={props.databases}
							updateSettings={props.updateSettings}
							updateDatabase={props.updateDatabase}
							updatePropertie={props.updatePropertie}
						/>
						: <Home />
					)} 
				/>
				<Route 
					path='/info' 
					render={() => (
						<Informations/>
					)} 
				/>
			</Switch>
		</BrowserRouter>
	);
}

const mapStateToProps = (state) => ({
	components: state.componentReducer.components,
	relations: state.relationReducer.relations,
	componentInfo: state.componentReducer.componentInfo,
	allRelations: state.mapReducer.allRelations,
	settings: state.settingsReducer.settings,
	properties: state.propertiesReducer.properties,
	databases: state.settingsReducer.databases,
	title: state.settingsReducer.title,
	user: state.userReducer
});

const mapDispatchToProps = (dispatch) => ({
	changePage: (aInt) => dispatch(changePage(aInt)),
	getComponents: () => dispatch(getComponents()),
	getRelations: () => dispatch(getRelations()),
	getSettings: (user) => dispatch(getSettings(user)),
	getNodeNames: () => dispatch(getNodeNames()),
	mapAllRelations: () => dispatch(mapAllRelations()),
	updateSettings: (user, settings) => dispatch(updateSettings(user, settings)),
	getAllProperties: () => dispatch(getAllProperties()),
	getDatabase: () => dispatch(getDatabase()),
	updateDatabase: (database) => dispatch(updateDatabase(database)),
	updatePropertie: (oldProp, newProp) => dispatch(updatePropertie(oldProp, newProp)),
	login: (userInfo) => dispatch(login(userInfo)),
	resetError: () => dispatch(resetError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
