/* eslint-disable no-undef */

	export const formatData = (nodes, links, nodesTypes, linksTypes, queryData, relationsData, lastRelations) => {
		const nodesData = [...nodes, ...relationsData?.nodes ?? [], ...queryData ?? []].filter(el => el);
		const linksData = [...links, ...relationsData?.links ?? [], ...lastRelations ?? []].filter(el => el);
		const uniqueNodesTypes = [...nodesTypes];
		const uniqueLinksTypes = [...linksTypes];
		
		const uniqueNodes = Array.from(new Set(nodesData.map(node => node?.id)))
		.map(id => {
			return nodesData.find(node => node?.id === id)
		})

		

		_.each(uniqueNodes, (node) => {
			uniqueNodesTypes.push(node?.type)
		})
		
		const uniqueLinks = Array.from(new Set(linksData.map(link => link.id)))
		.map(id => {
			return linksData.find(link => link.id === id)
		});
		uniqueLinks.sort((a, b) => {
			if ((a.source === b.source) && (a.target === b.target)) {
				return -1;
			} else if ((a.source === b.target) && (a.target === b.source)) {
				return 1;
			}
			return 0;
		})

		_.each(uniqueLinks, (link) => {
			uniqueLinksTypes.push(link.type)

			let same = _.filter(uniqueLinks, (l) => {
				return (((l.source === link.source) && (l.target === link.target)) || ((l.source === link.target) && (l.target === link.source)))
			});

			_.each(same, (s, i) => {
				s.sameIndex = (i + 1);
				s.sameTotal = same.length;
				s.sameTotalHalf = (s.sameTotal / 2);
				s.sameUneven = ((s.sameTotal % 2) !== 0);
				s.sameMiddleLink = ((s.sameUneven === true) && (Math.ceil(s.sameTotalHalf) === s.sameIndex));
				s.sameLowerHalf = s.sameIndex <= s.sameTotalHalf;
				s.sameArcDirection = 0;
				s.sameIndexCorrected = s.sameLowerHalf ? s.sameIndex : (s.sameIndex - Math.ceil(s.sameTotalHalf));
				if ((s.source === link.source) && (s.target === link.target)) {
					s.from = true;
				} else if ((s.source === link.target) && (s.target === link.source)) {
					s.from = false;
				}
			});


		});

		let maxSame = 0;
		if(uniqueLinks.length > 0) {
			maxSame = _.chain(uniqueLinks)
			.sortBy((l) => {
				return l.sameTotal;
			})
			.last()
			.value()
			.sameTotal;
			_.each(uniqueLinks, (link) => {
				link.maxSameHalf = Math.floor(maxSame / 2);
			})
		}
		return {
			newNodes: uniqueNodes,
			newLinks: uniqueLinks,
			newNodesTypes: [...new Set(uniqueNodesTypes)],
			newLinksTypes: [...new Set(uniqueLinksTypes)]
		}
	}